const { WHITELIST } = require('@/constants/internationalBankTransfer')
const { countryCodeEnum } = require('@/constants/country')

module.exports = {
  info: {
    url: 'www.puprime.com',
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4616.687864043498!2d55.45538001942663!3d-4.619136458242956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x22e028533cfa53ad%3A0x48d516d8499b9550!2sOrion%20Building!5e0!3m2!1sen!2sau!4v1592795342692!5m2!1sen!2sau',
    address: `<span>Suite C, Orion Mall, Palm Street, Victoria, Mahe, Seychelles</span>`,
    phone: '+248 4373 105',
    email: 'info@puprime.com',
    accountEmail: 'info@puprime.com',
    referralSite: 'www.puprime.partners',
    referralRebateSite: 'www.puprimepartners.com',
    blacklistEmail: 'info@puprime.com',
  },
  menus: [
    {
      name: 'menu.dashboard',
      path: '/home',
      icon: require('@/assets/images/menu/menu_icon_home.png'),
    },
    {
      name: 'menu.ibReport',
      path: '/ibreport',
      icon: require('@/assets/images/menu/menu_icon_ib_report.png'),
    },
    {
      name: 'menu.rebateReport',
      path: '/rebatereport',
      icon: require('@/assets/images/menu/menu_icon_rebate_report.png'),
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: require('@/assets/images/menu/menu_icon_account.png'),
      children: [
        {
          name: 'menu.ibAccs',
          path: '/ibaccounts',
          icon: require('@/assets/images/menu/menu_icon_activated_accounts.png'),
        },
        {
          name: 'menu.pendingAccs',
          path: '/pendingaccounts',
          icon: require('@/assets/images/menu/menu_icon_pending_accounts.png'),
        },
        {
          name: 'menu.leads',
          path: '/leads',
          icon: require('@/assets/images/menu/menu_icon_leads.png'),
        },
      ],
    },
    {
      name: 'menu.payHistory',
      path: '/rebatePaymentHistory',
      icon: require('@/assets/images/menu/menu_icon_history.png'),
    },
    {
      name: 'menu.iblevel',
      path: '/iblevel',
      icon: require('@/assets/images/menu/menu_icon_diagram.png'),
    },
    {
      name: 'menu.refLinks',
      path: '/referralLinks',
      icon: require('@/assets/images/menu/menu_icon_links.png'),
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: require('@/assets/images/menu/menu_icon_contact.png'),
    },
  ],
  withdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
    },
    3: 'Skrill / Neteller',
    4: 'Union Pay',
    5: 'Internet Banking (Thailand)',
    6: 'Internet Banking (Malaysia)',
    7: 'FasaPay',
    8: 'Internet Banking (Vietnam)',
    9: 'Internet Banking (Nigeria)',
    24: 'Internet Banking (India)',
    31: 'Skrill',
    32: 'Neteller',
    33: 'Paypal',
    34: 'Cryptocurrency-Bitcoin',
    35: 'Internet Banking (Korea)',
    36: 'Cryptocurrency-USDT',
    37: 'Bitwallet',
    38: 'SticPay',
    39: 'Internet Banking (Indonesia)',
    40: 'Internet Banking (Philippine)',
    41: 'astropay',
    42: 'Internet Banking (South Africa)',
    44: 'Vload',
    50: 'Internet Banking (Uganda)',
    51: 'Internet Banking (Rwanda)',
    52: 'Internet Banking (Zambia)',
    53: 'Internet Banking (Congo)',
    54: 'Internet Banking (Cameroon)',
    55: 'Internet Banking (Burundi)',
    56: 'Internet Banking (Kenya)',
    57: 'Internet Banking (Ghana)',
    58: 'Internet Banking (Tanzania)',
    64: 'Internet Banking (Brazil)',
    67: 'Internet Banking (Nigeria)',
    75: 'EBuy',
    99: 'Interac',
    100: 'PIX',
    102:'Internet Banking (Taiwan)',
    110: 'Internet Banking (EU)',
  },
  internationalBankTransfer: {
    WITHDRAW: {
      ALLOW_ALL: true,
      WHITELIST: WHITELIST,
    },
  },
  registerId3Countries: [countryCodeEnum.UNITED_KINGDOM, countryCodeEnum.FRANCE, countryCodeEnum.MALAYSIA],
  registerId3NeedAdditionalForm: [countryCodeEnum.UNITED_KINGDOM, countryCodeEnum.FRANCE],
}
