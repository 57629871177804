export default {
  en: 'English',
  zh: '中文',
  home: {
    NewPortal: '体验新版',
    SecureIBPortal: '安全的代理平台',
    DASHBOARD: '概览',
    MARKETING_TOOLS: '推广工具',
    IB_REPORT: '代理报表',
    IB_ACCOUNTS: '客户账号列表',
    REBATE_REPORT: '返佣报表',
    PAYMENT_HISTORY: '交易历史',
    IB_FAQ: '代理FAQ',
    RebateAccountNumber: '返佣账号',
    totalBalance: '可申请额度',
    totalCommission: '总佣金',
    APPLY_FOR_A_REBATE: '申请',
    availableBalance: '余额',
    WITHDRAW_REBATE: '出金',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '转账',
    MonthtoDate: '当月',
    UPDATE: '更新',
    REBATE: '佣金',
    TOTAL_VOLUME: '交易量',
    NET_FUNDING: '净入金',
    DEPOSITS: '总入金',
    WITHDRAW: '总出金',
    OPEND_ACCOUNT: '已开账号数',
    PERFORMANCE: '趋势图',
    OpendAccount: '已开账号数',
    select_ib: '请选择IB账户',
    MOST_TRADED_INSTRUMENTS: '交易产品分布',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '本月业绩表现TOP',
    RECENTLY_OPENED_ACCOUNTS: '近期新开户',
    ACCOUNT_MANAGER: '专属客服',
    Account: '账号',
    Name: '姓名',
    Volume: '交易量',
    Rebate: '佣金',
    Balance: '余额',
    ContactClient: '联系客户',
    YouCannotAskForCommission: '您无可申请的佣金',
    applicationFailedPleaseTryAgain: '申请失败，请稍后重试',
    applyRebateSuccessTip:
      '祝贺你，我们收到了您的申请要求。 | {amount}将在1个工作日内转入返佣账户{account}。 | 一旦您的申请处理完毕，您可以提取或转移资金。',
    HOVER: '前一天的佣金将会在澳大利亚东部时间9至11时之间计算',
    ACCOUNTS_PROFIT: '该IB账户名下所有交易账户的历史总盈利/亏损。加载可能需要一些时间。',
    PROFIT: '客户损益',
    reload: '重新加载',
    blacklist: '您的账号目前暂时无法提交返佣申请，请联系您的客户经理',
  },
  liveChat: { header: '在线聊天' },
  contactUs: {
    header: '联系我们',
    followUs: '更多方式关注我们',
    tip: '{platform} 全球多语言团队随时为您提供帮助和服务, 让您拥有无与伦比的交易体验',
    liveChat: '在线客服',
    chatNow: '我们现在来聊聊',
  },
  rebate: { status: { processed: '已完成', processing: '处理中', rejected: '被拒绝' } },
  ibAccounts: {
    header: '客户管理',
    ibAccount: '返佣账号',
    subIbs: '子代理返佣账号',
    tradinglist: '交易清单',
    openlist: '未关闭交易',
    closedlist: '已关闭交易',
    tradinglistColumn: {
      ticketid: '订单号码',
      opentime: '开仓时间',
      type: '类型',
      volume: '交易量',
      item: '产品名称',
      openprice: '开盘价',
      closeprice: '收盘价',
      closetime: '平仓时间',
      commission: '返佣',
      profit: '损益',
      buy: '买',
      sell: '卖',
    },
  },
  withdraw: {
    default: {
      selectCC: '选择信用卡',
      selectBA: '选择银行账户',
      anotherCC: '手动输入信用卡',
      anotherBA: '添加银行帐户',
      remember: '记住我的帐号',
      verificationCode: '验证码已发送至{email}。若您需要进一步协助，请联系{supportEmail}。',
      fetchTradeAccFailed: '获取交易账户失败',
      failed: '出金失败, 请稍后再尝试',
      tokenExpired: '超时, 请再试一次',
    },
    alert:
      '根据反洗钱法，提取资金的方式必须与用于存款的方式相同。如果提款金额少于您帐户基础货币的100个单位，则银行将向您收取20个单位的手续费。',
    NetellerChargeAlert: 'Neteller 对每次出金收取 {percentageFee}% 的手续费。',
    FasaPayChargeAlert: 'FasaPay 对每次出金收取 0.5% 的手续费。',
    SkrillChargeAlert: 'Skrill 对每次出金收取 {percentageFee}% 的手续费。',
    cryptoAlert:
      '请注意，我们无法通过 BUSDT 进行存款或取款，请确保地址和加密货币与我们接受的链和货币匹配，否则您可能会损失资金。',
    REBATE_WITHDRAW_REQUEST: '出金申请',
    usdtNote:
      '受ERC20的矿工费上涨影响，出金处理时间也相应延迟。为了保障您的出金处理速度，您可以选择使用TRC20，或支付较高的矿工费申请出金，以加快您的出金申请进度。',
    PleaseSelectDesiredWithdrawMethod: '请选择所需的出金方法',
    BankName: '银行名称',
    BankAccountsName: '银行账户名',
    FasaPayAccountName: 'FasaPay账户姓名',
    AustralianBankName: '澳洲银行名称',
    BankAccountNumber: '银行账号',
    FasaPayAccountNumber: 'FasaPay 账户号码',
    BankAccountBeneficiary: '银行收款人姓名',
    BSB: 'BSB信息',
    skrillEmail: 'Skrill邮箱',
    NetellerEmail: 'Neteller邮箱',
    PerfectMoneyEmail: 'Perfect Money邮箱',
    BitwalletEmail: 'Bitwallet邮箱',
    SticpayEmail: 'Sticpay邮箱',
    BitcoinEmail: 'Bitcoin钱包地址',
    USDTEmail: 'Tether钱包地址',
    BankBranch: '银行支行',
    Province: '省',
    City: '市',
    BankAddress: '银行地址',
    bankCity: '银行所在城市',
    bankProvince: '银行所在省',
    AccountNumberIBAN: '银行账号',
    BankBeneficiaryName: '银行收款人姓名',
    BankAccountBeneficiaryName: '银行账户收款人姓名',
    AccountHoldersAddress: '账户持有人地址',
    SelectWithdrawCard: '请选择出金卡',
    Swift: 'Swift代码',
    SwiftAud: 'Swift代码(非澳元账号)',
    ABA_SortCodeABA: 'Sort代码',
    ImportantNotes: '重要信息',
    YourPaymentGreaterThanAvailableBalance: '您的出金金额大于可用余额',
    CannotUseMoreThanTwoDecimalValues: '无法使用超过两位小数的数值和负数',
    YourPaymentIsBeingProcessed: '您的出金申请已经提交',
    Itcannotbe0: '出金不能为0',
    noCardWarn: '没有有效的卡, 请先绑卡',
    amtLarger: '金额不能为空，同时必须大于或等于 {minLimit} {currency}',
    blackList: '很遗憾，您目前无法提交提款请求。请联系{supportEmail}取得更多信息',
    status: {
      withdrawalSubmitted: '已提交',
      withdrawalCanceled: '取消',
      withdrawalAuditing: '处理中',
      withdrawalRejected: '拒绝',
      withdrawalAccepted: '处理中',
      withdrawalPayFailed: '失败',
      withdrawalSuccess: '已完成',
      withdrawalFailed: '失败',
      withdrawalPending: '等候中',
      withdrawalPartialFailed: '失败',
      transferSubmitted: '处理中',
      transferSuccess: '已完成',
      transferRejected: '拒绝',
      transferFailed: '失败',
      transferProcessing: '处理中',
      withdrawalReversedFailed: '逆转失败',
      withdrawalPartialSuccess: '部分成功',
    },
    formErrorMsg: {
      bankName: '请填写银行名称',
      bsb: '请填写BSB',
      beneName: '请填写帐户名',
      accNum: '请填写帐户号码',
      swift: '请填写Swift',
      bankAddress: '请填写银行地址',
      holderAddress: '请填写持卡人地址',
      amt: '请填写金额',
      firstSixDigits: '请填写卡号前六位',
      lastFourDigits: '请填写卡号前四位',
      branchName: '请填写分行名称',
      email: '请填写电子邮件',
      accName: '请填写银行账户名',
      province: '请填写银行所在省',
      city: '请填写银行所在市',
      cardbankCard: '请选择一张卡',
      emailFormat: '请填写正确的电邮地址',
      amt0: '金额不能为0',
      bitcoin: '请填写Bitcoin钱包地址',
      addressLength: '比特币钱包地址必须在 26 到 42 个字符之间',
      usdt: '请填写USDT钱包地址',
      beneficiaryNameReq: '需要银行受益人姓名',
    },
    transfer: {
      info1:
        '{platform} 将免除当月第一次提款的银行手续费，同月内的额外提款将产生20个单位的提款货币的银行手续费（对于USC/HKD/JPY，该费用则相当于20美元）。',
      info2:
        '请确保银行受益人姓名完全按照护照上的内容填写（禁止使用缩写，按照对账单上的首字母填写全称）。',
      info3: '银行账户以IBAN格式为佳，如果客户不是来自欧盟地区，请按银行对账单准确填写。',
      label: {
        upload: '请上传一张近3个月银行对账单的截图、照片或复印件',
        upload2: '请确保对账单日期或最后交易日期为最近3个月，若超出此时间范围，请重新上传对账单。',
      },
      formValidation: { upload: '请上传银行帐单' },
      minAmount: '最低提款金额为 100 {currency}',
    },
    transitNumber: '中转号码',
    institutionNumber: '机构编号',
    beneficiaryName: '受益人',
  },
  security: {
    contactSupport: '无法访问身份验证方法？请联系{contact_email}或Livechat',
    securityAuthenticator: '安全验证',
    verificationRequired: '请输入验证码',
    resend: '重新发送',
    authenticatorTooltip: '身份验证器应用程序的验证码每30秒刷新一次',
    verifyPreEmailNote: '验证码将发送到 {email}',
    verifyPostEmailNote: '验证码已发送到 {email}',
    enableNow: '立即启用',
    verificationTitle: '需要验证',
    verificationDesc: '请在继续之前启用安全验证器应用程序。',
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: '转账申请',
    transferAmountIsGreaterThanAvailableBalance: '您的转账金额大于可用余额',
    yourRebateTransferHasBeenProcessed: '您的转账申请已提交',
    PleaseEnterTransferInformation: '请输入准确的转账信息',
    tradingAccount: '交易账号',
    rebateAccount: '返佣账号',
    account: '账号',
    blackList: '此账户已被限制进行账户间转账，请联系 {supportEmail}',
  },
  common: {
    liveChat: { desc: '需要帮助？在线客服' },
    lang: {
      english: '英文',
      chinese: '中文',
      french: '法文',
      thai: '泰文',
      german: '德文',
      spanish: '西班牙文',
      malay: '马来西亚文',
      viet: '越南文',
      indo: '印尼文',
      arabic: '阿拉拍文',
    },
    button: {
      iKnow: '我明白了',
      confirmCamelCase: '确认',
      confirm: '确认',
      submit: '提交',
      cancel: '取消',
      sendCode: '发送验证码',
      resendCode: '重发验证码',
      ok: '确认',
    },
    withdrawChannel: {
      limitTitle: '暂时不支持提现',
      limitPassword:
        '由于您修改了登录密码，为了您的账户资金安全，{day} 天内暂不支持提现。预计{hour} 小时后解除限制。',
      limitUserLogin:
        '由于您修改了登录邮箱/手机号，为了您的账户资金安全，{day} 天内暂不支持提现。预计{hour} 小时后解除限制。',
      limitSecurityAuthenticator:
        '由于您的安全验证器应用程序的修改，为了确保您的帐户安全，暂时不支持 {day} 天的出金。此限制预计在 {hour} 小时内解除。',
      banktransfer: '国际银行转账',
      aus: '银行转账 (澳大利亚)',
      int: '银行转账 (国际)',
      chn: '银行转账 (中国)',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: '银联转帐',
      unionpaytransfer: '银联转帐',
      fasapay: 'FasaPay',
      creditcard: '信用卡',
      bpay: 'Bpay',
      creditdebitcard: '信用卡/借记卡',
      bankwiretransferinternational: '银行转帐 (国际)',
      banktransferaustralia: '银行转帐 (澳大利亚)',
      banktransferinternational: '国际银行转账',
      banktransferchina: '银行转帐 (国际)',
      mobilepay: '移动支付',
      brokertobrokertransfer: '交易商到交易商',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '线上银行 (泰国)',
      internetbankingnigeria: '线上银行 (尼日利亚)',
      internetbankingvietnam: '线上银行 (越南)',
      internetbankingmalaysia: '线上银行 (马来西亚)',
      internetbankingindonesia: '线上银行 (印度尼西亚)',
      internetbankingkorea: '线上银行 (韩国)',
      internetbankingindia: '线上银行 (印度)',
      internetbankingphilippine: '线上银行 (菲律宾)',
      internetbankingsouthafrica: '线上银行 (南非)',
      internetbankinguganda: '线上银行 (乌干达)',
      internetbankingrwanda: '线上银行 (卢旺达)',
      internetbankingzambia: '线上银行 (赞比亚)',
      internetbankingcongo: '线上银行 (刚果)',
      internetbankingcameroon: '线上银行 (喀麦隆)',
      internetbankingburundi: '线上银行 (布隆迪)',
      internetbankingkenya: '线上银行 (肯尼亚)',
      internetbankingghana: '线上银行 (加纳)',
      internetbankingtanzania: '线上银行 (坦桑尼亚)',
      internetbankingcanada: '线上银行 (加拿大)',
      internetbankingbrazil: '线上银行 (巴西)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '越南银行银行转帐',
      nigeriabanktransfer: '尼日利亚银行转帐',
      polipayment: 'Poli支付',
      bankwiretransferaustralia: '银行转帐 (澳大利亚)',
      thailandinstantbankwiretransfer: '泰国银行即时转账',
      malaysiainstantbankwiretransfer: '马来西亚银行即时转账',
      banktransferbpaypolipay: '银行转帐/BPay银行转帐/PoLi支付',
      cryptocurrencybitcoin: '加密货币-Bitcoin',
      cryptocurrencyusdt: '加密货币-USDT',
      cryptocurrencyethcps: '加密货币-ETH',
      cryptocurrencyusdccps: '加密货币-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: '尼日利亚银行转帐',
      vietnaminstantbankwiretransfer: '越南银行即时转账',
      indonesiainstantbankwiretransfer: '印度尼西亚银行即时转账',
      philippinesinstantbankwiretransfer: '菲律宾银行即时转账',
      southafricainstantbankwiretransfer: '南非银行即时转账',
      indiainstantbanktransfer: '印度银行即时转账',
      indiabanktransfer: '印度银行转帐',
      southkoreabanktransfer: '韩国即时银行电汇',
      ugandabanktransfer: '乌干达当地银行转账',
      rwandabanktransfer: '卢旺达当地银行转账',
      zambiabanktransfer: '赞比亚当地银行转账',
      congobanktransfer: '刚果当地银行转账',
      cameroonbanktransfer: '喀麦隆当地银行转账',
      burundibanktransfer: '布隆迪当地银行转账',
      kenyabanktransfer: '肯尼亚当地银行转账',
      ghanabanktransfer: '加纳当地银行转账',
      tanzaniabanktransfer: '坦桑尼亚当地银行转账',
      brazilbanktransfer: '巴西银行转账',
      banktransferbpaypolipayaustralia: '银行转帐/BPay银行转帐/PoLi支付 (澳大利亚)',
      banktransferbpaypolipayinternational: '银行转帐/BPay银行转帐/PoLi支付 (国际)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: '加拿大本地银行转帐',
      cryptobitcoin: '加密货币-Bitcoin',
      cryptotetheromni: '加密货币-Tether(OMNI)',
      cryptotethererc20: '加密货币-Tether(ERC20)',
      ryptotethertrc20: '加密货币-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: '转账至账号',
      E_WALLET: '电子钱包',
      CRYPTOCURRENCY: '加密货币',
      LOCAL_TRANSFER: '本地转帐',
      japanbanktransfer: '日本银行转账',
      internetbankingmexico: '线上银行 (墨西哥)',
      internetbankinguae: '线上银行（阿联酋）',
      ewallet: '电子钱包',
      internetbankingtaiwan: '网络银行 (台湾)',
      internetbankingeu: '网络银行（欧盟）',
    },
    country: { international: '国际', australia: '澳大利亚' },
    field: {
      country_region: '国家/地区',
      address: '地址',
      phone: '电话',
      nat: '国籍',
      country: '居住国家',
      save: '投资',
      annIncome: '年收入',
      emailAdd: '电子邮件地址',
      yes: '是',
      no: '否',
      enterVerificationCode: '输入验证码',
      ebuyAccName: 'EBuy 账户名称',
      ebuyEmailAddress: 'EBuy 邮箱地址',
      skrillAccName: 'Skrill 账户名称',
      skrillEmailAddress: 'Skrill 邮箱地址',
      netellerAccName: 'Neteller 账户名称',
      netellerEmailAddress: 'Neteller 邮箱地址',
      imptNotes: '备注',
      paymentAccName: '{name} 账户名',
      paymentAccNum: '{name} 账号',
      documentType: '文件类型',
      accDigit: '账号数位',
      docID: '文件 ID',
      bankBranchCode: '分行代码',
      accType: '账号类型',
      accName: '账户名',
      accNum: '账号',
      swiftCode: 'SWIFT代码',
      bankName: '银行名称',
      bankAddress: '银行地址',
      bankHolderAddress: '账号持有人地址',
      bankBeneficiaryName: '收款人姓名',
      bankAccNum: '银行账号',
    },
    keys: {
      REGISTRATION: '注册日期',
      FTD: '首次入金日期',
      CURRENTCAMPAIGN: '当前活动',
      blacklisted: '活动黑名单',
      FIRST_TRANS_IN: '首次转账转入日期',
      SUBMIT: '提交',
      FROM: '从',
      TO: '到',
      DATE: '开户日期',
      UPDATE: '更新',
      pleaseSelect: '请选择',
      amount: '金额',
      rebateAccount: '返佣账号',
      comingSoon: '敬请期待',
      noData: '没有数据',
      NAME: '姓名',
      EMAIL: '电子邮件',
      ACCTYPE: '账户类型',
      PLATFORM: '交易平台',
      BASECURRENCY: '币种',
      DATERANGE: '申请时间',
      STATUS: '状态',
      DEST: '目标帐户',
      tradingHistory: '交易历史',
      ACCNUM: '账号',
      BALANCE: '余额',
      PROFITLOST: '损益',
      MARGINLVL: '保证金水平',
      ACCEQUITY: '账户净值',
      CREDIT: '信用',
      NONE: '无',
      ALL: '全部',
      actualAmonut: '实际入金',
      currency: '货币类型',
      method: '方式',
      createTime: '提交时间',
      lastUpdateTime: '处理时间',
    },
    dateRange: {
      today: '今天',
      yesterday: '昨天',
      lastSevenDays: '最近7天',
      lastThirtyDays: '最近30天',
      weekToDate: '本周',
      monthToDate: '本月',
      quarterToDate: '本季度',
      previousWeek: '上周',
      previousMonth: '上月',
      previousQuarter: '上季度',
    },
    products: { forex: '外汇', commodities: '大宗商品', indices: '指数', crypto: '加密货币' },
    formValidation: {
      dynamicReq: '请填写{dynamic}',
      verCodeReq: '验证码为必填项',
      alphanumericReq: '请填写字母数字',
      answerReq: '请选择',
      sameAsPOA: '身份证序列号输入需要与ID相同',
      sameAsID: '身份证序列号输入需要与ID相同',
      notBlank: '不适用可留空',
      digits12WithoutDash: '请输入12位数字，不要包含“-”',
      enterAsPerId: '根据身份证输入',
      numberReq: '请输填写号码',
      addressReq: '请填写详细地址',
      suburbReq: '请填写城市或区',
      stateReq: '请填写省份或州',
      postcodeReq: '请填写邮政编码',
      countryReq: '请选择主要居住国家',
      AlphanumericOnlyNotSpace: '仅支持字母和数字。不允许使用空格。',
      AlphanumericOnly: '仅支持字母和数字。',
      NumbersSymbol: '仅支持数字和符号。',
      NumbersOnlyNotSpace: '仅支持数字。不允许使用空格。',
      NumbersOnly: '仅支持数字。',
      LettersSymbol: '仅支持字母和符号。',
      LettersSymbolNotSpace: '仅支持字母和符号。不允许使用空格。',
      LettersOnlyNotSpace: '仅支持字母。不允许使用空格。',
      NotSpace: '不允许使用空格。',
    },
  },
  report: {
    title: { rebateReport: '佣金报告' },
    rebate: {
      column: {
        date: '日期',
        name: '名字',
        accountNumber: '账号',
        accountType: '账号类型',
        volume: '交易量',
        rebate: '佣金',
        totalRebate: '总佣金',
        instrument: '产品',
      },
      dropdown: { allInstruments: '所有产品', all: '全部', instruments: '产品' },
      others: {
        lots: '手数',
        rebate: '佣金',
        totalRebate: '总佣金',
        instrumentsTraded: '已交易产品',
      },
    },
    comingSoon: '敬请期待',
    noData: '没有数据',
  },
  ibReport: {
    header: 'IB 报告',
    netFunding: '净入金',
    deposits: '总入金',
    withdraw: '总出金',
    openedAccs: '已开账户数',
    funding: '出入金记录',
    withdrawals: '总出金',
    appliedAmount: '申请金额',
    depositAmount: '入金金额',
    withdrawalAmount: '出金金额',
    defaultTimeMessage: '最大查询下载范围为90天。',
    timeLimitMessage: '最大的下载查询范围为90天，请更新新的日期范围。',
    successInfo: '下载成功',
    download: '下载',
    comfirm: '确认',
  },
  lead: { header: '潜在客户', ibAccount: '返佣账号', demoAccounts: '模拟账户' },
  pendingAccounts: {
    header: '待审核客户',
    errorDoc: '以下客户已经提交注册申请, 但还未提供满足开设账户的相关证件.',
    acceptedDocType: '可接受的证件类型包括:',
    primaryDoc: '身份证明',
    passport: '身份证(正面与背面)',
    id: '护照（完整的个人信息页）',
    dl: '有效的驾驶证',
    address: '地址证明',
    addressList: [
      '最近的公用事业账单副本（不超过6个月）',
      '信用卡对账单副本(不超过6个月)',
      '银行对账单副本（不超过6个月）',
    ],
    tip: '请联系客户提供一份身份证明和地址证明到 ',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: '交易历史',
    rebateHistory: '佣金申请记录',
    withdrawHistory: '佣金出金记录',
    transferHistory: '转账历史',
    cancelWithdraw: '您要取消提款请求吗？',
    cancelError:
      '您的提款目前正在处理中。 因此，我们不能接受您的取消请求。 如需更多信息，您可以使用实时聊天功能联系我们。',
  },
  fileUploader: {
    supportedFormat: '文件支持类型: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: '文件支持类型: {files}',
    maxSize: '最大文件大小: {maxSize}MB',
    maxFileNum: '最多文件数: {maxFileNum}',
    selectFile: '上传文件',
    maxFileSize: '上传文件大小不能超过 {maxSize}MB',
    sureToDelete: { title: '确定删除？', content: '您确定要删除已上传的文件吗？' },
  },
  transferHistory: {
    payStatus: { processing: '处理中', paid: '已付款', unpaid: '未付款', rejected: '被拒绝' },
  },
  referralLinks: {
    header: '推广链接',
    ibAcc: '返佣账号',
    campLang: '推广语言',
    cpy: '复制链接',
    alreadyCPY: '已复制!',
    liveAcc: '真实账户',
    demoAcc: '模拟账户',
    homePage: '主页链接',
    Share: '分享',
    subIbReferral: 'Sub IB 推荐',
  },
  myProfile: { changeDetails: '如您想更改账户资讯, 请联系: {mailTo}' },
  unfundedAcc: {
    header: '未入金客户',
    allActivationAccs: '所有已激活账户',
    neverFundedAccs: '从未入金',
    prevFundedZero: '余额为0',
  },
  multiib: { level: '{level} 级', noib: '此帐户下没有子介绍经纪商（IB）。' },
  header: { bckToCP: '返回客户后台', logout: '登出' },
  menu: {
    dashboard: '概览',
    ibReport: 'IB 报告',
    rebateReport: '佣金报告',
    accManagement: '账户管理',
    iblevel: '多级介绍经纪商',
    ibAccs: '已激活账户',
    leads: '潜在客户',
    pendingAccs: '待审核客户',
    unfundedAccs: '未入金客户',
    payHistory: '交易历史',
    ibProfile: '个人信息',
    refLinks: '推广链接',
    contactUs: '联系我们',
    switchCP: '返回客户后台',
  },
  footer: {
    riskWarn:
      '风险提示：差价合约（CFD）交易会给您的资金带来高风险，并可能导致损失，您只应使用可以承受损失的资金进行交易。 差价合约交易可能并不适合所有投资者，请确保您完全了解所涉及的风险并采取适当措施进行管理。 请仔细阅读相关的风险披露文件。',
  },
  responseMsg: {
    410: '参数验证失败',
    411: '密码错误',
    420: '用户不存在',
    421: '密码重置被拒',
    431: '正在计算返利，请稍后再申请',
    490: '请不要重复输入卡信息',
    500: '发生错误',
    501: '申请失败, 请稍后重试',
    505: '文件上传失败',
    520: '登录失败, 请重试',
    521: '您在此设备上已处于登入状态!',
    522: '请重新登录',
    523: '请重新登录',
    524: '您提供的邮箱尚未注册',
    525: '用户名或密码错误',
    527: '用户不是IB',
    528: '用户不存在',
    529: '用户名或密码错误',
    530: '登录会话无效',
    540: '系统找不到您的交易账号',
    541: '无法找到返佣账号',
    542: '抱歉，您不允许开同名账号',
    544: '您不允许申请一个额外的账户，直到您的身份证明核实为止',
    550: '账号错误',
    551: '您的余额不足',
    553: '您好！您当前的账号尚有信用金，暂时无法提交出金/转账，如需帮助请发送邮件至{email}或联系官方客服',
    554: '请求的数额为0或者空',
    562: '无法处理您的支付请求，请重新尝试。如问题仍未解决，请通过Live Chat或者电子邮件{email}与我们取得联系',
    564: '国家未能配合该支付渠道',
    565: '银行代码错误',
    566: '无法找到支付方式',
    581: '您已成功选择加入促销活动。 请注意，您必须先入金才能出金。',
    582: '您正在参与活动且有持仓，无法提交出金申请。',
    584: '您没有可申请的佣金',
    590: '无法找到转出账号',
    591: '无法找到转入账号',
    593: '由于您的账户包含信贷部分，我们无法对您所选择的账户进行资金转移处理。',
    594: '不允许从这个账户转移资金',
    595: '您正在参与活动，无法提交转账申请。',
    647: '验证码错误',
    1101: '已创建10个共享账号，无法继续新建',
    1102: '无法启用，该帐户已创建为真实帐户或模拟帐户。',
    1103: '账号已被停用',
    1202: '验证码已过期，请重试',
    1204: '请尝试使用另一个电子邮件地址',
    1208: '不能重复发送验证码',
    1209: '发送验证码错误',
    session_timeout: '登录时间超时, 正在回到登录页面',
  },
  verificationStatus: {
    title: { default: '就快设置完毕！', LV3: '欢迎' },
    dueToAML: '您很快就可以开始交易了！通过验证您的个人信息帮助我们保护您的帐户。',
    accOpeningVerification: { title: '创建真实账户', content: '创建真实账户并开始入金' },
    identityVerification: { title: '身份验证', content: '提交并验证您的身份证明以开始交易。' },
    poaVerification: { title: '地址验证', content: '提款前需要验证 POA。' },
    kyc: {
      depositNow: '立即入金',
      withdrawNow: '立即提款',
      createNow: '立即创建',
      start: '开始验证',
      complete: '创建完成',
      pending: '审核中',
      rejected: '拒收',
      resubmit: '重新上传',
    },
    resubmitNote: '您的文件可能缺失或不正确，请重新提交。',
    rejectedNote: '有任何疑问？请使用 {liveChat} 联系我们。',
    liveChat: '在线聊天',
    uploadTemplate: {
      idGuideTitle: '身份证件指南',
      uploadGuideTitle: '上传指南',
      desc1: '政府发行',
      desc2: '原始全尺寸、未经编辑的文档',
      desc3: '将文件放在单色背景下',
      desc4: '可读、光线充足、彩色图像',
      desc5: '没有黑白图像',
      desc6: '没有编辑过或过期的文件',
      bottomDesc: '上传的文件可以是jpg、png或pdf，文件大小不能超过5MB',
    },
  },
  register: {
    progressStatus: { title: '注册进度', estimatedTimeTitle: '预计时间', min: '分钟' },
    tabs: {
      personalDetails: '个人信息',
      moreAboutYou: '详细资料',
      accountConfig: '账户配置',
      confirmYourId: '文件上传',
      fundYourAcc: '即刻入金',
      personalInformation: '个人信息',
      createLiveAccount: '创建真实账户',
      financialInfo: '财务信息',
      iDProof: '身份证明',
      addressProof: '地址证明',
    },
    btn: {
      next: '下一步',
      back: '上一步',
      fundnow: '即刻入金',
      upload: '上传',
      done: '完成',
      bTCP: '回到客户端',
      proceedToUpload: '继续上传',
      completeID: '完成身份验证',
      completePOA: '完成 POA 验证',
      depositViaOther: '通过其他渠道入金',
      withdrawViaOther: '通过其他渠道出金',
    },
    authority: {
      dialog1: '您必须具有真实账户才能访问客户后台的此部分。',
      dialog2:
        '现在开设一个真实账户以解锁对您的客户后台的完全访问权限，其中包括一系列交易工具，促销活动以及更多其他内容',
      openLiveAcc: '开设真实账户',
    },
    demo: {
      congrad: '恭喜!',
      title: '您的60天测试 MT{platform} 账户现已启用',
      title2: '您的测试账户详细信息',
      li: { login: '帐号', srv: '服务器', expDate: '到期日' },
      emailSent: '登录详细信息也已发送到您的注册电子邮件',
      dl: '下载您的 MT{platform} 交易平台',
      improveQ: '你想改善交易能力？',
      improveWay: '只需在几分钟内开设一个真实账户即可解锁一系列独家交易工具和促销活动',
      proTrade: '专业市场分析报告',
      customerService: '24/7 一对一专业客服',
      competitivePromotionals: '具有竞争力的活动',
      secure: '3D安全交易',
      openLiveAcc: '开设真实账户',
    },
    personalDetails: {
      page1: {
        pageTitle: '个人信息',
        userTitle: '头衔',
        firstName: '名字',
        lastName: '姓氏',
        email: '电子邮箱',
        dob: '出生日期',
        idType: '证件类型',
        referral: '是否有推荐人？(选填)',
        middleName: '中间名',
        nationality: '国籍',
        phoneNum: '电话号码',
        date: '日',
        month: '月',
        year: '年',
        placeOfBirth: '出生地',
        idNum: '证件号码',
        passportNum: '护照号码',
        driverLicenseNum: '驾照号码',
        titleDropdown: {
          mr: '先生',
          mrs: '太太',
          ms: '女士',
          miss: '小姐',
          dr: '博士',
          prof: '教授',
        },
        idTypeDropdown: { passport: '护照', idCard: '身份证', driverLicense: '驾照' },
      },
      page2: {
        pageTitle: '主要居住地址',
        mainResidency: '主要居住国家',
        streetNumber: '街道号码',
        streetName: '街道名',
        address: '详细地址',
        provinceOrState: '省份/州',
        cityOrSuburb: '城市/区',
        postcode: '邮政编码',
        usQuestion: '是否为美国公民或是在美国纳税？',
      },
    },
    moreAboutYou: {
      pageTitle1: '工作与财务信息',
      pageTitle2: '投资经验',
      complianceExplain: '根据AMF/CTF的要求，我们需要收集您的以下信息.',
    },
    accountConfig: {
      pageTitle: '账户配置',
      chooseTradingPlatform: '选择交易平台',
      chooseAccType: '选择账户类型',
      chooseAccCurrency: '选择账户币种',
    },
    confirmYourId: {
      pageTitle: '文件上传',
      description: '我们需要验证您的身份. 请上传以下所需证件或者发送文件至我们邮箱{mailTo}',
      poidTitle: '身份证明',
      porTitle: '地址证明',
      uploadDescription: '请选择以下一种文件类型并上传',
      note: '请注意: 您的全名必须在您提供的以上文件中清晰可见。',
      pending: { upload: '待上传', verification: '待审核' },
      poidTypes: {
        photoId: '身份证 (正面和背面)',
        passport: '护照 (完整的个人信息页)',
        drivingLicence: '有效的驾驶证',
      },
      porTypes:
        '<li>房产证/居住证(有效期间内)</li>\n      <li>驾驶证(仅限无使用驾驶证为身份证明者适用)</li>\n      <li>近三个月内的水电煤账单、银行账单</li>\n      <li>户口本</li>\n      <li>租赁合同(需加盖公章)</li>\n      <li>派出所或居委会开具的居住证明(需加盖公章)</li>',
    },
    finishPage: {
      title: '{individualUserName}, 感谢您注册{platformName}真实账户!',
      contentDefault:
        '我们已经收到了您的申请并成功开通了您的真实账户.  不久之后，您会在<b> {email} </ b>收到一封电子邮件，其中包含您的登录详细信息.',
      contentWithoutId3WithAccount:
        '您的交易账户申请目前正在处理中, 我们将会尽快审核并通过邮件方式告知您处理结果.  您可以立即入金来获取优先开户服务.',
      contentWithoutId3WithoutAccount:
        '您的交易账户申请目前正在处理中, 我们将会尽快审核并通过邮件方式告知您处理结果.',
    },
    accOpeningVerification: {
      accOpenVer: '开户验证',
      personalInformation: {
        narrative: '开设真实账户需要您的个人信息',
        firstName: '姓名',
        middleName: '中间名',
        lastName: '姓氏',
        gender: '性别',
        genderList: { male: '男', female: '女', other: '其他' },
        dob: '出生日期',
        day: '日',
        month: '月',
        year: '年',
        mobile: '电话号码',
        countryName: '现居国家',
        nationality: '国籍',
      },
      createLiveAccount: {
        narrative:
          '完成几个步骤即可开户入金。更多关于我们帐户类型的信息，<a href="{link}" target="_blank" alt>请点击此处</a>。',
        choose: '选择 {dynamic}',
        tradingPlatform: '交易平台',
        accountType: ' 账户类型',
        accountCurrency: '账户币种',
        islamicTooltip:
          '{platform} 可能需要您提供文件以验证您是否信奉伊斯兰教。如果验证失败， {platform} 保留修改您的帐户配置的权利。',
        successInfo: {
          title: '交易账户创建完成',
          content: '恭喜！您已开户成功。 <br /> 立即入金并完成您的身份验证即可开始交易。',
        },
        successInfo2: {
          title: '交易账户创建完成',
          content: '填写您的财务资料以进行下一步的帐户验证',
          start: '开始填写',
        },
        generalInfo: {
          title: '您提交的内容正在审核中。',
          content: '感谢您与 {platform} 开设账户。一旦您的提交获得批准，即可为您的账户入金。',
        },
        rejectedInfo: {
          title: '拒收',
          content: '我们很遗憾地通知您，您开设 {platform} 账户的请求已被拒绝。',
        },
      },
    },
    identityVerification: {
      title: '身份验证',
      subTitle: '身份信息',
      narrative: '填写资料',
      firstName: '姓名',
      middleName: '中间名',
      lastName: '姓氏',
      nationality: '国籍',
      idType: '证件类型',
      idSerialNumber: '身份证序列号',
      passportNum: '护照号码',
      driverLicenseNum: '驾照号码',
      upload: {
        title: '上传文件',
        desc: '确保文件显示您的照片、全名、出生日期和签发日期',
        guideline: '上传指南',
        positive: '上传正面',
        signaturePage: '上传签名页',
      },
      idTypeDropdown: { passport: '护照', idCard: '身份证', driverLicense: '驾照', other: '其他' },
      additionalInfo: { title: '需要额外信息', content: '验证您的身份以开始交易' },
      successInfo: {
        title: '身份验证完成',
        content:
          '恭喜！您的身份已通过验证。如果您已经入金，现在可以开始在 MT4/MT5 或 {platform} 应用程序上进行交易。',
      },
      generalInfo: {
        title: '您提交的内容正在审核中。',
        content: '感谢您与 {platform} 开设账户。一旦您的身份信息得到验证，就可以开始交易了。',
      },
      rejectedInfo: {
        title: '拒收',
        content: '我们很遗憾地通知您，您开设 {platform} 账户的请求已被拒绝。',
      },
      identifyingRequiredInfo: {
        title: '需要额外信息',
        content: '您的一些身份证明文件不齐全或不正确。请重新上传。',
      },
      streetNumber: '街道号码',
      streetName: '街道名',
    },
    poaVerification: {
      proofOfAddressVer: '地址验证证明',
      subTitle: '地址信息',
      narrative: '地址信息',
      dueToAML: '由于反洗钱请求，您必须先验证您的地址才可提款。',
      nationality: '国籍',
      country: '现居国家',
      address: '地址',
      postalCode: '邮政编码',
      citySuburb: '城市/区',
      city: '城市/区',
      provinceState: '省份/州',
      upload: {
        title: 'POA证书',
        inst1: '房产证/居住证(水电煤账单）',
        inst2: '银行账单',
        desc: 'POA（提款委托书）必须是近 {months} 个月内',
        guideline: '上传指南',
        uploadFilesPhoto: '上传文件/照片',
      },
      additionalInfo: { title: '需要额外信息', content: '提款前需要验证 POA信息。' },
      successInfo: {
        title: '地址证明验证成功',
        content: '恭喜！您的 POA 已通过验证。您现可提款了。',
      },
      generalInfo: {
        title: '您提交的内容正在审核中。',
        content: '感谢您与 {platform} 进行交易。一旦您的提交获得批准，您就可以开始提款流程。',
      },
      identifyingRequiredInfo: {
        title: '需要额外信息',
        content: '您的一些身份证明文件不齐全或不正确。请重新上传。',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: '您的财务信息',
        subTitle: '财务信息',
        subTitle2: '投资经验',
        employmentStatus: '工作状态',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '未来一周预计的 {title}',
        estimatedAnnualIncome: '年收入',
        estimatedSavingsAndInvestments: '储蓄金与投资',
        estimatedIntendedDeposit: '您期望的入金金额',
        sourceOfFunds: '资金来源',
        numberOfTrades: '交易次数',
        tradingAmount: '交易金额',
      },
      iDProof: { ver: '上传身份证明', subTitle: '身份信息', narrative: '填写资料' },
      addressProof: {
        ver: '上传地址证明 (POA)',
        subTitle: '地址信息',
        narrative: '地址信息',
        upload: {
          title: '可接受的地址证明文件',
          inst1: '房产证/居住证(水电煤账单）',
          inst2: '银行账单',
          desc: 'POA（提款委托书）必须是近 {months} 个月内',
          guideline: '上传指南',
          uploadFilesPhoto: '上传文件/照片',
        },
      },
      successInfo: { title: '银行认证完成', content: '恭喜！您已成功验证您的银行详细信息。' },
      generalInfo: {
        title: '您提交的内容正在审核中。',
        content:
          '您的银行转账文件仍在审核中。如果您希望立即开始交易，您仍然可以通过其他渠道进行存款。',
      },
      rejectedInfo: { title: '拒收', content: '您通过银行转帐存款的请求已被拒绝。' },
      identifyingRequiredInfo: {
        title: '需要额外信息',
        content: '您的一些身份证明文件不齐全或不正确。请重新上传。',
      },
    },
  },
  ibProfile: {
    title: '个人信息',
    subTitle: 'Shared IB',
    sharedIbList: {
      sharedIbAccount: 'IB共享账号',
      maxSharedIbAccount: '最多可创建10个共享账号',
      add: '新建账号',
      accountHistory: '共享账号使用记录',
      setting: '设置',
      delete: '删除',
      enable: '启用',
      disable: '停用',
      enabled: '已启用',
      disabled: '已停用',
      sharedAccountEnabled: '共享账号已启用',
      sharedAccountDisabled: '共享账号已停用',
      sharedAccountCopied: '共享账号已复制',
      deleteTips: '删除后，该共享账号将无法再登录，是否删除？',
      sharedAccountDeleted: '共享账号已删除',
      copyTips: '共享账号已复制',
      resetPassword: '修改密码',
      remark: '备注',
      cancel: '取消',
      accountData: '客户数据',
      IBData: 'IB 数据',
      fundOperations: '资金操作',
      accountHistoryBtn: '账号使用记录',
    },
    updateSharedIb: {
      addNewSharedIb: '新建共享账号',
      editSharedIb: '编辑共享账号',
      addSuccess: '共享账号创建成功',
      maxSharedIbAccountError: '已创建10个共享账号，无法继续新建',
      permissionIdsTips: '您可以选择是否允许共享账号的以下权限：',
      permissionIdsFirst:
        '<b>客户数据</b>: 查看客户相关页面的数据（客户管理、近期新开户），不允许权限则相关数据会隐藏显示',
      permissionIdsSecond:
        '<b>IB 数据</b>：查看 IB 相关数据（概览数据、多级介绍经纪商-账号），不允许权限则相关数据会隐藏显示',
      permissionIdsThird:
        '<b>资金操作</b>：操作 IB 资金（申请佣金、转账、出金），查看 IB 佣金（佣金报告、余额、资金记录），不允许权限则无法操作和查看',
      email: '共享账号邮箱',
      emailPlaceholder: '请输入邮箱地址',
      emailValidate: '请输入有效电子邮件',
      emailError: '邮箱已被使用，请尝试其他邮箱',
      password: '设置登录密码',
      passwordValidate: '请输入密码',
      newPassword: '请输入新的密码',
      newPassowrdReset: '请输入新密码以重置',
      passwordValidateNum: '8-16个字符 ',
      passwordValidateTips: '混合大小写字母（A-Z)，数字(0-9)和特殊字符（如：!@#S%^&）',
      passwordValidateWarning:
        '8-16个字符，混合大小写字母（A-Z)，数字(0-9)和特殊字符（如：!@#S%^&）',
      passwordSame: '新密码不能与旧密码相同',
      sharedIbAccountPermission: '共享账号权限',
      remark: '备注',
      remarkTips: '账号备注可用于记录如使用者、用途等',
      status: '共享账号状态',
      cancel: '取消',
      confirm: '确认',
      disabled: '已停用',
      enabled: '已启用',
      changePasswordTips: '新的密码修改成功后，旧密码将会立即失效',
      resetPassword: '修改密码',
      resetPasswordSuccess: '密码修改成功',
      enterNewPassword: '请输入新的密码',
      fundsChangeTips: '允许资金操作后，子账号可操作IB主账号余额的入金与出金，是否允许该操作？',
      editSuccess: '修改成功',
    },
    sharedIbHistory: {
      viewMore: '查看更多',
      allHistory: '已展示全部记录',
      lastUpdate: '更新时间',
      refreshTips: '记录已更新',
      refresh: '刷新',
      chooseAccount: '请选择账号',
      device: '设备',
      login: '操作 登录',
      commission: '操作 申请佣金',
      withdraw: '操作 出金',
      fromTo: '从 {fromAccount} 到 {toAccount}，金额 {total}',
      transfer: '操作 转账',
    },
    npPermissionTips: '当前账号无权限操作',
  },
  agreement: {
    title: '确认介绍经纪人协议',
    rebateAccount: '返佣账号',
    description: '点击同意即确认您已阅读、理解并同意协议中的所有信息、服务条款和条件',
    agree: '同意',
    signed: '返佣账号 {accountID}. 签署于 {signedTime}',
    IBAgreement: 'IB 协议',
    confirmAgreement: '请联系 IB 经理确认协议',
  },
};
