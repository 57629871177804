export default {
  en: 'Englisch',
  home: {
    NewPortal: 'Neues Portal',
    SecureIBPortal: 'Sicheres IB-Portal',
    DASHBOARD: 'DASHBOARD',
    MARKETING_TOOLS: 'MARKETING-TOOLS',
    IB_REPORT: 'IB-BERICHT',
    REBATE_REPORT: 'RABATT-BERICHT',
    IB_ACCOUNTS: 'IB-KONTEN',
    PAYMENT_HISTORY: 'TRANSAKTIONSHISTORIE',
    IB_FAQ: 'IB-FAQ',
    RebateAccountNumber: 'Rabatt-Kontonummer',
    totalBalance: 'Gesamtguthaben',
    totalCommission: 'Gesamtprovision',
    APPLY_FOR_A_REBATE: 'ANWENDEN',
    availableBalance: 'verfügbares Guthaben',
    WITHDRAW_REBATE: 'AUSZAHLUNGEN',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Überweisung',
    MonthtoDate: 'Monat bis heute',
    UPDATE: 'AKTUALISIEREN',
    REBATE: 'RABATT',
    TOTAL_VOLUME: 'GESAMTVOLUMEN',
    NET_FUNDING: 'NETTO-FINANZIERUNG',
    DEPOSITS: 'EINZAHLUNGEN',
    WITHDRAW: 'AUSZAHLUNGEN',
    OPEND_ACCOUNT: 'ERÖFFNETES KONTO',
    PERFORMANCE: 'PERFORMANCE',
    OpendAccount: 'ERÖFFNETES KONTO',
    select_ib: 'Bitte IB-Konto auswählen',
    MOST_TRADED_INSTRUMENTS: 'MEIST GEHANDELTE INSTRUMENTE',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'KONTEN MIT DER BESTEN PERFORMANCE IN DIESEM MONAT',
    RECENTLY_OPENED_ACCOUNTS: 'KÜRZLICH ERÖFFNETE KONTEN',
    ACCOUNT_MANAGER: 'KUNDENSUPPORT',
    Account: 'Konto',
    Name: 'Name',
    Volume: 'Volumen',
    Rebate: 'Rabatte',
    Balance: 'Guthaben',
    ContactClient: 'Kundenkontakt',
    YouCannotAskForCommission: 'Sie können keine Provision beantragen',
    applicationFailedPleaseTryAgain:
      'Der Antrag ist fehlgeschlagen, bitte versuchen Sie es später noch einmal',
    applyRebateSuccessTip:
      'Herzlichen Glückwunsch, wir haben Ihren Rabattantrag erhalten. | {amount} wird in Kürze auf das Rabattkonto {account} überwiesen. | Sobald die Bearbeitung abgeschlossen ist, können Sie Ihr Geld abheben oder überweisen.',
    HOVER:
      'Die Rabatte des Vortages werden täglich zwischen 09:00 - 11:00 AEST berechnet und verarbeitet.',
    blacklist:
      'Für dieses Konto können derzeit keine Rabatte angewendet werden. Bitte wenden Sie sich an Ihren Account Manager.',
    ACCOUNTS_PROFIT:
      'Historischer Gesamtgewinn/-verlust aller Handelskonten mit diesem IB-Konto. Das Laden kann einen Moment dauern.',
    PROFIT: 'KONTEN PROFITIEREN',
    reload: 'Neu laden',
  },
  liveChat: { header: 'Live-Chat' },
  contactUs: {
    click: 'Klicken Sie jetzt',
    assist: 'für Unterstützung',
    header: 'KONTAKT',
    followUs: 'FOLGEN SIE UNS',
    tip: 'Das professionelle, mehrsprachige Kundensupport-Team von {platform} ist bereit, Sie zu unterstützen und Ihnen ein unvergleichliches Trading-Erlebnis zu bieten.',
    chatNow: 'Lassen Sie uns jetzt chatten',
    globalOffice: 'Globales Büro',
  },
  ibAccounts: {
    header: 'IB-KONTEN',
    ibAccount: 'IB-Konto',
    subIbs: 'IB-Unterkonto',
    tradinglist: 'Handelsliste',
    openlist: 'Liste öffnen',
    closedlist: 'Geschlossene Liste',
    tradinglistColumn: {
      ticketid: 'Ticketnummer',
      opentime: 'Offene Zeit',
      type: 'Typ',
      volume: 'Volumen',
      item: 'Artikel',
      openprice: 'Offener Preis',
      closeprice: 'Preis schließen',
      closetime: 'Schließzeit',
      commission: 'Kommission',
      profit: 'Profitieren',
      buy: 'Besorgen',
      sell: 'Verkaufen',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Kreditkarte wählen',
      selectBA: 'Bankkonto wählen',
      anotherCC: 'Kreditkarte manuell eingeben',
      anotherBA: 'Bankkonto hinzufügen',
      remember: 'Mein Konto merken',
      verificationCode:
        'Der Verifizierungscode wurde an {email} gesendet. Bitte kontaktieren Sie {supportEmail}, wenn Sie weitere Hilfe benötigen',
      fetchTradeAccFailed: 'Abrufen des Trading-Kontos fehlgeschlagen',
      failed: 'Auszahlung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
      tokenExpired: 'Token ist abgelaufen',
    },
    alert:
      'Aufgrund von AML-Gesetzen muss die Methode, die Sie zur Auszahlung verwenden, dieselbe sein wie die, die Sie für Ihre Einzahlung verwendet haben. Wenn der Auszahlungsbetrag weniger als 100 Einheiten der Basiswährung Ihres Kontos beträgt, wird Ihnen von der Bank eine Bearbeitungsgebühr von 20 Einheiten berechnet.',
    NetellerChargeAlert:
      'Neteller erhebt eine Transaktionsgebühr von {percentageFee}% für jede Auszahlung.',
    FasaPayChargeAlert: 'FasaPay berechnet für jede Auszahlung eine Transaktionsgebühr von 0,5%.',
    SkrillChargeAlert:
      'Skrill erhebt eine Transaktionsgebühr von {percentageFee}% für jede Auszahlung.',
    cryptoAlert:
      'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT vornehmen können. Bitte stellen Sie sicher, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, sonst könnten Sie die Gelder verlieren.',
    REBATE_WITHDRAW_REQUEST: 'ANTRAG AUF RABATTAUSZAHLUNG',
    PleaseSelectDesiredWithdrawMethod: 'Bitte wählen Sie die gewünschte Auszahlungssmethode',
    BankName: 'Bank Name',
    BankAccountsName: 'Bankkonto Name',
    FasaPayAccountName: 'FasaPay Kontoname',
    AustralianBankName: 'Australische Bank Name',
    BankAccountNumber: 'Bankkontonummer',
    FasaPayAccountNumber: 'FasaPay-Kontonummer',
    BankAccountBeneficiary: 'Bankkonto Begünstigter',
    BSB: 'BSB',
    skrillEmail: 'Skrill E-Mail',
    SticpayEmail: 'SticPay E-Mail',
    NetellerEmail: 'Neteller-E-Mail',
    PerfectMoneyEmail: 'Perfect Money-Email',
    BitwalletEmail: 'Bitwallet E-Mail',
    BitcoinEmail: 'Bitcoin-Wallet Adresse',
    USDTEmail: 'Tether-Wallet Adresse',
    BankBranch: 'Bankfiliale',
    Province: 'Provinz',
    City: 'Stadt',
    BankAddress: 'Bank Adresse',
    bankCity: 'Bank Stadt',
    bankProvince: 'Bank Provinz',
    AccountNumberIBAN: 'Kontonummer/IBAN',
    BankBeneficiaryName: 'Bank Name des Begünstigten',
    BankAccountBeneficiaryName: 'Bankkonto Name des Begünstigten',
    AccountHoldersAddress: 'Adresse des Kontoinhabers',
    SelectWithdrawCard: 'Wählen Sie Ihre Auszahlungskarte',
    Swift: 'Swift',
    ifscCode: 'IFSC-Code',
    SwiftAud: 'Swift (Nicht-AUD-Konten)',
    ABA_SortCodeABA: 'ABA/Sort-Code',
    ImportantNotes: 'Wichtige Hinweise',
    YourPaymentGreaterThanAvailableBalance: 'Ihre Zahlung ist höher als das verfügbare Guthaben',
    CannotUseMoreThanTwoDecimalValues:
      'Sie können keine Zahlen und negativen Zahlen mit mehr als zwei Dezimalstellen verwenden',
    YourPaymentIsBeingProcessed: 'Ihr Auszahlungsantrag wurde eingereicht',
    Itcannotbe0: 'Es kann nicht 0 sein',
    noCardWarn:
      'Sie haben keine verfügbare Karte für die Auszahlung, bitte verknüpfen Sie Ihre Karte zuerst in den Auszahlungsdetails',
    amtLarger:
      'Der Betrag kann nicht null sein und muss größer oder gleich ${minLimit} {currency} sein',
    blackList:
      'Leider können Sie derzeit keinen Auszahlungsantrag einreichen. Bitte kontaktieren Sie {supportEmail} für weitere Informationen',
    status: {
      withdrawalSubmitted: 'Abgesendet',
      withdrawalCanceled: 'Abgebrochen',
      withdrawalAuditing: 'In Bearbeitung',
      withdrawalRejected: 'Abgelehnt',
      withdrawalAccepted: 'In Bearbeitung',
      withdrawalPayFailed: 'Gescheitert',
      withdrawalSuccess: 'Verarbeitet',
      withdrawalFailed: 'Gescheitert',
      withdrawalPending: 'Ausstehend',
      withdrawalPartialFailed: 'Gescheitert',
      transferSubmitted: 'In Bearbeitung',
      transferSuccess: 'Verarbeitet',
      transferRejected: 'Abgelehnt',
      transferFailed: 'Gescheitert',
      transferProcessing: 'In Bearbeitung',
      withdrawalReversedFailed: 'Umgekehrtes Versagen',
      withdrawalPartialSuccess: 'Teilweiser Erfolg',
    },
    formErrorMsg: {
      bankName: 'Bankname ist erforderlich',
      bsb: 'BSB ist erforderlich',
      beneName: 'Bank Name des Begünstigten ist erforderlich',
      accNum: 'Bankkontonummer ist erforderlich',
      swift: 'Swift ist erforderlich',
      bankAddress: 'Bankadresse ist erforderlich',
      holderAddress: 'Inhaberadresse ist erforderlich',
      amt: 'Betrag ist erforderlich',
      firstSixDigits: 'Bitte geben Sie die ersten 6 Ziffern ein',
      lastFourDigits: 'Bitte geben Sie die letzten 4 Ziffern ein',
      branchName: 'Filialname ist erforderlich',
      email: 'E-Mail ist erforderlich',
      accName: 'Kontoname ist erforderlich',
      province: 'Bank Provinz ist erforderlich',
      city: 'Bank Stadt ist erforderlich',
      bankCard: 'Bitte wählen Sie eine Karte',
      emailFormat: 'Bitte korrekte E-Mail Adresse eingeben',
      amt0: 'Betrag kann nicht 0 sein',
      ifscCode: 'IFSC-Code ist erforderlich',
      bitcoin: 'Bitcoin-Wallet-Adresse ist erforderlich',
      addressLength: 'Bitcoin-Wallet-Adresse muss zwischen 26 und 42 Zeichen betragen',
      usdt: 'USDT-Wallet-Adresse ist erforderlich',
      beneficiaryNameReq: 'Bankbegünstigtenname ist erforderlich',
    },
    transfer: {
      info1:
        '{platform} erlässt Ihnen die Bankgebühren für die erste Auszahlung im Monat. Für weitere Auszahlungen innerhalb desselben Monats wird eine Bankgebühr in Höhe von 20 Einheiten Ihrer Auszahlungswährung erhoben (für USC/HKD/JPY entspricht die Gebühr USD 20).',
      info2:
        'Bitte vergewissern Sie sich, dass der Name des Begünstigten genau mit dem im Reisepass angegebenen Namen übereinstimmt (keine Abkürzungen, bitte den vollständigen Namen entsprechend den Initialen auf dem Kontoauszug angeben).',
      info3:
        'Bankverbindung vorzugsweise im IBAN-Format. Wenn Sie nicht aus der EU kommen, geben Sie bitte die genaue Bankverbindung laut Kontoauszug an.',
      label: {
        upload:
          'Bitte laden Sie eine Kopie Ihres Kontoauszugs hoch (aktuell für die letzten 3 Monate).',
        upload2:
          'Stellen Sie sicher, dass das Datum des Kontoauszugs oder das Datum der letzten Transaktion im aktuellen Monat bis zu den letzten drei Monaten liegt. Wenn dies den Zeitrahmen überschreitet, laden Sie bitte den Kontoauszug erneut hoch.',
      },
      formValidation: { upload: 'Bitte laden Sie einen Kontoauszug hoch.' },
      minAmount: 'Der Mindestabhebungsbetrag beträgt 100 {currency}',
    },
    transitNumber: 'Transitnummer',
    institutionNumber: 'Institutionsnummer',
    beneficiaryName: 'Begünstigter Name',
  },
  security: {
    contactSupport:
      'Zugriff auf die Authentifizierungsmethode nicht möglich? Bitte kontaktieren Sie {contact_email} oder Livechat',
    securityAuthenticator: 'Sicherheitsauthentifizierung',
    verificationRequired: 'Bitte geben Sie den Bestätigungscode ein',
    resend: 'Erneut senden',
    authenticatorTooltip:
      'Der Bestätigungscode der Authenticator-App wird alle 30 Sekunden aktualisiert',
    verifyPreEmailNote: 'Der Code wird an {email} gesendet',
    verifyPostEmailNote: 'Code wurde an {email} gesendet',
    enableNow: 'Jetzt aktivieren',
    verificationTitle: 'Verifizierung erforderlich',
    verificationDesc:
      'Bitte aktivieren Sie die Sicherheitsauthentifikator-App, bevor Sie fortfahren.',
  },
  rebate: {
    status: { processed: 'Verarbeitet', processing: 'In Bearbeitung', rejected: 'Abgelehnt' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'RABATT-ÜBERWEISUNGSANTRAG',
    transferAmountIsGreaterThanAvailableBalance:
      'Ihr Überweisungsbetrag ist größer als das verfügbare Guthaben',
    yourRebateTransferHasBeenProcessed: 'Ihre Rabatt-Überweisung wurde eingereicht',
    PleaseEnterTransferInformation: 'Bitte geben Sie genaue Überweisungsinformationen ein',
    tradingAccount: 'Trading-Konto',
    rebateAccount: 'Rabatt-Konto',
    account: 'Konto',
    blackList:
      'Dieses Konto wurde für Transfers zwischen Konten gesperrt. Bitte kontaktieren Sie {supportEmail}',
  },
  common: {
    liveChat: { desc: 'Brauchen Sie Hilfe? Live-Chat' },
    lang: {
      english: 'Englisch',
      chinese: 'Chinesisch',
      french: 'Französisch',
      thai: 'Thailändisch',
      german: 'Deutsch',
      spanish: 'Spanisch',
      malay: 'Malaiisch',
      viet: 'Vietnamesisch',
      indo: 'Indonesisch',
      arabic: 'Arabisch',
    },
    button: {
      iKnow: 'Ich verstehe',
      confirmCamelCase: 'Bestätigen',
      confirm: 'BESTÄTIGEN',
      submit: 'Absenden',
      cancel: 'ABBRECHEN',
      sendCode: 'Code senden',
      resendCode: 'Code erneut senden',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: 'Auszahlungen werden vorübergehend nicht unterstützt',
      limitPassword:
        'Aufgrund der Änderung Ihres Login-Passworts werden Abhebungen vorübergehend für {day} Tage nicht unterstützt, um die Sicherheit Ihres Kontos zu gewährleisten. Diese Einschränkung wird voraussichtlich in {hour} Stunden aufgehoben.',
      limitUserLogin:
        'Aufgrund der Änderung Ihrer Anmelde-E-Mail-/Telefonnummer werden Abhebungen für {day} Tage vorübergehend nicht unterstützt. Diese Einschränkung wird voraussichtlich in {hour} Stunden aufgehoben.',
      limitSecurityAuthenticator:
        'Aufgrund der Änderung Ihrer Sicherheits-Authenticator-App werden Auszahlungen vorübergehend für {day} Tage nicht unterstützt, um die Sicherheit Ihres Kontos zu gewährleisten. Diese Einschränkung wird voraussichtlich in {hour} Stunden aufgehoben.',
      banktransfer: 'Internationale Banküberweisung',
      aus: 'Banküberweisung - Australien',
      int: 'Banküberweisung - International',
      chn: 'Banküberweisung - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill/Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'UnionPay Überweisung',
      fasapay: 'Fasapay',
      creditcard: 'Kreditkarte',
      bpay: 'Bpay',
      creditdebitcard: 'Kredit-/Debitkarte',
      bankwiretransferinternational: 'Banküberweisung (International)',
      banktransferaustralia: 'Banküberweisung (Australien)',
      banktransferinternational: 'Internationale Banküberweisung',
      banktransferchina: 'Banküberweisung (International)',
      mobilepay: 'Mobiles Bezahlen',
      brokertobrokertransfer: 'Broker-zu-Broker-Überweisung',
      skrillmoneybookers: 'Skrill/Moneybookers',
      internetbankingthailand: 'Internet-Banking (Thailand)',
      internetbankingnigeria: 'Internet-Banking (Nigeria)',
      internetbankingvietnam: 'Internet-Banking (Vietnam)',
      internetbankingmalaysia: 'Internet-Banking (Malaysia)',
      internetbankingindonesia: 'Internet-Banking (Indonesia)',
      internetbankingkorea: 'Internet-Banking (Korea)',
      internetbankingindia: 'Internet-Banking (Indien)',
      internetbankingphilippine: 'Internet-Banking (Philippinen)',
      internetbankingsouthafrica: 'Internet-Banking (Südafrika)',
      internetbankinguganda: 'Internet-Banking (Uganda)',
      internetbankingrwanda: 'Internet-Banking (Ruanda)',
      internetbankingzambia: 'Internet-Banking (Sambia)',
      internetbankingcongo: 'Internet-Banking (Kongo)',
      internetbankingcameroon: 'Internet-Banking (Kamerun)',
      internetbankingburundi: 'Internet-Banking (Burundi)',
      internetbankingkenya: 'Internet-Banking (Kenia)',
      internetbankingghana: 'Internet-Banking (Ghana)',
      internetbankingtanzania: 'Internet-Banking (Tansania)',
      internetbankingcanada: 'Internet-Banking (Kanada)',
      internetbankingbrazil: 'Internet-Banking (Brasilien)',
      skrillneteller: 'Skrill/Neteller',
      vietnambanktransfer: 'Vietnam Banküberweisung',
      nigeriabanktransfer: 'Nigeria Banküberweisung',
      polipayment: 'Poli-Zahlung',
      bankwiretransferaustralia: 'Banküberweisung (Australien)',
      thailandinstantbankwiretransfer: 'Thailand Sofortige Banküberweisung',
      malaysiainstantbankwiretransfer: 'Malaysia Sofortige Banküberweisung',
      banktransferbpaypolipay: 'Banküberweisung/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Kryptowährung-Bitcoin',
      cryptocurrencyusdt: 'Kryptowährung-USDT',
      cryptocurrencyethcps: 'Kryptowährung-ETH',
      cryptocurrencyusdccps: 'Kryptowährung-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: 'Nigeria Banküberweisung',
      vietnaminstantbankwiretransfer: 'Vietnamesische Sofortüberweisung',
      indonesiainstantbankwiretransfer: 'Indonesien Sofortüberweisung',
      philippinesinstantbankwiretransfer: 'Philippinische Sofortige Banküberweisung',
      southafricainstantbankwiretransfer: 'Südafrikanische Instant BankÜberweisung',
      indiainstantbanktransfer: 'Indien Sofortige Banküberweisung',
      southkoreabanktransfer: 'Südkorea lokale Banküberweisung',
      ugandabanktransfer: 'Uganda lokale Banküberweisung',
      rwandabanktransfer: 'Ruanda lokale Banküberweisung',
      zambiabanktransfer: 'Sambia lokale Banküberweisung',
      congobanktransfer: 'Kongo lokale Banküberweisung',
      cameroonbanktransfer: 'Kamerun lokale Banküberweisung',
      burundibanktransfer: 'Burundi lokale Banküberweisung',
      kenyabanktransfer: 'Kenia lokale Banküberweisung',
      ghanabanktransfer: 'Ghana lokale Banküberweisung',
      tanzaniabanktransfer: 'Tansania lokale Banküberweisung',
      brazilbanktransfer: 'Brasilianische Banküberweisung',
      banktransferbpaypolipayaustralia: 'Banküberweisung/BPay/PoliPay (Australien)',
      banktransferbpaypolipayinternational: 'Banküberweisung/BPay/PoliPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Kanadische lokale Banküberweisung',
      cryptobitcoin: 'Krypto-Bitcoin',
      cryptotetheromni: 'Krypto-Tether (OMNI)',
      cryptotethererc20: 'Krypto-Tether (ERC20)',
      astropay: 'Astropay',
      vload: 'Vload',
      sticpay: 'SticPay',
      transfer: 'Überweisung auf Konten',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'KRYPTOWÄHRUNG',
      LOCAL_TRANSFER: 'LOKALER TRANSFER',
      japanbanktransfer: 'Japanische Banküberweisung',
      indiabanktransfer: 'Indien Banküberweisung',
      internetbankingmexico: 'Internet-Banking (Mexiko)',
      internetbankinguae: 'Online-Banking (VAE)',
      ewallet: 'E-Geldbörse',
      internetbankingtaiwan: 'Internet-Banking (Taiwan)',
      internetbankingeu: 'Internet-Banking (EU)',
    },
    country: { international: 'International', australia: 'Australien' },
    field: {
      country_region: 'Land/Region',
      address: 'Adresse',
      phone: 'Telefonnummer',
      nat: 'Nationalität',
      country: 'Land des Wohnsitzes',
      save: 'Investition',
      annIncome: 'Jährliches Einkommen',
      emailAdd: 'E-Mail-Adresse',
      yes: 'Ja',
      no: 'Nein',
      enterVerificationCode: 'Verifizierungscode eingeben',
      ebuyAccName: 'EBuy-accountnaam',
      ebuyEmailAddress: 'EBuy e-mailadres',
      skrillAccName: 'Skrill-accountnaam',
      skrillEmailAddress: 'Skrill e-mailadres',
      netellerAccName: 'Neteller-accountnaam',
      netellerEmailAddress: 'Neteller e-mailadres',
      imptNotes: 'Wichtige Hinweise',
      paymentAccName: '{name} Name des Kontos',
      paymentAccNum: '{name} Kontonummer',
      documentType: 'Dokument type',
      accDigit: 'Kontonummer',
      docID: 'ID des Dokuments',
      bankBranchCode: 'Filialcode',
      accType: 'Konto-Typ',
      accName: 'Name des Kontos',
      accNum: 'Kontonummer',
      bankName: 'Bankname',
      swiftCode: 'SWIFT-Code',
      bankAddress: 'Bankadresse',
      bankHolderAddress: 'Adresse des Kontoinhabers',
      bankBeneficiaryName: 'Bank Begünstigter Name',
      bankAccNum: 'Kontonummer der Bank',
    },
    keys: {
      SUBMIT: 'ABSENDEN',
      FROM: 'VON',
      UPDATE: 'AKTUALISIEREN',
      TO: 'AN',
      DATE: 'ERÖFFNUNGSDATUM',
      pleaseSelect: 'Bitte auswählen',
      amount: 'Betrag',
      amountIn: 'Betrag in',
      rebateAccount: 'Rabatt-Konto',
      comingSoon: 'demnächst',
      tradingHistory: 'TRADING-HISTORIE',
      noData: 'Keine Daten',
      NAME: 'NAME',
      EMAIL: 'E-MAIL',
      ACCTYPE: 'KONTO-TYP',
      PLATFORM: 'PLATTFORM',
      BASECURRENCY: 'BASISWÄHRUNG',
      DATERANGE: 'DATUMSBEREICH',
      STATUS: 'STATUS',
      DEST: 'ZIEL',
      ACCNUM: 'KONTONUMMER',
      BALANCE: 'GUTHABEN',
      PROFITLOST: 'PROFITIEREN',
      MARGINLVL: 'MARGIN-PEGEL',
      ACCEQUITY: 'KONTOEIGENKAPITAL',
      CREDIT: 'KREDIT',
      NONE: 'Keine',
      ALL: 'Alle',
      actualAmonut: 'Tatsächlicher Betrag',
      currency: 'WÄHRUNG',
      method: 'METHODE',
      REGISTRATION: 'REGISTRIERUNGSDATUM',
      FTD: 'EINZAHLUNGSDATUM',
      CURRENTCAMPAIGN: 'AKTUELLE KAMPAGNE',
      blacklisted: 'Auf der schwarzen Liste',
      FIRST_TRANS_IN: 'ERSTE TRANS IN DATUM',
      createTime: 'Übermittlungszeit',
      lastUpdateTime: 'Verarbeitete Zeit',
    },
    dateRange: {
      today: 'Heute',
      yesterday: 'Gestern',
      lastSevenDays: 'Letzte 7 Tage',
      lastThirtyDays: 'Letzte 30 Tage',
      weekToDate: 'Woche bis heute',
      monthToDate: 'Monat bis heute',
      quarterToDate: 'Quartal bis heute',
      previousWeek: 'Vorherige Woche',
      previousMonth: 'Vorheriger Monat',
      previousQuarter: 'Vorheriges Quartal',
    },
    products: { forex: 'FOREX', commodities: 'ROHSTOFFE', indices: 'INDIZES', crypto: 'CRYPTO' },
    formValidation: {
      dynamicReq: '{dynamic} ist erforderlich',
      verCodeReq: 'Verifizierungscode ist erforderlich',
      alphanumericReq: 'Bitte alphanumerisch eingeben',
      answerReq: 'Bitte wählen Sie',
      sameAsPOA: 'Die Eingaben müssen mit dem Adressnachweis übereinstimmen',
      sameAsID: 'Die Eingaben müssen mit dem Identitätsnachweis übereinstimmen',
      notBlank: 'Bitte leer lassen wenn nicht zutreffend',
      digits12WithoutDash: 'Bitte geben Sie 12 Ziffern ein, ohne "-".',
      enterAsPerId: 'Geben Sie gemäß der ID ein',
      numberReq: 'Bitte Nummer eingeben',
      addressReq: 'Bitte geben Sie Ihre Adresse ein',
      suburbReq: 'Bitte geben Sie Ihre Stadt oder Ihren Vorort ein',
      stateReq: 'Bundesland oder Provinz ist erforderlich',
      postcodeReq: 'Bitte geben Sie Ihre Postleitzahl ein',
      countryReq: 'Bitte wählen Sie ihr Wohnsitzland',
      AlphanumericOnlyNotSpace:
        'Nur Buchstaben und Zahlen erlaubt. Leerzeichen sind nicht erlaubt.',
      AlphanumericOnly: 'Nur Buchstaben und Zahlen.',
      NumbersSymbol: 'Nur Zahlen und Symbole.',
      NumbersOnlyNotSpace: 'Nur Zahlen erlaubt. Leerzeichen sind nicht erlaubt.',
      NumbersOnly: 'Nur Zahlen.',
      LettersSymbol: 'Nur Buchstaben und Symbole.',
      LettersSymbolNotSpace: 'Nur Buchstaben und Symbole erlaubt. Leerzeichen sind nicht erlaubt.',
      LettersOnlyNotSpace: 'Nur Buchstaben erlaubt. Leerzeichen sind nicht erlaubt.',
      NotSpace: 'Leerzeichen sind nicht erlaubt.',
    },
  },
  report: {
    title: { rebateReport: 'RABATT-BERICHT' },
    rebate: {
      column: {
        name: 'NAME',
        accountNumber: 'KONTONUMMER',
        accountType: 'KONTO-TYP',
        volume: 'Volumen',
        rebate: 'Rabatt',
        totalRebate: 'GESAMTRABATT',
        date: 'DATUM',
        instrument: 'INSTRUMENT',
      },
      dropdown: { allInstruments: 'Alle Instrumente', all: 'Alle', instruments: 'Instrumente' },
      others: {
        rebate: 'RABATT',
        instrumentsTraded: 'GEHANDELTE INSTRUMENTE',
        lots: 'Lots',
        totalRebate: 'GESAMTRABATT',
        rebates: 'Rabatte',
      },
    },
  },
  ibReport: {
    header: 'IB-BERICHT',
    netFunding: 'NETTO-FINANZIERUNG',
    deposits: 'EINZAHLUNGEN',
    withdraw: 'AUSZAHLUNGEN',
    openedAccs: 'ERÖFFNETE KONTEN',
    funding: 'FINANZIERUNG',
    withdrawals: 'AUSZAHLUNGEN',
    appliedAmount: 'angewendeter Betrag',
    depositAmount: 'Einzahlungsbetrag',
    withdrawalAmount: 'Auszahlungsbetrag',
    timeLimitMessage:
      'Der maximale Abfragebereich für den Download beträgt 90 Tage. Bitte aktualisieren Sie den neuen Datumsumfang.',
    successInfo: 'Download erfolgreich',
    download: 'HERUNTERLADEN',
    comfirm: 'Bestätigen',
    defaultTimeMessage: 'Der maximale Abfragebereich für den Download beträgt 90 Tage',
  },
  lead: { header: 'LEADS', ibAccount: 'IB-Konto', demoAccounts: 'DEMO-KONTEN' },
  pendingAccounts: {
    header: 'AUSSTEHENDE KONTEN',
    errorDoc:
      'Die folgenden Antragsteller haben das Online-Antragsformular ausgefüllt, müssen aber noch einen Identifikationsnachweis (ID) erbringen, um ihr Konto abschließen zu können.',
    acceptedDocType: 'Akzeptierte Formen der ID sind:',
    primaryDoc: 'Primäre-ID',
    passport: 'Eine Kopie Ihres gültigen internationalen Reisepasses (Unterschriftenseite)',
    id: 'Eine Kopie Ihres staatlich ausgestellten Personalausweises',
    dl: 'Eine Kopie Ihres gültigen Führerscheins',
    address: 'Sekundäre-ID',
    addressList: [
      'Kopie einer aktuellen Nebenkostenabrechnung (nicht älter als 6 Monate)',
      'Kopie einer Kreditkartenabrechnung (nicht älter als 6 Monate)',
      'Kopie eines Kontoauszugs (nicht älter als 6 Monate)',
    ],
    tip: 'Bitte fordern Sie Antragsteller auf, eine Kopie EINES der akzeptierten primären und sekundären Ausweisdokumente vorzulegen, um ',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'TRANSAKTIONSHISTORIE',
    rebateHistory: 'RABATTE',
    withdrawHistory: 'AUSZAHLUNGEN',
    transferHistory: 'ÜBERWEISUNGEN',
    cancelWithdraw: 'Möchten Sie die Auszahlungsanfrage stornieren?',
    cancelError:
      'Ihre Auszahlung wird gerade bearbeitet. Daher können wir Ihre Stornierungsanfrage nicht annehmen. Für weitere Informationen können Sie uns über die Live-Chat-Funktion erreichen.',
  },
  fileUploader: {
    supportedFormat: 'Unterstützte Dateitypen: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Unterstützte Dateitypen: {files}',
    maxSize: 'Maximale Dateigröße beim Hochladen: {maxSize}MB',
    maxFileNum: 'Maximale Dateianzahl: {maxFileNum}',
    selectFile: 'Datei auswählen',
    maxFileSize: 'Die Größe der hochgeladenen Datei darf {maxSize} MB nicht überschreiten',
    sureToDelete: {
      title: 'Sind Sie sicher?',
      content: 'Sind Sie sicher, dass Sie die hochgeladene Datei löschen möchten？',
    },
  },
  transferHistory: {
    payStatus: {
      processing: 'In Bearbeitung',
      paid: 'Bezahlt',
      unpaid: 'Unbezahlt',
      rejected: 'Abgelehnt',
    },
  },
  referralLinks: {
    header: 'EMPFEHLUNGSLINKS',
    ibAcc: 'IB-Konto',
    campLang: 'Kampagnen-Sprache',
    cpy: 'KOPIE',
    alreadyCPY: 'KOPIERT!',
    liveAcc: 'Live-Konto',
    demoAcc: 'Demo-Konto',
    homePage: 'Startseite',
    Share: 'Teilen',
    subIbReferral: 'IB-Weiterleitung',
  },
  settings: { ibProfile: 'IB-PROFIL', info: 'INFO', reg: 'REGISTRIERT AUF' },
  myProfile: {
    changeDetails:
      'Wenn Sie Ihre bestehenden Profildaten aktualisieren möchten, zögern Sie nicht, sich an {mail To} zu wenden',
  },
  unfundedAcc: {
    header: 'UNGEDECKTE KONTEN',
    allActivationAccs: 'ALLE AKTIVIERUNGSKONTEN',
    neverFundedAccs: 'NIE FINANZIERTE',
    prevFundedZero: 'KEIN GUTHABEN',
  },
  multiib: { level: 'EBENE {level}', noib: 'Es gibt keine Sub-IBs unter diesem Konto.' },
  header: { bckToCP: 'ZURÜCK ZUM KUNDENPORTAL', logout: 'AUSLOGGEN' },
  menu: {
    dashboard: 'DASHBOARD',
    ibReport: 'IB-BERICHT',
    rebateReport: 'RABATT-BERICHT',
    accManagement: 'KONTEN',
    iblevel: 'MULTI-LEVEL',
    ibAccs: 'AKTIVIERTE KONTEN',
    leads: 'LEADS',
    pendingAccs: 'AUSSTEHENDE KONTEN',
    unfundedAccs: 'UNGEDECKTE KONTEN',
    payHistory: 'TRANSAKTIONSHISTORIE',
    ibProfile: 'IB-PROFIL',
    refLinks: 'EMPFEHLUNGSLINKS',
    contactUs: 'KONTAKT',
    switchCP: 'ZUM KUNDENPORTAL WECHSELN',
  },
  footer: {
    riskWarn:
      'Risiko-Warnung: Das Traden mit Differenzkontrakten (CFDs) birgt ein hohes Risiko für Ihr Kapital und kann zu Verlusten führen. Sie sollten nur mit Geld traden, das  zu verlieren Sie sich leisten können. Das Traden mit CFDs ist möglicherweise nicht für alle Investoren geeignet. Bitte stellen Sie sicher, dass Sie die damit verbundenen Risiken vollständig verstehen und geeignete Maßnahmen ergreifen, um diese zu steuern. Bitte lesen Sie das entsprechende Dokument mit den Risikohinweisen sorgfältig durch.',
  },
  responseMsg: {
    410: 'Parametervalidierung fehlgeschlagen',
    411: 'Falsches Passwort',
    420: 'Benutzer existiert nicht',
    421: 'Passwortrücksetzung verweigert',
    431: 'Die Rebateberechnung ist in Arbeit, bitte versuchen Sie Ihre Anfrage in Kürze erneut',
    490: 'Bitte geben Sie keine doppelten Karteninformationen ein',
    500: 'Es ist ein Fehler aufgetreten.',
    501: 'Antrag fehlgeschlagen. Bitte versuchen Sie es später erneut.',
    505: 'Datei-Upload fehlgeschlagen',
    520: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    521: 'Es ist bereits ein Benutzer auf diesem Gerät angemeldet!',
    522: 'Bitte melden Sie sich erneut an',
    523: 'Bitte melden Sie sich erneut an',
    524: 'Leider wurde Ihre E-Mail-Adresse nicht erkannt',
    525: 'Falscher Benutzername oder falsches Passwort',
    527: 'Benutzer ist kein IB',
    528: 'Benutzer existiert nicht',
    529: 'Falscher Benutzername oder falsches Passwort',
    530: 'Ungültige Anmeldesitzung',
    540: 'Ihr Trading-Konto konnte nicht gefunden werden',
    541: 'Kann Rabatt-Konto nicht finden',
    542: 'Sie dürfen kein zusätzliches Konto eröffnen',
    544: 'Sie können kein weiteres Konto beantragen, bis Ihr Identitätsnachweis verifiziert wurde',
    550: 'Falsche Kontonummer',
    551: 'Ihr Guthaben ist nicht ausreichend',
    553: 'Sie können aufgrund des Guthabens auf Ihrem Konto nicht auszahlen/überweisen. Bitte kontaktieren Sie {email} für weitere Hilfe.',
    554: 'Der angeforderte Betrag ist Null oder nicht gültig',
    562: 'Wir konnten Ihre Zahlung nicht verarbeiten, bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte per Live-Chat oder E-Mail {email}',
    564: 'Land stimmt nicht mit dem Zahlungskanal überein',
    565: 'Falsche Bankleitzahl',
    566: 'Zahlungsmethode nicht gefunden',
    581: 'Sie haben sich erfolgreich für die Promotion angemeldet. Bitte beachten Sie, dass Sie eine Einzahlung vornehmen müssen, um Geld auszahlen zu können.',
    582: 'Da Sie an der Kampagne teilnehmen und offene Positionen haben, können wir Ihren Auszahlungsantrag nicht bearbeiten.',
    584: 'Sie haben keine anwendbaren Rabatte',
    590: 'Transfer-Out-Konto nicht gefunden',
    591: 'Kann Einbuchungskonto nicht finden',
    593: 'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto Guthaben enthält.',
    594: 'Geldtransfer von diesem Konto ist nicht erlaubt',
    595: 'Da Sie an unserer Kampagne teilnehmen, können wir Ihre Überweisungsanfrage nicht bearbeiten',
    647: 'Verifizierungscode ist nicht korrekt',
    1101: '10 Konten existieren bereits, es können keine weiteren hinzugefügt werden.',
    1102: 'Die Aktivierung ist nicht möglich, da das Konto als Live- oder Demokonto erstellt wurde.',
    1103: 'Konto wurde deaktiviert',
    1202: 'Verifizierung abgelaufen, bitte erneut versuchen',
    1204: 'Bitte versuchen Sie es mit einer anderen E-Mail-Adresse',
    1208: 'Überprüfungscodes können nicht wiederholt gesendet werden',
    1209: 'Fehler beim Senden des Bestätigungscodes',
    session_timeout: 'Session-Timeout, Weiterleitung zur Anmeldeseite.',
  },
  verificationStatus: {
    title: { default: 'Sie haben es fast geschafft!', LV3: 'Herzlich willkommen' },
    dueToAML:
      'Sie können schon bald mit dem Trading beginnen! Helfen Sie uns, Ihr Konto zu schützen, indem Sie Ihre persönlichen Daten verifizieren.',
    accOpeningVerification: {
      title: 'Live-Konto erstellen',
      content: 'Erstellen Sie ein Live-Konto, um mit Einzahlungen auf Ihr Konto zu beginnen.',
    },
    identityVerification: {
      title: 'Verifizierung der Identität',
      content:
        'Übermitteln und verifizieren Sie Ihren Identitätsnachweis, um mit dem Trading zu beginnen.',
    },
    poaVerification: {
      title: 'Verifizierung der Adresse',
      content:
        'Der Adressnachweis muss verifiziert werden, bevor Sie eine Auszahlung vornehmen können.',
    },
    kyc: {
      depositNow: 'Jetzt einzahlen',
      withdrawNow: 'Jetzt auszahlen',
      createNow: 'Jetzt erstellen',
      start: 'Start',
      complete: 'Abgeschlossen',
      pending: 'Ausstehend',
      rejected: 'Abgelehnt',
      resubmit: 'Erneut übermitteln',
    },
    resubmitNote: 'Ihre Dokumente könnten fehlen oder fehlerhaft sein. Bitte erneut übermitteln.',
    rejectedNote: 'Fragen? Bitte kontaktieren Sie uns über den {liveChat}.',
    liveChat: 'Live-Chat',
    uploadTemplate: {
      idGuideTitle: 'Richtlinien für ID-Dokumente',
      uploadGuideTitle: 'Richtlinien für das Hochladen',
      desc1: 'Von den Behörden ausgestellt',
      desc2: 'Originaldokumente in voller Größe, unbearbeitet',
      desc3: 'Dokumente auf einfarbigem Hintergrund platzieren',
      desc4: 'Gut lesbares, gut beleuchtetes, farbiges Bild',
      desc5: 'Keine Schwarz-Weiß-Bilder',
      desc6: 'Keine bearbeiteten oder abgelaufenen Dokumente',
      bottomDesc:
        'Die hochzuladende Datei kann das Format jpg, png oder pdf haben und darf nicht größer als 5 MB sein',
    },
  },
  register: {
    progressStatus: {
      title: 'Fortschritt der Verifizierung',
      estimatedTimeTitle: 'geschätzte Zeit',
      min: 'Minuten',
    },
    tabs: {
      personalDetails: 'Persönliche Details',
      moreAboutYou: 'Mehr über Sie',
      accountConfig: 'Konto-Konfiguration',
      confirmYourId: 'Bestätigen Sie Ihre Identität',
      fundYourAcc: 'Auf Ihr Konto einzahlen',
      personalInformation: 'Persönliche Daten',
      createLiveAccount: 'Live-Konto erstellen',
      financialInfo: 'Finanzielle Informationen',
      iDProof: 'ID-Nachweis',
      addressProof: 'Adressnachweis',
    },
    btn: {
      next: 'WEITER',
      back: 'ZURÜCK',
      fundnow: 'JETZT EINZAHLEN',
      upload: 'Hochladen',
      done: 'FERTIG',
      bTCP: 'Zurück zum Kundenportal',
      proceedToUpload: 'Weiter zum Upload',
      completeID: 'ID-Verifizierung abschließen',
      completePOA: 'Verifizierung des Adressnachweises abschließen',
      depositViaOther: 'Einzahlung über einen anderen Kanal',
      withdrawViaOther: 'Über einen anderen Kanal abheben',
    },
    authority: {
      dialog1:
        'Sie müssen ein Live-Konto haben, um auf diesen Bereich des Kundenportals zugreifen zu können.',
      dialog2:
        'Eröffnen Sie jetzt ein Live-Konto, um den vollen Zugang zu Ihrem Kundenportal freizuschalten, das eine Reihe von Trading-Werkzeugen, Promotionen und vieles mehr enthält.',
      openLiveAcc: 'Live-Konto eröffnen',
    },
    demo: {
      congrad: 'Herzlichen Glückwunsch!',
      title: 'Ihr 60-tägiges MT {platform} DEMO-Konto ist jetzt aktiv',
      title2: 'Details zu Ihrem DEMO-Konto',
      li: { login: 'ANMELDEN', srv: 'Server', expDate: 'Ablaufdatum' },
      emailSent: '* Die Anmeldedaten wurden auch an Ihre registrierte E-Mail gesendet.',
      dl: 'Laden Sie Ihre MT{platform} Trading-Plattform herunter',
      improveQ: 'Möchten Sie Ihr Trading verbessern?',
      improveWay:
        'Eröffnen Sie einfach ein Live-Konto in nur wenigen Minuten, um eine Reihe von exklusiven Trading-Werkzeugen und Promotionen freizuschalten.',
      proTrade: 'Professionelle Marketingberichte',
      customerService: '24/7 Eins-zu-Eins-Kundendienst',
      competitivePromotionals: 'Wettbewerbsfähige Promotions',
      secure: 'Sichere 3D-Transaktionen',
      openLiveAcc: 'Live-Konto eröffnen',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Persönliche Details',
        userTitle: 'Titel',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        dob: 'Geburtsdatum',
        idType: 'Identifikations-Typ',
        referral: 'Wurden Sie von jemandem empfohlen? (optional)',
        middleName: 'Mittlerer Name',
        nationality: 'Nationalität',
        phoneNum: 'Telefonnummer',
        date: 'Tag',
        month: 'Monat',
        year: 'Jahr',
        placeOfBirth: 'Geburtsort',
        idNum: 'Identifikationsnummer',
        passportNum: 'Reisepass-Nummer',
        driverLicenseNum: 'Führerscheinnummer',
        titleDropdown: {
          mr: 'Herr',
          mrs: 'Frau',
          ms: 'Fräulein',
          miss: 'Fräulein',
          dr: 'Dr.',
          prof: 'Prof.',
        },
        idTypeDropdown: {
          passport: 'Reisepass',
          idCard: 'Nationale ID-Karte',
          driverLicense: 'Führerschein',
        },
      },
      page2: {
        pageTitle: 'HAUPTWOHNSITZADRESSE',
        mainResidency: 'Hauptwohnsitzland',
        streetNumber: 'Hausnummer',
        streetName: 'Straßenname',
        address: 'Adresse',
        provinceOrState: 'Provinz / Bundesland',
        cityOrSuburb: 'Stadt / Vorort',
        postcode: 'Postleitzahl',
        usQuestion:
          'Sind Sie aus steuerlichen Gründen US-Bürger oder haben Sie irgendwelche Verbindungen zu den USA (z.B. Bankkonto, etc.)?',
      },
    },
    moreAboutYou: {
      pageTitle1: 'BESCHÄFTIGUNG UND FINANZIELLE ANGABEN',
      pageTitle2: 'Trading-Kenntnisse und Erfahrungen',
      complianceExplain:
        'Die folgenden Fragen werden zur Einhaltung der AML/CTF-Anforderungen erhoben.',
    },
    accountConfig: {
      pageTitle: 'Konto-Konfiguration',
      chooseTradingPlatform: 'Trading-Plattform wählen',
      chooseAccType: 'Kontotyp wählen',
      chooseAccCurrency: 'Kontowährung wählen',
    },
    confirmYourId: {
      pageTitle: 'Bestätigen Sie Ihre Identität',
      description:
        'Als regulierter Forex-Broker sind wir verpflichtet, Ihre Identität zu verifizieren. Bitte laden Sie eines der folgenden Dokumente zur Verifizierung Ihres Identitäts- und Adressnachweises hoch. Alternativ können Sie Ihre Verifizierungsdokumente auch per E-Mail an {mailTo} senden',
      poidTitle: 'Identitätsnachweis',
      porTitle: 'Wohnsitznachweis',
      uploadDescription: 'Wählen Sie einen Dokumententyp und laden Sie ihn hoch',
      note: 'WICHTIG: Der Name auf dem Dokument MUSS deutlich Ihren vollen Namen zeigen.',
      pending: { upload: 'Ausstehender Upload', verification: 'Ausstehende Verifizierung' },
      poidTypes: {
        photoId: 'Lichtbildausweis',
        passport: 'Reisepass',
        drivingLicence: 'Führerschein',
      },
      porTypes:
        '<li>Nebenkostenabrechnung</li>\n      <li>Kontoauszug</li>\n      <li>Wohnsitzbescheinigung</li>\n      ',
    },
    finishPage: {
      title:
        '{individualUserName}, vielen Dank, dass Sie ein Live-Konto bei {platformName} beantragt haben!',
      contentDefault:
        'Wir haben Ihren Antrag erhalten und Ihr Live-Konto <b>{Konto}</b> eingerichtet. <br />\n      In Kürze erhalten Sie eine E-Mail an <b>{email}</b> mit Ihren Anmeldedaten und einem Link, um den Status Ihres Kontos einzusehen. <br />',
      contentWithoutId3WithAccount:
        'Ihr Live-Trading-Konto wird derzeit bearbeitet und Sie werden in Kürze eine E-Mail mit weiteren Informationen erhalten.<br />\n      Um die Bearbeitung Ihres Live-Kontos zu beschleunigen, können Sie jetzt auf Ihr Konto einzahlen und erhalten eine bevorzugte Eröffnung.<br />',
      contentWithoutId3WithoutAccount:
        'Ihr Live-Trading-Konto wird derzeit bearbeitet und Sie werden in Kürze eine E-Mail mit weiteren Informationen erhalten.<br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Verifizierung zur Kontoeröffnung',
      personalInformation: {
        narrative: 'Ihre persönlichen Daten werden benötigt, um Ihr Live-Konto zu eröffnen',
        firstName: 'Vorname',
        middleName: 'Zweiter Vorname',
        lastName: 'Nachname',
        gender: 'Geschlecht',
        genderList: { male: 'Männlich', female: 'Weiblich', other: 'Sonstiges' },
        dob: 'Geburtsdatum',
        day: 'Tag',
        month: 'Monat',
        year: 'Jahr',
        mobile: 'Mobiltelefonnummer',
        countryName: 'Land des Hauptwohnsitzes',
        nationality: 'Nationalität',
      },
      createLiveAccount: {
        narrative:
          'In wenigen Schritten können Sie ein Konto eröffnen und mit Einzahlungen beginnen. Weitere Informationen zu unseren Kontotypen finden Sie <a href="{link}" target="_blank" alt>hier</a>.',
        choose: 'Wählen Sie eine {dynamic}',
        tradingPlatform: 'Handelsplattform',
        accountType: 'Kontotyp',
        accountCurrency: 'Kontowährung',
        islamicTooltip:
          '{platform} kann von Ihnen die Vorlage von Dokumenten verlangen, die belegen, dass Sie dem islamischen Glauben angehören. {platform} behält sich das Recht vor, die Konfiguration Ihres Kontos zu ändern, wenn die Verifizierung fehlschlägt.',
        successInfo: {
          title: 'Handelskonto erstellt',
          content:
            'Herzlichen Glückwunsch! Sie haben die Kontoeröffnung erfolgreich abgeschlossen. <br /> Um mit dem Trading zu beginnen, zahlen Sie jetzt Geld ein und verifizieren Sie Ihre ID.',
        },
        successInfo2: {
          title: 'Handelskonto erstellt',
          content:
            'Geben Sie die Daten Ihres finanziellen Profils ein, um mit dem nächsten Schritt der Verifizierung Ihres Kontos zu beginnen.',
          start: 'Start',
        },
        generalInfo: {
          title: 'Ihre eingereichten Unterlagen werden überprüft.',
          content:
            'Vielen Dank für die Eröffnung eines Kontos bei {platform}. Sie können mit der Einzahlung auf Ihr Konto beginnen, sobald Ihre eingereichten Unterlagen genehmigt wurden.',
        },
        rejectedInfo: {
          title: 'Abgelehnt',
          content:
            'Wir bedauern, Ihnen mitteilen zu müssen, dass Ihr Antrag auf Eröffnung eines Kontos bei {platform} abgelehnt wurde.',
        },
      },
    },
    identityVerification: {
      title: 'Verifizierung der Identität',
      subTitle: 'Informationen zur Identität',
      narrative: 'Füllen Sie die Details aus',
      firstName: 'Vorname',
      middleName: 'Zweiter Vorname',
      lastName: 'Nachname',
      nationality: 'Nationalität',
      idType: 'ID-Typ',
      idSerialNumber: 'ID-Seriennummer',
      passportNum: 'Ausweisnummer',
      driverLicenseNum: 'Führerscheinnummer',
      upload: {
        title: 'Dokument hochladen',
        desc: 'Achten Sie darauf, dass das Dokument Ihr Foto, Ihren vollständigen Namen, Ihr Geburtsdatum und das Ausstellungsdatum enthält.',
        guideline: 'Richtlinien für das Hochladen',
        positive: 'Hochladen erfolgreich',
        signaturePage: 'Unterschriftsseite hochladen',
      },
      idTypeDropdown: {
        passport: 'Reisepass',
        idCard: 'Nationale ID-Karte',
        driverLicense: 'Führerschein',
        other: 'Sonstiges',
      },
      additionalInfo: {
        title: 'Zusätzliche Informationen erforderlich',
        content: 'Verifizieren Sie Ihre Identität, um mit dem Trading zu beginnen',
      },
      successInfo: {
        title: 'ID-Verifizierung abgeschlossen',
        content:
          'Herzlichen Glückwunsch! Ihre ID wurde verifiziert. Sie können jetzt auf MT4/MT5 oder in der {platform} App mit dem Trading beginnen, wenn Sie bereits eine Einzahlung vorgenommen haben.',
      },
      generalInfo: {
        title: 'Ihre eingereichten Unterlagen werden überprüft.',
        content:
          'Vielen Dank für die Eröffnung eines Kontos bei {platform}.  Sie können mit dem Trading beginnen, sobald Ihre Identitätsdaten verifiziert sind.',
      },
      rejectedInfo: {
        title: 'Abgelehnt',
        content:
          'Wir bedauern, Ihnen mitteilen zu müssen, dass Ihr Antrag auf Eröffnung eines Kontos bei {platform} abgelehnt wurde.',
      },
      identifyingRequiredInfo: {
        title: 'Zusätzliche Informationen erforderlich',
        content:
          'Einige Ihrer Identifizierungsdokumente fehlen oder sind falsch. Bitte laden Sie sie erneut hoch, um fortzufahren.',
      },
      streetNumber: 'Hausnummer',
      streetName: 'Straßenname',
    },
    poaVerification: {
      proofOfAddressVer: 'Verifizierung des Adressnachweises',
      subTitle: 'Adressinformationen',
      narrative: 'Füllen Sie die Details aus',
      dueToAML:
        'Aufgrund von AML-Anforderungen muss Ihre Adresse verifiziert werden, bevor Sie eine Auszahlung vornehmen können.',
      nationality: 'Nationalität',
      country: 'Land des Hauptwohnsitzes',
      address: 'Adresse',
      postalCode: 'Postleitzahl',
      citySuburb: 'Stadt/Bezirk',
      city: 'Stadt',
      provinceState: 'Region/Bundesland',
      upload: {
        title: 'Zertifikat für den Adressnachweis',
        inst1: 'Nebenkostenrechnung (Strom, Wasser, Gas)',
        inst2: 'Bank- oder Kreditkartenauszüge',
        desc: 'Die Dokumente zum Adressnachweis für Auszahlungen müssen innerhalb der letzten {months} Monate ausgestellt worden sein',
        guideline: 'Richtlinien für das Hochladen',
        uploadFilesPhoto: 'Dateien/Fotos hochladen',
      },
      additionalInfo: {
        title: 'Zusätzliche Informationen erforderlich',
        content:
          'Der Adressnachweis muss verifiziert werden, bevor Sie eine Auszahlung vornehmen können.',
      },
      successInfo: {
        title: 'Adressnachweis verifiziert',
        content:
          'Herzlichen Glückwunsch! Ihr Adressnachweis wurde verifiziert. Sie können jetzt Auszahlungen vornehmen.',
      },
      generalInfo: {
        title: 'Ihre eingereichten Unterlagen werden überprüft.',
        content:
          'Vielen Dank für den Handel mit {platform}. Sobald Ihr Antrag genehmigt wurde, können Sie mit dem Auszahlungsprozess beginnen.',
      },
      identifyingRequiredInfo: {
        title: 'Zusätzliche Informationen erforderlich',
        content:
          'Einige Ihrer Identifizierungsdokumente fehlen oder sind falsch. Bitte laden Sie sie erneut hoch, um fortzufahren.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'Ihre Finanzinformationen',
        subTitle: 'Angaben zu Beschäftigung und Finanzen',
        subTitle2: 'Wissen und Erfahrung im Trading',
        employmentStatus: 'Beschäftigungsstatus',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} pro Woche',
        estimatedAnnualIncome: 'Geschätztes jährliches Einkommen',
        estimatedSavingsAndInvestments: 'Geschätzte Ersparnisse und Investitionen',
        estimatedIntendedDeposit: 'Geschätzte beabsichtigte Einzahlung',
        sourceOfFunds: 'Quelle der Geldmittel',
        numberOfTrades: 'Anzahl der Trades',
        tradingAmount: 'Tradingbetrag',
      },
      iDProof: {
        ver: 'Identitätsnachweis hochladen',
        subTitle: 'Informationen zur Identität',
        narrative: 'Füllen Sie die Details aus',
      },
      addressProof: {
        ver: 'Adressnachweis hochladen (POA)',
        subTitle: 'Adressinformationen',
        narrative: 'Füllen Sie die Details aus',
        upload: {
          title: 'Akzeptierte Dokumente für den Adressnachweis',
          inst1: 'Nebenkostenrechnung (Strom, Wasser, Gas)',
          inst2: 'Bank- oder Kreditkartenauszüge',
          desc: 'Die Dokumente zum Adressnachweis für Auszahlungen müssen innerhalb der letzten {months} Monate ausgestellt worden sein',
          guideline: 'Richtlinien für das Hochladen',
          uploadFilesPhoto: 'Dateien/Fotos hochladen',
        },
      },
      successInfo: {
        title: 'Bankauthentifizierung abgeschlossen',
        content: 'Herzlichen Glückwunsch! Sie haben Ihre Bankdaten erfolgreich verifiziert.',
      },
      generalInfo: {
        title: 'Ihre eingereichten Unterlagen werden überprüft.',
        content:
          'Ihre Dokumente für die Banküberweisung werden noch überprüft. Sollten Sie jetzt mit dem Handel beginnen möchten, können Sie dennoch über einen anderen Kanal eine Einzahlung vornehmen.',
      },
      rejectedInfo: {
        title: 'Abgelehnt',
        content: 'Ihre Anfrage, eine Einzahlung per Banküberweisung zu tätigen, wurde abgelehnt.',
      },
      identifyingRequiredInfo: {
        title: 'Zusätzliche Informationen erforderlich',
        content:
          'Einige Ihrer Identifizierungsdokumente fehlen oder sind falsch. Bitte laden Sie sie erneut hoch, um fortzufahren.',
      },
    },
  },
  ibProfile: {
    title: 'IB-PROFIL',
    subTitle: 'Shared IB',
    sharedIbList: {
      sharedIbAccount: 'Geteiltes IB-Konto',
      maxSharedIbAccount: 'Maximal 10 geteilte IB-Konten',
      add: 'Hinzufügen',
      accountHistory: 'Historie des geteilten IB-Kontos',
      setting: 'Einstellungen',
      delete: 'Löschen',
      enable: 'Aktivieren',
      disable: 'Deaktivieren',
      enabled: 'Aktiviert',
      disabled: 'Deaktiviert',
      sharedAccountEnabled: 'Geteiltes IB-Konto wurde aktiviert.',
      sharedAccountDisabled: 'Geteiltes IB-Konto wurde deaktiviert.',
      sharedAccountCopied: 'Geteiltes IB-Konto kopiert',
      deleteTips:
        'Sie können das geteilte IB-Konto nach der Löschung nicht mehr anmelden. Möchten Sie es löschen?',
      sharedAccountDeleted: 'Geteiltes IB-Konto wurde gelöscht.',
      copyTips: 'Geteiltes IB-Konto kopiert',
      remark: 'Bemerkung',
      cancel: 'Abbrechen',
      accountData: 'Kontodaten',
      IBData: 'IB-Berichtsdaten',
      fundOperations: 'Geldberechtigung',
      resetPassword: 'Passwort zurücksetzen',
      accountHistoryBtn: 'Geteilte Kontohistorie',
    },
    updateSharedIb: {
      addNewSharedIb: 'Neues geteiltes IB-Konto hinzufügen',
      editSharedIb: 'Geteiltes IB-Konto bearbeiten',
      maxSharedIbAccountError:
        '10 Konten existieren bereits, es können keine weiteren hinzugefügt werden.',
      email: 'E-Mail-Adresse',
      emailValidate: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
      emailError:
        'E-Mail existiert bereits, bitte versuchen Sie es mit einer anderen Email Adresse',
      password: 'Passwort',
      passwordValidate: 'Bitte geben Sie das Passwort ein',
      passwordValidateNum: '8-16 Zeichen',
      passwordValidateTips:
        'Mischung aus Groß- und Kleinbuchstaben (A-Z, a-z), Zahlen (0-9) und Sonderzeichen (z. B.: !@#S%^&)',
      passwordValidateWarning:
        '8-16 Zeichen,Mischung aus Groß- und Kleinbuchstaben (A-Z, a-z), Zahlen (0-9) und Sonderzeichen (z. B.: !@#S%^&)',
      sharedIbAccountPermission: 'Berechtigung des geteilten IB-Kontos',
      remark: 'Bemerkung',
      remarkTips: 'Sie können den Zweck des Kontos oder den Kontoinhaber speichern',
      status: 'Status',
      cancel: 'Abbrechen',
      confirm: 'Bestätigen',
      disabled: 'Deaktiviert',
      enabled: 'Aktiviert',
      resetPassword: 'Passwort zurücksetzen',
      emailPlaceholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
      addSuccess: 'Geteiltes Konto erstellt',
      permissionIdsTips:
        'Sie können die folgenden Berechtigungen für gemeinsame Konten erlauben oder nicht:',
      permissionIdsFirst:
        '<b>Kontodaten</b>: Anzeigen von Kontodatenseiten (IB-Konten, Kürzlich geöffnete Konten). Die Daten werden ausgeblendet, wenn die Berechtigung nicht erteilt ist.',
      permissionIdsSecond:
        '<b>IB-Berichtsdaten</b>: Anzeigen von IB-Datenseiten (Dashboard, mehrstufiges IB – Konto). Die Daten werden ausgeblendet, wenn die Berechtigung nicht erteilt ist.',
      permissionIdsThird:
        '<b>Geldberechtigung</b>: IB-Fonds betreiben (Rabatt anwenden, Rabatt übertragen, Rabatt abheben), Rückvergütung anzeigen (Rückvergütungsbericht, Saldo und Fondsdatensätze). Operationen können nicht durchgeführt werden, wenn die Berechtigung nicht erteilt ist.',
      newPassword: 'Bitte geben Sie das neue Passwort ein',
      newPassowrdReset: 'Bitte geben Sie das neue Passwort ein',
      passwordSame: 'Das neue Passwort darf nicht dasselbe sein wie das alte.',
      resetPasswordSuccess: 'Passwortrücksetzung erfolgreich',
      enterNewPassword: 'Bitte geben Sie das neue Passwort ein',
      changePasswordTips: 'Das alte Passwort wird ungültig und auf das neue Passwort zurückgesetzt',
      fundsChangeTips:
        'Nach Erteilung der Fondsgenehmigung kann das geteilte IB-Konto das Guthaben Ihres Haupt-IB-Kontos übertragen und abheben. Möchten Sie dies erlauben?',
      editSuccess: 'Erfolgreich geändert',
    },
    sharedIbHistory: {
      allHistory: 'Die gesamte Historie wurde angezeigt',
      lastUpdate: 'Zuletzt aktualisiert',
      refreshTips: 'Historie aktualisiert',
      refresh: 'Aktualisieren',
      chooseAccount: 'Konto auswählen',
      device: 'Gerät',
      login: 'Einloggen',
      commission: 'Rabatt anwenden',
      withdraw: 'Rabatt auszahlen',
      transfer: 'Rabatt übertragen',
      fromTo: 'Von {fromAccount} bis {toAccount}, Betrag {total}',
      viewMore: 'Mehr anzeigen',
    },
    npPermissionTips: 'Keine Berechtigung für dieses Konto',
  },
  agreement: {
    title: 'Einführung Maklervertrag Bestätigen',
    rebateAccount: 'Rabattkonto',
    description:
      'Indem Sie auf "Zustimmen" klicken, bestätigen Sie, dass Sie alle Informationen, Servicebedingungen und Konditionen in der Vereinbarung gelesen und verstanden haben und damit einverstanden sind.',
    agree: 'Einverstanden',
    signed: 'Rabattkonto {accountID}. Unterzeichnet am {signedTime}',
    IBAgreement: 'IB-Vereinbarung',
    confirmAgreement:
      'Bitte kontaktieren Sie den IB-Manager, um die IB-Vereinbarung zu bestätigen.',
  },
};
