export default {
  en: 'English',
  zh: '中文',
  fr: 'Français',
  home: {
    NewPortal: 'Nouveau Portail',
    SecureIBPortal: 'Portail IB sécurisé',
    DASHBOARD: 'TABLEAU DE BORD',
    MARKETING_TOOLS: 'OUTILS MARKETING',
    IB_REPORT: 'RAPPORT DE IB',
    REBATE_REPORT: 'RAPPORT DE BONUS',
    IB_ACCOUNTS: 'COMPTES IB',
    PAYMENT_HISTORY: 'HISTORIQUE DES TRANSACTIONS',
    IB_FAQ: 'Fréquemment Posées DE IB',
    RebateAccountNumber: 'Numéro de bonus',
    totalBalance: 'solde totale',
    totalCommission: 'bonus totale',
    APPLY_FOR_A_REBATE: 'DEMANDER',
    availableBalance: 'solde disponible',
    WITHDRAW_REBATE: 'Se désister',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFÉRER',
    MonthtoDate: 'Le mois courant',
    UPDATE: 'MISE À JOUR',
    REBATE: 'Bonus',
    TOTAL_VOLUME: 'Volume TOTALE',
    NET_FUNDING: 'PAIEMENT NET',
    DEPOSITS: 'DÉPÔTS',
    WITHDRAW: 'RETRAIT',
    OPEND_ACCOUNT: 'COMPTE OUVERT',
    PERFORMANCE: 'PERFORMANCE',
    OpendAccount: 'Compte ouvert',
    select_ib: 'VEUILLEZ SÉLECTIONNER LE COMPTE IB',
    MOST_TRADED_INSTRUMENTS: 'LES INSTRUMENTS LES PLUS ÉCHANGÉS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'LES COMPTES LES PLUS PERFORMANTS DE CE MOIS',
    RECENTLY_OPENED_ACCOUNTS: 'COMPTES RÉCEMMENT OUVERTS',
    ACCOUNT_MANAGER: 'SERVICE CLIENT DÉDIÉ',
    Account: 'Compte',
    Name: 'Nom',
    Volume: 'Volume',
    Rebate: 'Bonus',
    Balance: 'Équilibre',
    ContactClient: 'Contacter',
    YouCannotAskForCommission: 'Vous ne pouvez pas demander de bonus',
    applicationFailedPleaseTryAgain: "L'application a échoué, veuillez réessayer plus tard",
    applyRebateSuccessTip:
      "\n    Félicitations, nous avons reçu votre demande de remise. | {montant} sera transféré sur le compte de remise {compte} dans un délai d'un jour ouvrable. | Vous pouvez retirer ou transférer des fonds une fois votre remise traitée.",
    HOVER:
      'Les remises de la veille sont calculées et traitées quotidiennement entre 9:00 et 11:00 AEST.',
    blacklist:
      "Il n'est pas possible d'appliquer une remise à ce compte pour le moment. Contactez votre gestionnaire de compte.",
    ACCOUNTS_PROFIT:
      'Profit/perte total historique de tous les comptes de trading avec ce compte de remise. Le chargement peut prendre un moment.',
    PROFIT: 'ACCOUNTS  PROFIT',
    reload: 'Recharger',
  },
  liveChat: { header: 'Chat en direct' },
  contactUs: {
    header: 'NOUS CONTACTER',
    followUs: 'Suivez nous',
    tip: "L'équipe multilingue mondiale de {platform} est là pour vous aider et vous offrir une expérience de trading inégalée.",
    chatNow: 'Discutons maintenant',
    globalOffice: 'Bureau mondial',
  },
  ibAccounts: {
    header: 'COMPTES IB',
    ibAccount: 'COMPTE IB',
    subIbs: 'Compte de IB secondaire',
    tradinglist: "Liste d'échange",
    openlist: 'Ouvrir la liste',
    closedlist: 'Liste fermée',
    tradinglistColumn: {
      ticketid: 'ID de billets',
      opentime: "Heure d'ouverture",
      type: 'Taper',
      volume: 'Le volume',
      item: 'Article',
      openprice: 'Prix Ouvert',
      closeprice: 'Fermer le prix',
      closetime: 'La période de fermeture',
      commission: 'Commission',
      profit: 'Profit',
      buy: 'Acheter',
      sell: 'Vendre',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Sélectionner une carte de crédit',
      selectBA: 'Sélectionner un compte en banque',
      anotherCC: 'Saisissez manuellement la carte de crédit',
      anotherBA: 'Ajouter un compte en banque',
      remember: 'Se souvenir de mon compte',
      verificationCode:
        'Le code de vérification a été envoyé à {email}. Contactez-nous l’adresse à {supportEmail} si vous avez besoin d’une assistance supplémentaire',
      fetchTradeAccFailed: 'Échec de récupération du compte de trading',
      failed: 'Échec du retrait. Veuillez réessayer plus tard',
      tokenExpired: 'Le jeton a expiré',
    },
    alert:
      'En raison des lois AML, la méthode que vous utilisez pour retirer doit être la même que la méthode utilisée pour votre dépôt. Si le montant du retrait est inférieur à 100 unités de la devise de base de votre compte, des frais de traitement vous seront facturés 20 unités par la banque.',
    NetellerChargeAlert:
      'Neteller facture des frais de transaction de {percentageFee}% pour chaque retrait.',
    FasaPayChargeAlert: 'FasaPay facture des frais de transaction de 0,5% pour chaque retrait.',
    SkrillChargeAlert:
      'Skrill facture des frais de transaction de {percentageFee}% pour chaque retrait.',
    cryptoAlert:
      "Veuillez noter que nous ne sommes pas en mesure d'effectuer des dépôts ou des retraits via BUSDT. Assurez-vous que l'adresse et la crypto-monnaie correspondent à la chaîne et à la devise que nous acceptons, sinon vous pourriez perdre les fonds.",
    REBATE_WITHDRAW_REQUEST: 'DEMANDE DE RETRAIT DE BONUS',
    PleaseSelectDesiredWithdrawMethod: 'Veuillez sélectionner la méthode de retrait souhaitée',
    BankName: 'Nom de banque',
    BankAccountsName: 'Nom des comptes bancaires',
    FasaPayAccountName: 'Nom du compte FasaPay',
    AustralianBankName: 'Nom de la banque australienne',
    BankAccountNumber: 'Numéro de compte bank',
    FasaPayAccountNumber: 'Numéro de compte FasaPay',
    BankAccountBeneficiary: 'Bénéficiaire du compte bancaire',
    BSB: 'BSB',
    skrillEmail: 'Email Skrill',
    NetellerEmail: 'Email Neteller',
    PerfectMoneyEmail: 'Email Perfect Money',
    SticpayEmail: 'Email SticPay',
    BitwalletEmail: 'Email Bitwallet',
    BitcoinEmail: 'Adresse du portefeuille Bitcoin',
    USDTEmail: 'Adresse du portefeuille Tether',
    BankBranch: 'Agence Bancaire',
    Province: 'Province',
    City: 'Ville',
    BankAddress: 'Adresse de la banque',
    bankCity: 'Ville de la banque',
    bankProvince: 'Province de la banque',
    AccountNumberIBAN: 'Numéro de compte/IBAN',
    BankBeneficiaryName: 'Nom du bénéficiaire de la banque',
    BankAccountBeneficiaryName: 'Nom du bénéficiaire du compte bancaire',
    AccountHoldersAddress: 'Adresse du titulaire du compte',
    Swift: 'Swift',
    SwiftAud: 'Swift(Comptes non AUD)',
    ABA_SortCodeABA: 'ABA/Code de Sort',
    ImportantNotes: 'Notes IMPORTANTES',
    YourPaymentGreaterThanAvailableBalance: 'Votre paiement est supérieur au solde disponible',
    CannotUseMoreThanTwoDecimalValues:
      'Vous ne pouvez pas utiliser des nombres et des nombres négatifs avec plus de deux décimales',
    YourPaymentIsBeingProcessed: 'Votre demande de retrait a été soumise',
    Itcannotbe0: 'Ça ne peut pas être 0',
    amtLarger:
      'Le montant ne peut pas être nul et doit être supérieur ou égal à {minLimit} {currency}',
    blackList:
      "Malheureusement, vous n'êtes actuellement pas en mesure de soumettre une demande de retrait. Contactez-nous à {supportEmail} pour plus d'informations",
    status: {
      withdrawalSubmitted: 'Soumis',
      withdrawalCanceled: 'Annulé',
      withdrawalAuditing: 'En cours',
      withdrawalRejected: 'Rejeté',
      withdrawalAccepted: 'En cours',
      withdrawalPayFailed: 'Échec',
      withdrawalSuccess: 'Traité',
      withdrawalFailed: 'Échec',
      withdrawalPending: 'Pending',
      withdrawalPartialFailed: 'Échec',
      transferSubmitted: 'En cours',
      transferSuccess: 'Traité',
      transferRejected: 'Rejeté',
      transferFailed: 'Échec',
      transferProcessing: 'En cours',
      withdrawalReversedFailed: 'Échec inversé',
      withdrawalPartialSuccess: 'Succès Partiel',
    },
    formErrorMsg: {
      bankName: 'Le nom de la banque est requis',
      bsb: 'BSB est requis',
      beneName: 'Le nom du bénéficiaire de la banque est requis',
      accNum: 'Le numéro de compte bancaire est requis',
      swift: 'Swift est requis',
      bankAddress: "L'adresse bancaire est obligatoire",
      holderAddress: "L'adresse du titulaire est obligatoire",
      amt: 'Un montant est requis',
      firstSixDigits: 'Veuillez entrer les 6 premiers chiffres',
      lastFourDigits: 'Veuillez entrer les 4 premiers chiffres',
      branchName: 'Le nom de la succursale est obligatoire',
      email: "L'email est requis",
      accName: 'Le nom du compte est obligatoire',
      province: 'La province de la banque est obligatoire',
      city: 'Banque Ville est requis',
      emailFormat: 'Veuillez entrer une adresse e-mail correcte',
      amt0: 'Le montant ne peut pas être égal à 0',
      bitcoin: "L'adresse du portefeuille Bitcoin est requise",
      addressLength:
        "L'adresse du portefeuille en Bitcoin doit comporter entre 26 et 42 caractères",
      usdt: "L'adresse du portefeuille USDT est requise",
      beneficiaryNameReq: 'Le nom du bénéficiaire bancaire est requis',
    },
    transfer: {
      info1:
        '{platform} annule les frais bancaires lors du premier retrait du mois. Les retraits supplémentaires effectués au cours du même mois donneront lieu à des frais bancaires de 20 unités de votre devise de retrait (pour USC/HKD/JPY, les frais seront équivalents à 20 USD).',
      info2:
        "Veillez à ce que le nom du bénéficiaire soit indiqué exactement comme sur le passeport (abréviation interdite, indiquez le nom complet en fonction de l'initiale figurant sur le relevé d'identité).",
      info3:
        "Le compte bancaire doit de préférence être au format IBAN; si vous n'êtes pas originaire de l'Union européenne, veuillez vous référer au relevé de compte bancaire.",
      label: {
        upload:
          "Veuillez télécharger une capture d'écran, une photo ou une copie numérisée de votre relevé bancaire (couvrant les 3 derniers mois)",
        upload2:
          'Veuillez vous assurer que la date du relevé ou la date de la dernière transaction est comprise entre le mois en cours et les trois derniers mois. Si cela dépasse cette période, veuillez réimporter le relevé.',
      },
      formValidation: { upload: 'Veuillez télécharger un relevé bancaire' },
      minAmount: 'Le montant de retrait minimum est 100 {currency}',
    },
    transitNumber: 'Numéro de transit',
    institutionNumber: "Numéro de l'établissement",
    beneficiaryName: 'Nom du bénéficiaire',
  },
  security: {
    contactSupport:
      "Impossible d'accéder à la méthode d'authentification? Veuillez contacter {contact_email} ou Livechat",
    securityAuthenticator: 'Authentification de sécurité',
    verificationRequired: 'Veuillez entrer le code de vérification',
    resend: 'Renvoyer',
    authenticatorTooltip:
      "Le code de vérification de l'application Authenticator se rafraîchit toutes les 30 secondes",
    verifyPreEmailNote: 'Le code sera envoyé à {email}',
    verifyPostEmailNote: 'Code envoyé à {email}',
    enableNow: 'Activer maintenant',
    verificationTitle: 'Vérification requise',
    verificationDesc:
      "Veuillez activer l'application d'authentification de sécurité avant de procéder.",
  },
  rebate: { status: { processed: 'Traité', processing: 'En traitement', rejected: 'Rejeté' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'DEMANDE DE TRANSFERT DE BONUS',
    transferAmountIsGreaterThanAvailableBalance:
      'Le montant de votre transfert est supérieur au solde disponible',
    yourRebateTransferHasBeenProcessed: 'votre transfert de remise a été soumis',
    PleaseEnterTransferInformation: 'Veuillez saisir des informations de transfert précises',
    tradingAccount: 'Un compte de commerce',
    rebateAccount: 'Compte de bonus',
    account: 'Compte',
    blackList:
      "Ce compte ne comporte pas d'autorisation pour effectuer un transfert entre comptes. Contactez-nous à {supportEmail}",
  },
  common: {
    liveChat: { desc: "Besoin d'aide ? Chat en direct" },
    lang: {
      english: 'Anglais',
      chinese: 'Chinois',
      french: 'Français',
      thai: 'Thaïlandais',
      german: 'Allemand',
      spanish: 'Espagnol',
      malay: 'Malais',
      viet: 'Vietnamien',
      indo: 'Indonésien',
      arabic: 'Arabe',
    },
    button: {
      iKnow: 'Je comprends',
      confirmCamelCase: 'Confirmer',
      sendCode: 'Envoyer le code',
      submit: 'Soumettre',
      resendCode: 'Renvoyer le code',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: 'Les retraits ne sont temporairement pas pris en charge',
      limitPassword:
        'En raison de la modification de votre mot de passe de connexion, les retraits ne sont temporairement pas pris en charge pendant {day} jours pour garantir la sécurité de votre compte. Cette restriction devrait être levée dans {hour} heures.',
      limitUserLogin:
        'En raison de la modification de votre adresse e-mail/numéro de téléphone de connexion, les retraits ne sont temporairement pas pris en charge pendant {day} jours. Cette restriction devrait être levée dans {hour} heures.',
      limitSecurityAuthenticator:
        'En raison de la modification de votre application Authenticator de sécurité, les retraits ne sont temporairement pas pris en charge pendant {day} jours pour garantir la sécurité de votre compte. Cette restriction devrait être levée dans {hour} heures.',
      banktransfer: 'Virement bancaire international',
      aus: 'Virement bancaire - Australie',
      int: 'Virement bancaire - International',
      chn: 'Virement bancaire - Chine',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'Virement UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Carte de crédit',
      bpay: 'Bpay',
      creditdebitcard: 'Carte de crédit/débit',
      bankwiretransferinternational: 'Virement bancaire (International)',
      banktransferaustralia: 'Virement bancaire (Australie)',
      banktransferinternational: 'Virement bancaire international',
      banktransferchina: 'Virement bancaire (International)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Virement de courtier à courtier',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banque virtuelle (Thaïlande)',
      internetbankingnigeria: 'Banque virtuelle (Nigeria)',
      internetbankingvietnam: 'Banque virtuelle (Vietnam)',
      internetbankingmalaysia: 'Banque virtuelle (Malaisie)',
      internetbankingindonesia: 'Banque virtuelle (Indonésie)',
      internetbankingkorea: 'Banque virtuelle (Corée du Sud)',
      internetbankingindia: 'Banque virtuelle (Inde)',
      internetbankingphilippine: 'Banque virtuelle (Philippines)',
      internetbankingsouthafrica: 'Banque virtuelle (Afrique du Sud)',
      internetbankinguganda: 'Banque virtuelle (Ouganda)',
      internetbankingrwanda: 'Banque virtuelle (Rwanda)',
      internetbankingzambia: 'Banque virtuelle (Zambie)',
      internetbankingcongo: 'Banque virtuelle (Congolais)',
      internetbankingcameroon: 'Banque virtuelle (Cameroun)',
      internetbankingburundi: 'Banque virtuelle (Burundi)',
      internetbankingkenya: 'Banque virtuelle (Kenya)',
      internetbankingghana: 'Banque virtuelle (Ghana)',
      internetbankingtanzania: 'Banque virtuelle (Tanzanie)',
      internetbankingcanada: 'Banque virtuelle (Canada)',
      internetbankingbrazil: 'Banque virtuelle (Brésil)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Virement bancaire Vietnam',
      nigeriabanktransfer: 'Virement bancaire Nigeria',
      polipayment: 'Paiement POLi',
      bankwiretransferaustralia: 'Virement bancaire (Australie)',
      thailandinstantbankwiretransfer: 'Virement bancaire instantané en Thaïlande',
      malaysiainstantbankwiretransfer: 'Virement bancaire instantané en Malaisie',
      banktransferbpaypolipay: 'Virement/BPay/PoliPay',
      cryptocurrencybitcoin: 'Crypto-monnaie-Bitcoin',
      cryptocurrencyusdt: 'Crypto-monnaie-USDT',
      cryptocurrencyethcps: 'Crypto-monnaie-ETH',
      cryptocurrencyusdccps: 'Crypto-monnaie-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: 'Virement bancaire Nigeria',
      vietnaminstantbankwiretransfer: 'Virement bancaire express vietnamien',
      indonesiainstantbankwiretransfer: 'Virement bancaire express Indonesia',
      philippinesinstantbankwiretransfer: 'Virement bancaire express Philippines',
      southafricainstantbankwiretransfer: 'Virement bancaire express Afrique du Sud',
      paygindiainstantbanktransferate: 'Virement bancaire express Inde',
      indiabanktransfer: 'Virement bancaire Inde',
      southkoreabanktransfer: 'Virement bancaire instantané en Corée du Sud',
      ugandabanktransfer: 'Virement bancaire local en Ouganda',
      rwandabanktransfer: 'Virement bancaire local au Rwanda',
      zambiabanktransfer: 'Virement bancaire local en Zambie',
      congobanktransfer: 'Virement bancaire local au Congo',
      cameroonbanktransfer: 'Virement bancaire local au Cameroun',
      burundibanktransfer: 'Virement bancaire local au Burundi',
      kenyabanktransfer: 'Virement bancaire local au Kenya',
      ghanabanktransfer: 'Virement bancaire local au Ghana',
      tanzaniabanktransfer: 'Virement bancaire local en Tanzanie',
      brazilbanktransfer: ' Virement bancaire au Brésil',
      banktransferbpaypolipayaustralia: 'Virement bancaire/BPay/POLiPay (Australie)',
      banktransferbpaypolipayinternational: 'Virement bancaire/BPay/POLiPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Virement bancaire local canadien',
      cryptobitcoin: 'Crypto-monnaie-Bitcoin',
      cryptotetheromni: 'Crypto-monnaie-Tether(OMNI)',
      cryptotethererc20: 'Crypto-monnaie-Tether(ERC20)',
      cryptotethertrc20: 'Crypto-monnaie-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: 'Transfert aux comptes',
      E_WALLET: 'PORTEFEUILLE ÉLECTRONIQUE',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      LOCAL_TRANSFER: 'TRANSFERT LOCAL',
      japanbanktransfer: 'Virement bancaire au Japon',
      internetbankingmexico: 'Banque virtuelle (Mexique)',
      internetbankinguae: 'Service bancaire en ligne (Émirats arabes unis)',
      ewallet: 'Porte-monnaie électronique',
      internetbankingtaiwan: 'Banque en ligne (Taïwan)',
      internetbankingeu: 'Banque en ligne (UE)',
    },
    country: { international: 'International', australia: 'Australie' },
    field: {
      country_region: 'Pays/Région',
      address: 'Adresse',
      phone: 'Numéro de téléphone',
      nat: 'Nationalité',
      country: 'Pays de résidence',
      save: 'Investissement',
      annIncome: 'Revenu annuel',
      emailAdd: 'Adresse e-mail',
      yes: 'Oui',
      no: 'Non',
      enterVerificationCode: 'Saisissez le code de vérification',
      ebuyAccName: 'Nom du compte EBuy',
      ebuyEmailAddress: 'Adresse e-mail EBuy',
      skrillAccName: 'Nom du compte Skrill',
      skrillEmailAddress: 'Adresse e-mail Skrill',
      netellerAccName: 'Nom du compte Neteller',
      netellerEmailAddress: 'Adresse e-mail Neteller',
      imptNotes: 'Notes importantes',
      paymentAccName: '{name} Nom du compte',
      paymentAccNum: '{name} Numéro du compte',
      documentType: 'Type de document',
      accDigit: 'Chiffre du compte',
      docID: 'ID du document',
      bankBranchCode: 'Code de la succursale',
      accType: 'Type de compte',
      accName: 'Nom du compte',
      accNum: 'Numéro du compte',
      bankName: 'Nom de la banque',
      swiftCode: 'Code SWIFT',
      bankAddress: 'Adresse de la banque',
      bankHolderAddress: 'Adresse du titulaire du compte',
      bankBeneficiaryName: 'Nom du bénéficiaire à la banque',
      bankAccNum: 'Numéro du compte bancaire',
    },
    keys: {
      SUBMIT: 'SOUMETTRE',
      FROM: 'DE',
      UPDATE: 'MISE À JOUR',
      TO: 'À',
      DATE: "DATE D'OUVERTURE",
      pleaseSelect: 'Veuillez sélectionner',
      amount: 'Montant',
      rebateAccount: 'Compte de bonus',
      comingSoon: 'bientôt disponible',
      tradingHistory: 'HISTOIRE DU COMMERCE',
      noData: 'Pas de données',
      NAME: 'NOM',
      EMAIL: 'EMAIL',
      ACCTYPE: 'TYPE DE COMPTE',
      PLATFORM: 'PLATE-FORME',
      BASECURRENCY: 'DEVISE',
      DATERANGE: 'PLAGE DE DATES',
      STATUS: 'STATUT',
      DEST: 'DESTINATION',
      ACCNUM: 'NUMÉRO DE COMPTE',
      BALANCE: 'ÉQUILIBRE',
      PROFITLOST: 'PROFIT',
      MARGINLVL: 'NIVEAU DE MARGE',
      ACCEQUITY: 'CAPITAUX PROPRES',
      CREDIT: 'CRÉDIT',
      NONE: 'Aucun',
      ALL: 'Tout',
      actualAmonut: 'montant actuel',
      currency: 'DEVISE',
      method: 'MÉTHODE',
      REGISTRATION: "DATE D'INSCRIPTION",
      FTD: 'DATE DE DEPOT',
      CURRENTCAMPAIGN: 'CAMPAGNE ACTUELLE',
      blacklisted: 'Liste noire',
      FIRST_TRANS_IN: 'PREMIÈRE TRANS EN DATE',
      createTime: 'Heure de soumission',
      lastUpdateTime: 'Temps traité',
    },
    dateRange: {
      today: "Aujourd'hui",
      yesterday: 'Hier',
      lastSevenDays: 'Les 7 derniers jours',
      lastThirtyDays: 'Les 30 derniers jours',
      weekToDate: 'À ce jour',
      monthToDate: 'Le mois courant',
      quarterToDate: 'Trimestre à ce jour',
      previousWeek: 'Semaine précédente',
      previousMonth: 'Le mois précédent',
      previousQuarter: 'Trimestre précédent',
    },
    products: {
      forex: 'FOREX',
      commodities: 'MATIÈRES PREMIÈRES',
      indices: 'INDICES',
      crypto: 'CRYPTO',
    },
    formValidation: {
      dynamicReq: '{dynamic} est requis',
      verCodeReq: 'Le code de vérification est obligatoire',
      alphanumericReq: 'Veuillez saisir alphanumérique',
      answerReq: 'Veuillez sélectionner',
      sameAsPOA: 'La saisie doit être identique au justificatif de domicile.',
      sameAsID: 'La saisie doit être identique au justificatif d’identité.',
      notBlank: 'Laisser vide si non applicable',
      digits12WithoutDash: 'Veuillez entrer 12 chiffres, sans "-".',
      enterAsPerId: 'Entrez selon ID',
      numberReq: 'Veuillez entrer le numéro',
      addressReq: 'Veuillez entrer votre adresse',
      suburbReq: 'Veuillez entrer votre ville ou banlieue',
      stateReq: 'État ou province requis',
      postcodeReq: 'Veuillez saisir votre code postal',
      countryReq: 'Veuillez sélectionner votre pays de résidence',
      AlphanumericOnlyNotSpace: 'Uniquement alphanumérique. Les espaces ne sont pas autorisés.',
      AlphanumericOnly: 'Uniquement alphanumérique.',
      NumbersSymbol: 'Uniquement chiffres et symboles.',
      NumbersOnlyNotSpace: 'Uniquement des chiffres. Les espaces ne sont pas autorisés.',
      NumbersOnly: 'Uniquement des chiffres.',
      LettersSymbol: 'Uniquement des lettres et des symboles.',
      LettersSymbolNotSpace:
        'Uniquement des lettres et des symboles. Les espaces ne sont pas autorisés.',
      LettersOnlyNotSpace: 'Uniquement des lettres. Les espaces ne sont pas autorisés.',
      NotSpace: "L'espace n'est pas autorisé.",
    },
  },
  report: {
    title: { rebateReport: 'RAPPORT DE BONUS' },
    rebate: {
      column: {
        name: 'NOM',
        accountNumber: 'NUMÉRO DU COMPTE',
        accountType: 'TYPE DE COMPTE',
        volume: 'Volume',
        rebate: 'Bonus',
        totalRebate: 'BONUS TOTALE',
        date: 'DATE',
        instrument: 'INSTRUMENT',
      },
      dropdown: { allInstruments: 'Tous les instruments', all: 'Tous', instruments: 'Instruments' },
      others: {
        rebate: 'Bonus',
        instrumentsTraded: 'INSTRUMENTS TRADÉS',
        lots: 'Lots',
        totalRebate: 'BONUS TOTALE',
        rebates: 'Bonus',
      },
    },
  },
  ibReport: {
    header: 'RAPPORT IB',
    netFunding: 'PAIEMENT NET',
    deposits: 'DÉPÔTS',
    withdraw: 'RETRAIT',
    openedAccs: 'COMPTES OUVERTS',
    funding: 'PAIEMENT',
    withdrawals: 'RETRAIT',
    appliedAmount: 'montant appliqué',
    depositAmount: 'Montant du dépôt',
    withdrawalAmount: 'Montant de retrait',
    timeLimitMessage:
      'La plage de requête maximale pour le téléchargement est de 90 jours, veuillez mettre à jour la nouvelle plage de dates',
    successInfo: 'Téléchargement réussi',
    download: 'TÉLÉCHARGER',
    comfirm: 'Confirmer',
    defaultTimeMessage: 'La plage de requête maximale pour le téléchargement est de 90 jours',
  },
  lead: { header: 'OPPORTUNITES', ibAccount: 'COMPTE IB', demoAccounts: 'COMPTES DÉMO' },
  pendingAccounts: {
    header: 'COMPTES EN ATTENTE',
    errorDoc:
      "Les demandeurs suivants ont rempli le formulaire de demande en ligne, mais doivent encore fournir un justificatif d'identité afin de finaliser la création de leur compte.",
    acceptedDocType: "Les pièces d'identité acceptées comprennent:",
    primaryDoc: "Pièce d'identité principale",
    passport: 'Une copie de votre passeport international valide (page de signature)',
    id: "Une copie de votre carte d'identité nationale délivrée par l'administration",
    dl: 'Une copie de votre permis de conduire valide',
    address: "Autre pièce d'identité",
    addressList: [
      "Copie d'une facture de services publics récente (datant de moins de 6 mois)",
      "Copie d'un relevé de carte de crédit (pas plus de 6 mois)",
      "Copie d'un relevé bancaire (pas plus de 6 mois)",
    ],
    tip: "Veuillez inviter les demandeurs à fournir une copie d'UN des justificatifs d'identité principaux et autres pour ",
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'HISTORIQUE DES TRANSACTIONS',
    rebateHistory: 'BONUS',
    transferHistory: 'TRANSFERTS',
    withdrawHistory: 'RETRAITS',
    cancelWithdraw: 'Souhaitez-vous annuler la demande de retrait ?',
    cancelError:
      "Votre retrait est en cours de traitement en ce moment. Par conséquent, nous ne pouvons pas accepter votre demande d'annulation. Pour plus d'informations, vous pouvez nous joindre en utilisant la fonction de chat en direct.",
  },
  fileUploader: {
    supportedFormat: 'Types de fichier pris en charge : bmp, jpg, gif, jpeg, png, tiff, doc, pdf',
    supportedFormatCustom: 'Types de fichier pris en charge: {files}',
    maxSize: 'Taille maximale du fichier de téléchargement : {maxSize} Mo',
    maxFileNum: 'Nombre maximum de fichiers : {maxFileNum}',
    selectFile: 'Sélectionner un fichier',
    maxFileSize:
      'Le fichier téléchargé peut être au format jpg, png ou pdf, et la taille du fichier ne doit pas dépasser {maxSize} Mo.',
    sureToDelete: {
      title: 'En êtes-vous sûr ?',
      content: 'Êtes-vous sûr de vouloir supprimer le fichier téléchargé ?',
    },
  },
  transferHistory: {
    payStatus: { processing: 'En cours', paid: 'Payé', unpaid: 'Non payé', rejected: 'Rejeté' },
  },
  referralLinks: {
    header: 'LIENS DE RÉFÉRENCE',
    ibAcc: "Compte de l'apporteur d'affaires",
    campLang: 'Langue de la campagne',
    cpy: 'COPIER',
    alreadyCPY: 'COPIÉ !',
    liveAcc: 'Compte en direct',
    demoAcc: 'Compte démo',
    homePage: "Page d'accueil",
    Share: 'Partager',
    subIbReferral: 'Sous-référence IB',
  },
  settings: { ibProfile: 'PROFIL IB', info: 'INFOS', reg: "DATE D'INSCRIPTION" },
  myProfile: {
    changeDetails:
      "Si vous souhaitez mettre à jour les détails de votre profil existant, n'hésitez pas à contacter {mailTo}",
  },
  unfundedAcc: {
    header: 'COMPTES NON APPROVISIONNÉS',
    allActivationAccs: "TOUS LES COMPTES D'ACTIVATION",
    neverFundedAccs: 'COMPTES JAMAIS APPROVISIONNÉS',
    prevFundedZero: 'SOLDE NUL',
  },
  multiib: { level: 'NIVEAU {level}', noib: 'Aucun sous-CR dans ce compte.' },
  header: { bckToCP: 'RETOUR AU PORTAIL CLIENT', logout: 'DÉCONNEXION' },
  menu: {
    dashboard: 'TABLEAU DE BORD',
    ibReport: 'RAPPORT IB',
    rebateReport: 'RAPPORT DE BONUS',
    accManagement: 'GESTION DES COMPTES',
    iblevel: 'CR MULTI-NIVEAU',
    ibAccs: 'COMPTES ACTIVÉS',
    leads: 'NOUVEAUX CLIENTS',
    pendingAccs: 'COMPTES EN ATTENTE',
    unfundedAccs: 'COMPTES NON APPROVISIONNÉS',
    payHistory: 'HISTORIQUE DES TRANSACTIONS',
    ibProfile: 'PROFIL IB',
    refLinks: 'LIENS DE RÉFÉRENCE',
    contactUs: 'NOUS CONTACTER',
    switchCP: 'BASCULER VERS LE PORTAIL CLIENT',
  },
  footer: {
    riskWarn:
      "Avertissement sur les risques: le trading de contrats sur différence (CFD) comporte un niveau de risque élevé pour votre capital et peut entraîner des pertes, vous ne devriez négocier qu'avec de l'argent que vous pouvez vous permettre de perdre. Le trading de CFD peut ne pas convenir à tous les investisseurs, veuillez vous assurer de bien comprendre les risques encourus et de prendre les mesures appropriées pour les gérer. Veuillez lire attentivement le document de divulgation des risques pertinent.",
  },
  responseMsg: {
    410: 'Échec de la validation du paramètre',
    411: 'Mot de passe incorrect',
    420: "L'utilisateur n'existe pas",
    421: 'Réinitialisation du mot de passe refusée',
    431: 'Le calcul du remboursement est en cours, veuillez réessayer votre demande après quelques temps',
    490: 'Ne soumettez pas les informations de votre carte en double',
    500: "Une erreur s'est produite.",
    501: 'Échec de la demande. Veuillez réessayer plus tard.',
    505: 'Échec du téléchargement du fichier',
    520: 'Échec de connexion. Veuillez réessayer.',
    521: 'Un utilisateur est déjà connecté sur cet appareil !',
    522: 'Veuillez vous reconnecter',
    523: 'Veuillez vous reconnecter',
    524: "Malheureusement votre adresse e-mail n'a pas été reconnue",
    525: "Nom d'utilisateur ou mot de passe incorrect",
    527: "L'utilisateur n'est pas un apporteur d'affaires",
    528: "L'utilisateur n'existe pas",
    529: "Nom d'utilisateur ou mot de passe incorrect",
    530: 'Session de connexion non valide',
    540: 'Votre compte de trading est introuvable',
    541: 'Compte de bonus introuvable',
    542: "Vous n'êtes pas autorisé à ouvrir un compte supplémentaire",
    544: "Vous n'êtes pas autorisé à demander un compte supplémentaire tant que votre pièce d'identité n'a pas été vérifiée",
    550: 'Numéro de compte incorrect',
    551: 'Votre solde est insuffisant',
    553: "Vous ne pouvez pas retirer / transférer en raison du crédit sur votre compte. Veuillez contacter {email} pour obtenir de l'aide",
    554: 'La somme demandée est égale à zéro ou est nulle',
    564: 'Le pays ne correspond pas au canal de paiement',
    565: 'Code de banque incorrect',
    566: 'Méthode de paiement introuvable',
    581: 'Vous avez bien choisi la promotion. Veuillez noter que vous devez effectuer un dépôt pour retirer des fonds.',
    582: "Vous participez à l'événement et avez des postes, vous ne pouvez donc pas soumettre de demande de retrait.",
    584: "Vous n'avez aucune bonus applicable",
    590: 'Compte du transfert sortant introuvable',
    591: 'Compte du transfert entrant introuvable',
    593: 'Nous ne sommes pas en mesure de traiter votre transfert de fonds depuis le compte sélectionné car votre compte contient du crédit.',
    594: "Le transfert de fonds depuis ce compte n'est pas autorisé",
    595: 'Comme vous participez à nos activités, votre demande de transfert ne peut pas être traitée pour le moment.',
    647: 'Le code de vérification est incorrect',
    1101: "10 comptes existent, impossible d'en ajouter.",
    1102: "Impossible d'activer, le compte a été créé en tant que compte réel ou démo.",
    1103: 'Le compte a été désactivé',
    1202: 'La vérification a expiré, veuillez réessayer',
    1204: 'Veuillez essayer avec une autre adresse e-mail',
    1208: 'Les codes de vérification ne peuvent pas être envoyés à plusieurs reprises',
    1209: "Erreur d'envoi du code de vérification",
    session_timeout: 'Expiration de la session. Redirection vers la page de connexion.',
  },
  verificationStatus: {
    title: { default: 'C’est presque terminé', LV3: 'Bienvenue' },
    dueToAML:
      'Vous êtes prêt et allez bientôt pouvoir commencer à trader ! Aidez-nous à protéger votre compte en vérifiant vos informations personnelles.',
    accOpeningVerification: {
      title: 'Créer un compte réel',
      content: 'Créez un compte réel pour commencer à approvisionner votre compte.',
    },
    identityVerification: {
      title: 'Vérification de l’identité',
      content:
        'Soumettez votre justificatif d’identité pour le faire vérifier et commencer à trader.',
    },
    poaVerification: {
      title: 'Vérification de l’adresse',
      content:
        'Le justificatif de résidence doit être vérifié avant de pouvoir effectuer un retrait.',
    },
    kyc: {
      depositNow: 'Déposer',
      withdrawNow: 'Faire un retrait',
      createNow: 'Créer',
      start: 'Commencer',
      complete: 'Terminé',
      pending: 'En attente',
      rejected: 'Rejetée',
      resubmit: 'Soumettre à nouveau',
    },
    resubmitNote:
      'Il est possible que vos documents soient manquants ou incorrects. Veuillez les soumettre à nouveau.',
    rejectedNote: 'Des questions ? Contactez-nous par {liveChat} .',
    liveChat: 'chat',
    uploadTemplate: {
      idGuideTitle: 'Directives relatives aux justificatifs d’identité',
      uploadGuideTitle: 'Télécharger les directives',
      desc1: 'Documents émis par le gouvernement.',
      desc2: 'Documents non modifiés, avec les dimensions d’origine.',
      desc3: 'Placez les documents sur un fond de couleur unie.',
      desc4: 'Image en couleur lisible et correctement éclairée.',
      desc5: 'Pas d’image en noir et blanc.',
      desc6: 'Pas de documents modifiés ou expirés.',
      bottomDesc:
        'Le fichier téléchargé peut être au format jpg, png ou pdf, et la taille du fichier ne doit pas dépasser 5 Mo.',
    },
  },
  register: {
    progressStatus: {
      title: 'Avancée de la procédure de vérification',
      estimatedTimeTitle: 'temps estimé',
      min: 'Minutes',
    },
    tabs: {
      personalDetails: 'Informations personnelles',
      moreAboutYou: "Plus d'informations vous concernant",
      accountConfig: 'Configuration du compte',
      confirmYourId: 'Confirmer votre identité',
      fundYourAcc: 'Approvisionner votre compte',
      personalInformation: 'Informations personnelles',
      createLiveAccount: 'Créer un compte réel',
      financialInfo: 'Infos financières',
      iDProof: 'Justificatif d’identité',
      addressProof: 'Justificatif d’adresse',
    },
    btn: {
      next: 'SUIVANT',
      back: 'RETOUR',
      fundnow: 'APPROVISIONNER MAINTENANT',
      upload: 'Télécharger',
      done: 'terminé',
      bTCP: 'Retour au Portail client',
      proceedToUpload: 'Continuer et télécharger',
      completeID: 'Faire vérifier l’identité',
      completePOA: 'Vérification du justificatif de domicile effectuée',
      depositViaOther: 'Déposer via un autre canal',
      withdrawViaOther: 'Retirer via un autre canal',
    },
    demo: {
      proTrade: 'Rapports marketing professionnels',
      customerService: 'Service client personnalisé 24h / 24 et 7j / 7',
      competitivePromotionals: 'Promotions compétitives',
      secure: 'Transactions sécurisées 3D',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Informations personnelles',
        userTitle: 'Titre',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Adresse e-mail',
        dob: 'Date de naissance',
        idType: "Type de document d'identité",
        referral: "Avez-vous été recommandé par quelqu'un ?  (optionnelle)",
        middleName: 'Deuxième prénom',
        nationality: 'Nationalité',
        phoneNum: 'Numéro de téléphone',
        date: 'Date de naissance',
        month: 'Mois',
        year: 'Année',
        placeOfBirth: 'Lieu de naissance',
        idNum: "Numéro d'identification",
        passportNum: 'Passeport',
        driverLicenseNum: 'Permis de conduire',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Passeport',
          idCard: "Carte d'identité nationale",
          driverLicense: 'Permis de conduire',
        },
      },
      page2: {
        pageTitle: 'ADRESSE DE DOMICILE PRINCIPAL',
        mainResidency: 'Pays de résidence',
        streetNumber: 'Numéro de rue',
        streetName: 'Nom de rue',
        address: 'Adresse',
        provinceOrState: 'Province / État',
        cityOrSuburb: 'Ville / Quartier',
        postcode: 'Code postal',
        usQuestion:
          'Êtes-vous un citoyen des États-Unis/ Résidez-vous aux États-Unis pour des raisons fiscales ou avez-vous des liens/relations avec les États-Unis (ex. : compte bancaire, etc.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLOI ET DÉTAILS FINANCIERS',
      pageTitle2: 'Connaissances et expérience en matière de trading',
      complianceExplain:
        "Les questions suivantes sont posées dans le cadre des exigences requises en matière de lutte contre le blanchiment d'argent et le financement du terrorisme.",
    },
    accountConfig: {
      pageTitle: 'Configuration du compte',
      chooseTradingPlatform: 'Choisir la plateforme de trading',
      chooseAccType: 'Choisir le type de compte',
      chooseAccCurrency: 'Choisir la devise du compte',
    },
    confirmYourId: {
      pageTitle: 'Confirmer votre identité',
      description:
        "En tant que broker de forex régulé, nous devons vérifier votre identité. Veuillez donc télécharger un document d'identité ci-dessous ou nous l'envoyer à l'adresse {mailTo}",
      poidTitle: "Pièce d'identité",
      porTitle: 'Justificatif de domicile',
      uploadDescription: 'Choisissez un type de document et téléchargez le document correspondant',
      note: 'IMPORTANT: Le nom sur le document DOIT clairement indiquer votre nom complet.',
      pending: { upload: 'Téléchargement en cours', verification: 'Vérification en cours' },
      poidTypes: {
        photoId: "Carte d'identité avec photo",
        passport: 'Passeport',
        drivingLicence: 'Permis de conduire',
      },
      porTypes:
        '<li>Facture de service public</li>\n      <li>Relevé bancaire</li>\n      <li>Certificat de résidence</li>',
    },
    finishPage: {
      title:
        "{individualUserName}, Merci d'avoir lancé la procédure de création d'un compte {platformName} en direct !",
      contentDefault:
        "Nous avons bien reçu votre formulaire d'inscription et paramétré votre compte en direct.  <b>{account}</b>. <br />\n      Vous recevrez sous peu un e-mail <b>{email}</b> contenant vos informations de connexion et un lien qui vous permettra de consulter le statut de votre compte. <br />",
      contentWithoutId3WithAccount:
        'Votre compte de trading en direct est en cours de traitement et vous recevrez sous peu un e-mail contenant de plus amples informations.<br />\n      Pour accélérer le traitement de votre compte en direct, vous pouvez financer votre compte maintenant et recevoir une ouverture prioritaire..<br />',
      contentWithoutId3WithoutAccount:
        'Votre compte de trading en direct est en cours de traitement et vous recevrez sous peu un e-mail contenant de plus amples informations.<br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Vérification à l’ouverture de compte',
      personalInformation: {
        narrative: 'Vos informations personnelles sont nécessaires pour ouvrir votre compte réel.',
        firstName: 'Prénom',
        middleName: 'Deuxième prénom',
        lastName: 'Nom',
        gender: 'Sexe',
        genderList: { male: 'Homme', female: 'Femme', other: 'Autre' },
        dob: 'Date de naissance',
        day: 'Jour',
        month: 'Mois',
        year: 'Année',
        mobile: 'Numéro de téléphone mobile',
        countryName: 'Pays de résidence',
        nationality: 'Nationalité',
      },
      createLiveAccount: {
        narrative:
          'Complétez quelques étapes pour ouvrir un compte et commencer à l’approvisionner. Plus d’informations sur nos types de compte <a href="{link}" target="_blank" alt>ici</a>.',
        choose: 'Choisir {dynamic}',
        tradingPlatform: 'une plate-forme de trading',
        accountType: 'le type de compte',
        accountCurrency: 'la devise du compte',
        islamicTooltip:
          '{platform} peut vous demander de fournir des pièces justificatives pour vérifier que vous êtes de confession islamique. {platform} se réserve le droit de modifier la configuration de votre compte en cas d’échec de la vérification.',
        successInfo: {
          title: 'Compte de trading créé',
          content:
            'Félicitations ! Vous avez complété avec succès le processus d’ouverture du compte. <br /> Pour commencer à trader, faites un dépôt et vérifiez votre justificatif d’identité.',
        },
        successInfo2: {
          title: 'Compte de trading créé',
          content:
            'Renseignez les informations de votre profil financier pour commencer l’étape suivante de la vérification de votre compte.',
          start: 'Commencer',
        },
        generalInfo: {
          title: 'Nous examinons votre soumission.',
          content:
            'Merci d’avoir ouvert un compte chez PU Prime. Vous pourrez commencer à approvisionner votre compte dès l’approbation de votre soumission.',
        },
        rejectedInfo: {
          title: 'Rejetée',
          content:
            'Nous avons le regret de vous informer que votre demande d’ouverture de compte chez PU Prime a été rejetée.',
        },
      },
    },
    identityVerification: {
      title: 'Vérification de l’identité',
      subTitle: 'Informations sur l’identité',
      narrative: 'Renseignez les informations',
      firstName: 'Prénom',
      middleName: 'Deuxième prénom',
      lastName: 'Nom',
      nationality: 'Nationalité',
      idType: 'Type de justificatif d’identité',
      idSerialNumber: 'Numéro du justificatif d’identité',
      passportNum: 'Passeport',
      driverLicenseNum: 'Permis de conduire',
      upload: {
        title: 'Télécharger un document',
        desc: 'Assurez-vous que le document présente votre photo, votre nom complet, votre date de naissance et la date de délivrance.',
        guideline: 'Télécharger les directives',
        positive: 'Téléchargement réussi',
        signaturePage: 'Télécharger la page de signature',
      },
      idTypeDropdown: {
        passport: 'Passeport',
        idCard: "Carte d'identité nationale",
        driverLicense: 'Permis de conduire',
        other: 'Autre',
      },
      additionalInfo: {
        title: 'Infos supplémentaires requises',
        content: 'Faire vérifier votre identité pour commencer à trader',
      },
      successInfo: {
        title: 'Vérification de l’identité effectuée',
        content:
          'Félicitations ! Votre document d’identité est vérifié. Vous pouvez commencer à trader sur MT4 / MT5 ou dans l’application {platform} si vous avez déjà effectué un dépôt.',
      },
      generalInfo: {
        title: 'Nous examinons votre soumission.',
        content:
          'Merci d’avoir ouvert un compte chez {platform}. Vous pourrez commencer à trader après la vérification de vos informations d’identité.',
      },
      rejectedInfo: {
        title: 'Rejetée',
        content:
          'Nous avons le regret de vous informer que votre demande d’ouverture de compte chez {platform} a été rejetée.',
      },
      identifyingRequiredInfo: {
        title: 'Infos supplémentaires requises',
        content:
          'Certains de vos documents d’identification peuvent être manquants ou incorrects. Veuillez les télécharger à nouveau pour continuer.',
      },
      streetNumber: 'Numéro de rue',
      streetName: 'Nom de rue',
    },
    poaVerification: {
      proofOfAddressVer: 'Vérification du justificatif d’adresse',
      subTitle: 'Informations sur l’adresse',
      narrative: 'Renseignez les informations',
      dueToAML:
        'En raison des exigences AML, votre adresse doit être vérifiée avant de pouvoir effectuer un retrait.',
      nationality: 'Nationalité',
      country: 'Pays de résidence',
      address: 'Adresse',
      postalCode: 'Code postal',
      citySuburb: 'Ville / Quartier',
      city: 'La ville',
      provinceState: 'Province / État',
      upload: {
        title: 'Certificat de justificatif de domicile',
        inst1: 'Facture domestique (électricité, eau, gaz)',
        inst2: 'Relevé bancaire ou de carte de crédit',
        desc: 'Le justificatif de domicile pour les retraits doit être daté depuis moins de {months} mois.',
        guideline: 'Télécharger les directives',
        uploadFilesPhoto: 'Télécharger les fichiers / photos',
      },
      additionalInfo: {
        title: 'Infos supplémentaires requises',
        content:
          'Le justificatif de résidence doit être vérifié avant de pouvoir effectuer un retrait.',
      },
      successInfo: {
        title: 'Justificatif d’adresse vérifié',
        content:
          'Félicitations ! Votre justificatif de domicile est vérifié. Vous pouvez désormais effectuer des retraits.',
      },
      generalInfo: {
        title: 'Nous examinons votre soumission.',
        content:
          "Merci d'avoir échangé avec {platform}. Vous pouvez commencer le processus de retrait une fois votre soumission approuvée.",
      },
      identifyingRequiredInfo: {
        title: 'Infos supplémentaires requises',
        content:
          'Certains de vos documents d’identification peuvent être manquants ou incorrects. Veuillez les télécharger à nouveau pour continuer.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'Vos informations financières',
        subTitle: 'Emploi et informations financières',
        subTitle2: 'Connaissances et expérience en trading',
        employmentStatus: 'Employment Status',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} par semaine',
        estimatedAnnualIncome: 'Situation professionnelle',
        estimatedSavingsAndInvestments: 'Estimation de l’épargne et des investissements',
        estimatedIntendedDeposit: 'Estimation du dépôt prévu',
        sourceOfFunds: 'Source des fonds',
        numberOfTrades: 'Nombre de trades',
        tradingAmount: 'Montant du trading',
      },
      iDProof: {
        ver: 'Télécharger la pièce d’identité',
        subTitle: 'Informations sur l’identité',
        narrative: 'Renseignez les informations',
      },
      addressProof: {
        ver: 'Télécharger le justificatif de domicile',
        subTitle: 'Informations sur l’adresse',
        narrative: 'Renseignez les informations',
        upload: {
          title: 'Documents acceptés pour le justificatif d’adresse',
          inst1: 'Facture domestique (électricité, eau, gaz)',
          inst2: 'Relevé bancaire ou de carte de crédit',
          desc: 'Le justificatif de domicile pour les retraits doit être daté depuis moins de {months} mois.',
          guideline: 'Télécharger les directives',
          uploadFilesPhoto: 'Télécharger les fichiers / photos',
        },
      },
      successInfo: {
        title: 'Authentification bancaire terminée',
        content: 'Félicitations ! Vous avez fait vérifier avec succès vos coordonnées bancaires.',
      },
      generalInfo: {
        title: 'Nous examinons votre soumission.',
        content:
          "Vos documents de virement bancaire sont encore en cours de vérification. Vous pouvez toujours effectuer un dépôt par le biais d'un autre canal si vous souhaitez commencer à trader maintenant.",
      },
      rejectedInfo: {
        title: 'Rejetée',
        content: 'Votre demande de dépôt par virement bancaire a été rejetée.',
      },
      identifyingRequiredInfo: {
        title: 'Infos supplémentaires requises',
        content:
          'Certains de vos documents d’identification peuvent être manquants ou incorrects. Veuillez les télécharger à nouveau pour continuer.',
      },
    },
  },
  ibProfile: {
    title: 'PROFIL IB',
    subTitle: 'Shared IB',
    sharedIbList: {
      sharedIbAccount: 'Compte IB partagé',
      maxSharedIbAccount: 'Maximum 10 comptes IB partagés',
      add: 'Ajouter',
      accountHistory: 'Historique du compte IB partagé',
      setting: 'Paramètres',
      delete: 'Supprimer',
      enable: 'Activer',
      disable: 'Désactiver',
      enabled: 'Activé',
      disabled: 'Désactivé',
      sharedAccountEnabled: 'Le compte IB partagé a été activé.',
      sharedAccountDisabled: 'Le compte IB partagé a été désactivé.',
      sharedAccountCopied: 'Compte IB partagé copié',
      deleteTips:
        'Le compte IB partagé ne pourra pas se connecter après sa suppression, voulez-vous le supprimer ?',
      sharedAccountDeleted: 'Le compte IB partagé a été supprimé.',
      copyTips: 'Compte IB partagé copié',
      remark: 'Remarque',
      cancel: 'Annuler',
      accountData: 'Données des comptes',
      IBData: 'Données du rapport IB',
      fundOperations: 'Autorisation des fonds',
      resetPassword: 'Réinitialiser le mot de passe',
      accountHistoryBtn: 'Historique du compte partagé',
    },
    updateSharedIb: {
      addNewSharedIb: 'Ajouter un nouveau compte IB partagé',
      editSharedIb: 'Modifier le compte IB partagé',
      maxSharedIbAccountError: "10 comptes existent, impossible d'en ajouter.",
      email: 'Adresse e-mail',
      emailValidate: 'Veuillez saisir une adresse e-mail valide',
      emailError: "L'e-mail existe déjà, veuillez en essayer un autre",
      password: 'Mot de passe',
      passwordValidate: 'Veuillez saisir le mot de passe',
      passwordValidateNum: '8 à 16 caractères',
      passwordValidateTips:
        'Mélange de lettres majuscules et minuscules (A-Z, a-z), de chiffres (0-9) et de caractères spéciaux (par exemple : !@#S%^&)',
      passwordValidateWarning:
        '8 à 16 caractères,Mélange de lettres majuscules et minuscules (A-Z, a-z), de chiffres (0-9) et de caractères spéciaux (par exemple : !@#S%^&)',
      sharedIbAccountPermission: 'Permission du compte IB partagé',
      remark: 'Remarque',
      remarkTips: 'Vous pouvez enregistrer le but du compte ou le titulaire du compte',
      status: 'Statut',
      cancel: 'Annuler',
      confirm: 'Confirmer',
      disabled: 'Désactivé',
      enabled: 'Activé',
      resetPassword: 'Réinitialiser le mot de passe',
      emailPlaceholder: 'Veuillez entrer votre adresse e-mail',
      addSuccess: 'Compte commun créé',
      permissionIdsTips:
        'Vous pouvez autoriser ou non les permissions suivantes pour les comptes partagés :',
      permissionIdsFirst:
        "<b>Données des comptes</b>: Afficher les pages des données du compte (Comptes IB, Comptes récemment ouverts). Les données seront masquées si l'autorisation n'est pas autorisée.",
      permissionIdsSecond:
        "<b>Données du rapport IB </b>: Afficher les pages des données IB (Tableau de bord, IB multi-niveaux - Compte). Les données seront masquées si l'autorisation n'est pas autorisée.",
      permissionIdsThird:
        "<b>Autorisation des fonds </b>: Opérer sur les fonds IB (Demander un remboursement, Transférer un remboursement, Retirer un remboursement), Afficher la remise (rapport sur les remises, enregistrements de solde et de fonds). Les opérations ne peuvent pas être effectuées si l'autorisation n'est pas autorisée.",
      newPassword: 'Veuillez saisir le nouveau mot de passe',
      newPassowrdReset: 'Veuillez saisir le nouveau mot de passe',
      passwordSame: "Le nouveau mot de passe ne peut pas être le même que l'ancien.",
      resetPasswordSuccess: 'Réinitialisation du mot de passe réussie',
      enterNewPassword: 'Veuillez saisir le nouveau mot de passe',
      changePasswordTips:
        "L'ancien mot de passe sera invalide et réinitialisé avec le nouveau mot de passe",
      fundsChangeTips:
        "Une fois l'autorisation des fonds accordée, le compte IB commun peut transférer et retirer le solde de votre compte IB principal. Souhaitez-vous autoriser cela ?",
      editSuccess: 'Modifié avec succès',
    },
    sharedIbHistory: {
      allHistory: "Tout l'historique a été affiché",
      lastUpdate: 'Dernière mise à jour',
      refreshTips: 'Historique mis à jour',
      refresh: 'Actualiser',
      chooseAccount: 'Choisir le compte',
      device: 'Appareil',
      login: 'Se connecter',
      commission: 'Demander le remboursement',
      withdraw: 'Retrait du remboursement',
      transfer: 'Transfert du remboursement',
      fromTo: 'De {fromAccount} à {toAccount}, montant de {total}',
      viewMore: 'Voir plus',
    },
    npPermissionTips: 'Pas de permission pour ce compte',
  },
  agreement: {
    title: 'Contrat de Courtier Introducteur Confirmant',
    rebateAccount: 'Compte de rabais',
    description:
      "En cliquant sur Accepter, vous confirmez que vous avez lu, compris et accepté toutes les informations, les termes et conditions de service dans l'accord.",
    agree: 'Accepter',
    signed: 'Compte de rabais {accountID}. Signé le {signedTime}',
    IBAgreement: 'Accord IB',
    confirmAgreement: "Veuillez contacter le Responsable IB pour confirmer l'accord IB",
  },
};
