export default {
  en: 'English',
  zh: '中文',
  home: {
    NewPortal: 'New Portal',
    SecureIBPortal: 'Secure IB Portal',
    DASHBOARD: 'DASHBOARD',
    MARKETING_TOOLS: 'MARKETING TOOLS',
    IB_REPORT: 'IB REPORT',
    REBATE_REPORT: 'REBATE REPORT',
    IB_ACCOUNTS: 'IB ACCOUNTS',
    PAYMENT_HISTORY: 'TRANSACTION HISTORY',
    IB_FAQ: 'IB FAQ',
    RebateAccountNumber: 'Rebate account number',
    totalBalance: 'total balance',
    totalCommission: 'total commission',
    APPLY_FOR_A_REBATE: 'APPLY',
    availableBalance: 'available balance',
    WITHDRAW_REBATE: 'WITHDRAW',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFER',
    MonthtoDate: 'Month to Date',
    UPDATE: 'UPDATE',
    REBATE: 'REBATE',
    TOTAL_VOLUME: 'TOTAL VOLUME',
    NET_FUNDING: 'NET FUNDING',
    DEPOSITS: 'DEPOSITS',
    WITHDRAW: 'WITHDRAW',
    OPEND_ACCOUNT: 'OPENED ACCOUNT',
    PERFORMANCE: 'PERFORMANCE',
    OpendAccount: 'Opened Account',
    select_ib: 'Please select IB account',
    MOST_TRADED_INSTRUMENTS: 'MOST TRADED INSTRUMENTS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'TOP PERFORMING ACCOUNTS FOR THIS MONTH',
    RECENTLY_OPENED_ACCOUNTS: 'RECENTLY OPENED ACCOUNTS',
    ACCOUNT_MANAGER: 'CUSTOMER SUPPORT',
    Account: 'Account',
    Name: 'Name',
    Volume: 'Volume',
    Rebate: 'Rebate',
    Balance: 'Balance',
    ContactClient: 'Contact Client',
    YouCannotAskForCommission: "You can't ask for a commission",
    applicationFailedPleaseTryAgain: 'The application failed, please try again later',
    applyRebateSuccessTip:
      'Congratulations, we have received your rebate request. | {amount} will be transferred to the rebate Account {account} shortly. | Once processed, you will be able to withdraw or transfer your funds.',
    HOVER:
      'The previous day’s rebates are calculated and processed between 09:00 - 11:00 AEST daily.',
    ACCOUNTS_PROFIT:
      'Historical total profit/loss of all trading accounts with this IB account. Loading may take a moment.',
    PROFIT: 'ACCOUNTS PROFIT/LOSS',
    reload: 'Reload',
    blacklist:
      'This account is unable to make a rebate at the moment, please contact your account manager.',
  },
  popupBanner: { doNotShowThisAgain: 'Do Not Show This Again.' },
  liveChat: { header: 'Live Chat' },
  contactUs: {
    click: 'Click',
    assist: ' for assistance now',
    header: 'CONTACT US',
    followUs: 'FOLLOW US',
    tip: "{platform}'s professional multilingual customer support team is ready to assist you and help to provide you an incomparable trading experience.",
    chatNow: "Let's Chat now",
    globalOffice: 'Global Office',
  },
  ibAccounts: {
    header: 'IB ACCOUNTS',
    ibAccount: 'IB Account',
    subIbs: 'Sub IB Account',
    tradinglist: 'Trading List',
    openlist: 'Open List',
    closedlist: 'Closed List',
    tradinglistColumn: {
      ticketid: 'TicketID',
      opentime: 'Open Time',
      type: 'Type',
      volume: 'Volume',
      item: 'Item',
      openprice: 'Open Price',
      closeprice: 'Close Price',
      closetime: 'Close Time',
      commission: 'Commission',
      profit: 'Profit/Loss',
      buy: 'Buy',
      sell: 'Sell',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Select Credit Card',
      selectBA: 'Select Bank Account',
      anotherCC: 'Enter credit card manually',
      anotherBA: 'Add bank account',
      remember: 'Remember my account',
      verificationCode:
        'Verification code has been sent to {email}. Please contact {supportEmail} if you need further assistance.',
      fetchTradeAccFailed: 'Fetch Trading Account failed',
      phone: 'PHONE',
      evp: 'EVP',
      failed: 'Withdraw failed. Please try again later',
      tokenExpired: 'Token is expired',
    },
    alert:
      'Due to AML laws, the method you use to withdraw must be the same as the method used for your deposit. If the withdrawal amount is less than 100 units of your account base currency, you will be charged a 20 unit for handling fee by the bank.',
    NetellerChargeAlert: 'Neteller charges {percentageFee}% transaction fee for every withdrawal.',
    FasaPayChargeAlert: 'FasaPay charges 0.5% transaction fee for every withdrawal.',
    SkrillChargeAlert: 'Skrill charges {percentageFee}% transaction fee for every withdrawal.',
    cryptoAlert:
      'Please note that we are unable to deposit or withdraw via BUSDT, please ensure the address and Cryptocurrency match the chain and currency we accept or you may loss the fund.',
    usdtNote:
      'The current gas fee of ERC20 is significantly higher than usual and as a result there may be a lengthy delay in the processing of your transaction. Alternatively, you are able to withdraw funds via TRC20 by providing the coresponding wallet address, or you can elect to pay the gas fee to speed up your ERC20 withdrawal.',
    REBATE_WITHDRAW_REQUEST: 'REBATE WITHDRAW REQUEST',
    PleaseSelectDesiredWithdrawMethod: 'Please select desired withdraw method',
    BankName: 'Bank Name',
    BankAccountsName: 'Bank Accounts Name',
    FasaPayAccountName: 'FasaPay Account Name',
    AustralianBankName: 'Australian Bank Name',
    BankAccountNumber: 'Bank Account Number',
    FasaPayAccountNumber: 'FasaPay Account Number',
    BankAccountBeneficiary: 'Bank Account Beneficiary',
    BSB: 'BSB',
    skrillEmail: 'Skrill Email',
    SticpayEmail: 'SticPay Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    PerfectMoneyAccount: 'Perfect Money Account',
    BitwalletEmail: 'Bitwallet Email',
    BitcoinEmail: 'Bitcoin Wallet Address',
    USDTEmail: 'Tether Wallet Address',
    USDCEmail: 'USDC Wallet Address',
    ETHEmail: 'ETH Wallet Address',
    Chain: 'Chain',
    BankBranch: 'Bank Branch',
    Province: 'Province',
    City: 'City',
    BankAddress: 'Bank Address',
    bankCity: 'Bank City',
    bankProvince: 'Bank Province',
    AccountNumberIBAN: 'Account Number/IBAN',
    BankBeneficiaryName: 'Bank Beneficiary Name',
    BankAccountBeneficiaryName: 'Bank Account Beneficiary Name',
    beneficiaryName: 'Beneficiary Name',
    AccountHoldersAddress: "Account Holder's Address",
    SelectWithdrawCard: 'Select Your Withdraw Card',
    Swift: 'Swift',
    ifscCode: 'IFSC Code',
    SwiftAud: 'Swift(Non AUD Accounts)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    ImportantNotes: 'Important Notes',
    YourPaymentGreaterThanAvailableBalance: 'Your payment is greater than the available balance',
    CannotUseMoreThanTwoDecimalValues:
      'You cannot use Numbers and negative Numbers with more than two decimal places',
    YourPaymentIsBeingProcessed: 'Your withdraw application has been submitted',
    Itcannotbe0: "It can't be 0",
    noCardWarn:
      'You don’t have any available card to make withdrawal, please link your card in Withdraw Details first',
    amtLarger: 'Amount cannot be null and must be greater than or equal to {minLimit} {currency}',
    blackList:
      'Unfortunately you are currently unable to submit a withdrawal request. Please contact {supportEmail} for more information',
    status: {
      withdrawalSubmitted: 'Submitted',
      withdrawalCanceled: 'Cancelled',
      withdrawalAuditing: 'Processing',
      withdrawalRejected: 'Rejected',
      withdrawalAccepted: 'Processing',
      withdrawalPayFailed: 'Failed',
      withdrawalSuccess: 'Processed',
      withdrawalFailed: 'Failed',
      withdrawalPending: 'Pending',
      withdrawalPartialFailed: 'Failed',
      transferSubmitted: 'Processing',
      transferSuccess: 'Processed',
      transferRejected: 'Rejected',
      transferFailed: 'Failed',
      transferProcessing: 'Processing',
      withdrawalReversedFailed: 'Reversed Failed',
      withdrawalPartialSuccess: 'Partial Success',
    },
    formErrorMsg: {
      bankName: 'Bank Name is required',
      bsb: 'BSB is required',
      beneName: 'Bank Beneficiary Name is required',
      beneficiaryNameReq: 'Beneficiary Name is required',
      accNum: 'Bank Account Number is required',
      swift: 'Swift is required',
      bankAddress: 'Bank address is required',
      holderAddress: 'Holder address is required',
      amt: 'Amount is required',
      firstSixDigits: 'Please Enter First 6 digits',
      lastFourDigits: 'Please Enter Last 4 digits',
      branchName: 'Branch name is required',
      email: 'Email is required',
      accName: 'Account name is required',
      province: 'Bank province is required',
      city: 'Bank City is required',
      bankCard: 'Please select a card',
      emailFormat: 'Please input correct email address',
      amtCAD: 'Min ${minLimit} Max $9999.99 {currency}',
      amt0: 'Amount cannot be 0',
      ifscCode: 'IFSC Code is required',
      bitcoin: 'Bitcoin Wallet Address is required',
      eth: 'Eth Wallet Address is required',
      usdc: 'Usdc Wallet Address is required',
      bitcoinFormat: 'Incorrect Bitcoin wallet address format',
      ethFormat: 'Incorrect ETH wallet address format',
      usdcFormat: 'Incorrect USDC wallet address format',
      addressLength: 'Bitcoin wallet address must be between 26 and 42 characters',
      usdt: 'USDT Wallet Address is required',
      walletFormatIncorrect: 'Incorrect {type} wallet address format',
      chain: 'Chain is required',
    },
    transfer: {
      info1:
        '{platform} will waive the bank fee charges on first withdrawal of the month. Additional withdrawals within the same month will incur a bank fee of 20 units of your withdrawal currency. (For USC/HKD/JPY, the fee will be equivalent to 20 USD)',
      info2:
        'Please ensure Bank Beneficiary Name fill in exactly with Bank statement (abbreviation prohibited, fill in full name according to the initial on the statement)',
      info3:
        "Bank account is preferred with IBAN format, if you're not from EU region, please fill in exactly with bank statement",
      label: {
        upload: 'Please upload a copy of your bank statement (current to the last 3 months)',
        upload2:
          'Please make sure that the statement date or the last transaction date is from the current month to the past three months. If it exceeds the timeframe, kindly re-upload the statement.',
      },
      formValidation: { upload: 'Please upload a bank statement' },
      minAmount: 'Minimum withdrawal amount is 100 {currency}',
    },
    transitNumber: 'Transit Number',
    institutionNumber: 'Institution Number',
  },
  security: {
    contactSupport:
      'Unable to access authentication method? <br/> Please contact {contact_email} or Livechat.',
    verificationTitle: 'Verification Required',
    verificationDesc: 'Please enable Security Authenticator APP before proceed.',
    securityAuthenticator: 'Security Authentication',
    verificationRequired: 'Verification Code is required. ',
    resend: 'Resend',
    authenticatorTooltip: 'Authenticator APP verification code refreshes every 30 seconds',
    verifyPostEmailNote: 'Code sent to {email}',
    verifyPreEmailNote: 'Code will be sent to {email}',
    enableNow: 'Enable Now',
  },
  rebate: { status: { processed: 'Processed', processing: 'Processing', rejected: 'Rejected' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'REBATE TRANSFER REQUEST',
    transferAmountIsGreaterThanAvailableBalance:
      'Your transfer amount is greater than the available balance',
    yourRebateTransferHasBeenProcessed: 'Your rebate transfer has been submitted',
    PleaseEnterTransferInformation: 'Please enter accurate transfer information',
    tradingAccount: 'Trading Account',
    rebateAccount: 'Rebate Account',
    account: 'Account',
    blackList:
      'This account has been restricted from making a transfer between accounts, please contact {supportEmail}',
  },
  common: {
    liveChat: { desc: 'Need Help ? Live Chat' },
    lang: {
      english: 'English',
      chinese: 'Chinese',
      french: 'French',
      thai: 'Thai',
      german: 'German',
      spanish: 'Spanish',
      malay: 'Malay',
      viet: 'Vietnamese',
      indo: 'Indonesian',
      arabic: 'Arabic',
    },
    button: {
      iKnow: 'I understand',
      confirmCamelCase: 'Confirm',
      confirm: 'CONFIRM',
      submit: 'Submit',
      cancel: 'CANCEL',
      sendCode: 'Send Code',
      resendCode: 'Resend Code',
      ok: 'OK',
    },
    withdrawChannel: {
      limitTitle: 'Withdrawals are temporarily not supported',
      limitPassword:
        'Due to the modification of your login password, withdrawals are temporarily not supported for {day} days to ensure your account security. This restriction is expected to be lifted in {hour} hours.',
      limitUserLogin:
        'Due to the modification of your login email/phone number, withdrawals are temporarily not supported for {day} days. This restriction is expected to be lifted in {hour} hours.',
      limitSecurityAuthenticator:
        'Due to the modification of your Security Authenticator APP, withdrawals are temporarily not supported for {day} days to ensure your account security. This restriction is expected to be lifted in {hour} hours.',
      banktransfer: 'International Bank Transfer',
      aus: 'Bank Transfer - Australia',
      int: 'Bank Transfer - International',
      chn: 'Bank Transfer - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      unionpaytransfer: 'UnionPay Transfer',
      fasapay: 'FasaPay',
      creditcard: 'Credit Card',
      bpay: 'Bpay',
      creditdebitcard: 'Credit/Debit Card',
      bankwiretransferinternational: 'Bank Wire Transfer (International)',
      banktransferaustralia: 'Bank Transfer (Australia)',
      banktransferinternational: 'International Bank Transfer',
      banktransferchina: 'Bank Transfer (International)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Broker to Broker Transfer',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Internet Banking (Thailand)',
      internetbankingnigeria: 'Internet Banking (Nigeria)',
      internetbankingvietnam: 'Internet Banking (Vietnam)',
      internetbankingmalaysia: 'Internet Banking (Malaysia)',
      internetbankingindonesia: 'Internet Banking (Indonesia)',
      internetbankingkorea: 'Internet Banking (Korea)',
      internetbankingindia: 'Internet Banking (India)',
      internetbankingphilippine: 'Internet Banking (Philippine)',
      internetbankingsouthafrica: 'Internet Banking (South Africa)',
      internetbankinguganda: 'Internet Banking (Uganda)',
      internetbankingrwanda: 'Internet Banking (Rwanda)',
      internetbankingzambia: 'Internet Banking (Zambia)',
      internetbankingcongo: 'Internet Banking (Congo)',
      internetbankingcameroon: 'Internet Banking (Cameroon)',
      internetbankingburundi: 'Internet Banking (Burundi)',
      internetbankingkenya: 'Internet Banking (Kenya)',
      internetbankingghana: 'Internet Banking (Ghana)',
      internetbankingtanzania: 'Internet Banking (Tanzania)',
      internetbankingbrazil: 'Internet Banking (Brazil)',
      internetbankingjapan: 'Internet Banking (Japan)',
      internetbankinguae: 'Internet Banking (UAE)',
      internetbankingtaiwan: 'Internet Banking (Taiwan)',
      ewallet: 'E-wallet',
      internetbankingcanada: 'Interac® eTransfer',
      internetbankingcanadatm: '®Trade-mark of Interac Corp. Used under license',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Vietnam Bank Transfer',
      nigeriabanktransfer: 'Nigeria Bank Transfer',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: 'Bank Wire Transfer (Australia)',
      thailandinstantbankwiretransfer: 'Thailand Instant Bank Wire Transfer',
      malaysiainstantbankwiretransfer: 'Malaysia Instant Bank Transfer',
      banktransferbpaypolipay: 'Bank Transfer/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      cryptocurrencyethcps: 'Cryptocurrency-ETH',
      cryptocurrencyusdccps: 'Cryptocurrency-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: 'Nigeria Bank Transfer',
      vietnaminstantbankwiretransfer: 'Vietnamese Instant Bank Wire Transfer',
      indonesiainstantbankwiretransfer: 'Indonesia Instant Bank Transfer',
      philippinesinstantbankwiretransfer: 'Philippine instant Bank wire transfer',
      southafricainstantbankwiretransfer: 'South Africa instant Bank wire transfer',
      indiainstantbanktransfer: 'India Instant Bank Wire Transfer',
      southkoreabanktransfer: 'South Korea instant Bank wire transfer',
      ugandabanktransfer: 'Uganda Local Bank Transfer',
      rwandabanktransfer: 'Rwanda Local Bank Transfer',
      zambiabanktransfer: 'Zambia Local Bank Transfer',
      congobanktransfer: 'Congo Local Bank Transfer',
      cameroonbanktransfer: 'Cameroon Local Bank Transfer',
      burundibanktransfer: 'Burundi Local Bank Transfer',
      kenyabanktransfer: 'Kenya Local Bank Transfer',
      ghanabanktransfer: 'Ghana Local Bank Transfer',
      tanzaniabanktransfer: 'Tanzania Local Bank Transfer',
      brazilbanktransfer: 'Brazil Bank Transfer',
      banktransferbpaypolipayaustralia: 'Bank Transfer/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Bank Transfer/BPay/PoliPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Canadian Local Bank Transfer',
      cryptobitcoin: 'Crypto-Bitcoin',
      cryptotetheromni: 'Crypto-Tether(OMNI)',
      cryptotethererc20: 'Crypto-Tether(ERC20)',
      cryptotethertrc20: 'Crypto-Tether(TRC20)',
      astropay: 'Astropay',
      sticpay: 'SticPay',
      vload: 'Vload',
      perfectmoney: 'Perfect Money',
      transfer: 'Transfer to accounts',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      LOCAL_TRANSFER: 'LOCAL TRANSFER',
      CPS: 'CPS',
      ebuy: 'EBuy',
      pix: 'PIX',
      japanbanktransfer: 'Japan Bank Transfer',
      indiabanktransfer: 'India Bank Transfer',
      interac: 'Interac',
      internetbankingmexico: 'Internet Banking (Mexico)',
      internetbankingeu: 'Internet Banking (EU)',
    },
    country: { international: 'International', australia: 'Australia' },
    field: {
      country_region: 'Country/Region',
      address: 'Address',
      phone: 'Phone Number',
      nat: 'Nationality',
      country: 'Country of residency',
      save: 'Investment',
      annIncome: 'Annual Income',
      emailAdd: 'Email Address',
      yes: 'Yes',
      no: 'No',
      enterVerificationCode: 'Enter Verification Code',
      bankName: 'Bank Name',
      bankAddress: 'Bank Address',
      branchAddress: 'Branch Address',
      bankBranch: 'Bank Branch',
      bankCity: 'Bank City',
      bankProvince: 'Bank Province',
      bankBeneficiaryName: 'Bank Beneficiary Name',
      bankBeneAccName: 'Bank Beneficiary Account Name',
      bankBeneAccNum: 'Bank Beneficiary Account Number',
      bankBeneAddress: 'Beneficiary Address',
      bankAccNum: 'Bank Account Number',
      bankAccName: 'Bank Account Name',
      imptNotes: 'Important notes',
      select: 'Select',
      accDigit: 'Account Digit',
      docID: 'Document ID',
      ebuyAccName: 'EBuy Account Name',
      ebuyEmailAddress: 'EBuy Email Address',
      skrillAccName: 'Skrill Account Name',
      skrillEmailAddress: 'Skrill Email Address',
      netellerAccName: 'Neteller Account Name',
      netellerEmailAddress: 'Neteller Email Address',
      paymentAccName: '{name} Account Name',
      paymentAccNum: '{name} Account Number',
      documentType: 'Document Type',
      bankBranchCode: 'Branch Code',
      accType: 'Account Type',
      accName: 'Account Name',
      accNum: 'Account Number',
      bankAccNumKate: 'Bank Account Name in Katakana',
      swiftCode: 'SWIFT code',
      bankHolderAddress: 'Account Holder’s Address',
    },
    keys: {
      REGISTRATION: 'REGISTRATION DATE',
      FTD: 'FTD DATE',
      CURRENTCAMPAIGN: 'CURRENT CAMPAIGN',
      blacklisted: 'Blacklisted',
      FIRST_TRANS_IN: 'FIRST TRANS IN DATE',
      SUBMIT: 'SUBMIT',
      FROM: 'FROM',
      UPDATE: 'UPDATE',
      TO: 'TO ',
      DATE: 'OPENING DATE',
      pleaseSelect: 'Please Select',
      amount: 'Amount',
      amountIn: 'Amount in',
      rebateAccount: 'Rebate Account',
      comingSoon: 'coming soon',
      tradingHistory: 'TRADING HISTORY',
      noData: 'No data',
      NAME: 'NAME',
      EMAIL: 'EMAIL',
      ACCTYPE: 'ACCOUNT TYPE',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'BASE CURRENCY',
      DATERANGE: 'DATE RANGE',
      STATUS: 'STATUS',
      DEST: 'DESTINATION',
      ACCNUM: 'ACCOUNT NUMBER',
      BALANCE: 'BALANCE',
      PROFITLOST: 'PROFIT/LOSS',
      MARGINLVL: 'MARGIN LEVEL',
      ACCEQUITY: 'ACCOUNT EQUITY',
      CREDIT: 'CREDIT',
      NONE: 'None',
      ALL: 'All',
      actualAmonut: 'Actual Amonut',
      currency: 'Currency',
      method: 'Method',
      createTime: 'Submission Time',
      lastUpdateTime: 'Processed Time',
    },
    dateRange: {
      today: 'Today',
      yesterday: 'Yesterday',
      lastSevenDays: 'Last 7 Days',
      lastThirtyDays: 'Last 30 Days',
      weekToDate: 'Week To Date',
      monthToDate: 'Month To Date',
      quarterToDate: 'Quarter To Date',
      previousWeek: 'Previous Week',
      previousMonth: 'Previous Month',
      previousQuarter: 'Previous Quarter',
    },
    products: {
      forex: 'FOREX',
      commodities: 'COMMODITIES',
      indices: 'INDICES',
      crypto: 'CRYPTO',
      sharecfd: 'Share CFD',
      bond: 'Bond',
      pamm: 'PAMM',
    },
    formValidation: {
      dynamicReq: '{dynamic} is required',
      verCodeReq: 'Verification code is required',
      alphanumericReq: 'Please enter alphanumeric',
      common: 'This field is required.',
      answerReq: 'Please select',
      sameAsPOA: 'Entry needs to be same as POA',
      sameAsID: 'Entry needs to be same as ID',
      notBlank: 'To be left blank if not applicable',
      digits12WithoutDash: 'Please enter 12 digits, without "-"',
      enterAsPerId: 'Enter as per ID',
      numberReq: 'Please enter number',
      addressReq: 'Please enter your address',
      suburbReq: 'Please enter your city or suburb',
      stateReq: 'State or province is required',
      postcodeReq: 'Please enter your postcode',
      countryReq: 'Please select your country of residency',
      AlphanumericOnlyNotSpace: 'Alphanumeric only. Space is not allowed.',
      AlphanumericOnly: 'Alphanumeric only.',
      NumbersSymbol: 'Numbers and symbol only.',
      NumbersOnlyNotSpace: 'Numbers only. Space is not allowed.',
      NumbersOnly: 'Numbers only.',
      LettersSymbol: 'Letters and symbol only.',
      LettersSymbolNotSpace: 'Letters and symbol only. Space is not allowed.',
      LettersOnlyNotSpace: 'Letters only. Space is not allowed.',
      NotSpace: 'Space is not allowed.',
    },
  },
  report: {
    title: { rebateReport: 'REBATE REPORT' },
    rebate: {
      column: {
        name: 'NAME',
        accountNumber: 'ACCOUNT NUMBER',
        accountType: 'ACCOUNT TYPE',
        volume: 'Volume',
        rebate: 'Rebate',
        totalRebate: 'TOTAL REBATE',
        crypto: 'Crypto',
        sharecfd: 'Share CFD',
        date: 'DATE',
        instrument: 'INSTRUMENT',
      },
      dropdown: { allInstruments: 'All Instruments', all: 'All', instruments: 'Instruments' },
      others: {
        rebate: 'REBATE',
        instrumentsTraded: 'INSTRUMENTS TRADED',
        lots: 'Lots',
        totalRebate: 'Total Rebate',
        rebates: 'Rebates',
      },
    },
  },
  ibReport: {
    header: 'IB REPORT',
    netFunding: 'NET FUNDING',
    deposits: 'DEPOSITS',
    withdraw: 'WITHDRAW',
    openedAccs: 'OPENED ACCOUNTS',
    funding: 'FUNDING',
    withdrawals: 'WITHDRAWALS',
    appliedAmount: 'Applied Amount',
    depositAmount: 'Deposit Amount',
    withdrawalAmount: 'Withdrawal Amount',
    defaultTimeMessage: 'Maximum query range for download is 90 days',
    timeLimitMessage:
      'Maximum query range for download is 90 days,please update the new date range',
    successInfo: 'Download successful',
    download: 'DOWNLOAD',
    comfirm: 'Comfirm',
  },
  lead: { header: 'LEADS', ibAccount: 'IB Account', demoAccounts: 'DEMO ACCOUNTS' },
  pendingAccounts: {
    header: 'PENDING ACCOUNTS',
    errorDoc:
      'The following applicants have filled in the online application form but are yet to supply proof of identification (ID) in order to finalise their account.',
    acceptedDocType: 'Accepted forms of ID include:',
    primaryDoc: 'Primary ID',
    passport: 'A copy of your valid international passport (signature page)',
    id: 'A copy of your government issued national identity card',
    dl: 'A copy of your valid driver’s licence',
    address: 'Secondary ID',
    addressList: [
      'Copy of a recent Utility Bill (no older than 6 months)',
      'Copy of a credit card statement (no older than 6 months)',
      'Copy of a bank statement (no older than 6 months)',
    ],
    tip: 'Please encourage applicants to provide a copy of ONE of the accepted primary and secondary ID documents to ',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: 'TRANSACTION HISTORY',
    rebateHistory: 'REBATES',
    withdrawHistory: 'WITHDRAWALS',
    transferHistory: 'TRANSFERS',
    cancelWithdraw: 'Would you like to Cancel the Withdrawal Request?',
    cancelError:
      'Your withdrawal is being processed at the moment. Therefore, we cannot accept your cancellation request. For more information, you may reach us using the live chat feature.',
  },
  fileUploader: {
    supportedFormat: 'Supported file types: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Supported file types: {files}',
    maxSize: 'Maximum upload file size: {maxSize}MB',
    maxFileNum: 'Maximum file number: {maxFileNum}',
    selectFile: 'Select File',
    maxFileSize: 'The uploaded file size cannot exceed {maxSize}MB',
    sureToDelete: {
      title: 'Are you sure',
      content: 'Are you sure you want to delete the uploaded file？',
    },
  },
  transferHistory: {
    payStatus: { processing: 'Processing', paid: 'Paid', unpaid: 'Unpaid', rejected: 'Rejected' },
  },
  referralLinks: {
    header: 'REFERRAL LINKS',
    ibAcc: 'IB Account',
    campLang: 'Campaign Language',
    cpy: 'COPY',
    alreadyCPY: 'COPIED!',
    liveAcc: 'Live Account',
    demoAcc: 'Demo Account',
    homePage: 'Home Page',
    Share: 'Share',
    subIbReferral: 'Sub IB Referral',
  },
  ibProfile: {
    title: 'IB Profile',
    subTitle: 'Shared IB',
    npPermissionTips: 'No permission for this account',
    sharedIbList: {
      sharedIbAccount: 'Shared IB account',
      maxSharedIbAccount: 'Maximum 10 Shared IB accounts',
      add: 'Add',
      accountHistory: 'Shared IB account history',
      accountHistoryBtn: 'Shared account history',
      setting: 'Setting',
      delete: 'Delete',
      enable: 'Enable',
      disable: 'Disable',
      enabled: 'Enabled',
      disabled: 'Disabled',
      sharedAccountEnabled: 'Shared IB account has been enabled.',
      sharedAccountDisabled: 'Shared IB account has been disabled.',
      sharedAccountCopied: 'Shared IB account copied',
      deleteTips:
        'The Shared IB account will be unable to log in after deletion, do you want to delete it?',
      sharedAccountDeleted: 'Shared IB account has been deleted.',
      copyTips: 'Shared IB account copied',
      accountData: 'Accounts data',
      IBData: 'IB report data',
      fundOperations: 'Funds permission',
      resetPassword: 'Reset password',
      remark: 'Remark',
      cancel: 'Cancel',
    },
    updateSharedIb: {
      addNewSharedIb: 'Add new Shared IB account',
      editSharedIb: 'Edit Shared IB account',
      addSuccess: 'Shared account created',
      maxSharedIbAccountError: '10 accounts exist, unable to add.',
      fundsChangeTips:
        'After funds permission is allowed, the Shared IB account can transfer and withdraw the balance of your main IB account. Do you want to allow this?',
      permissionIdsTips: 'You can allow the following permissions for shared accounts or not:',
      permissionIdsFirst:
        '<b>Accounts data</b>: View account data pages (IB Accounts, Recently Opened Accounts). The data will be hidden if permission is not allowed.',
      permissionIdsSecond:
        '<b>IB report data</b>: View IB data pages (Dashboard, Multi-Level IB - Account). The data will be hidden if permission is not allowed.',
      permissionIdsThird:
        '<b>Funds permission</b>: Operate IB funds (Apply For Rebate, Transfer Rebate, Withdraw Rebate), View rebate (Rebate report, Balance, and Funds records). Operations cannot be performed if permission is not allowed.',
      email: 'Email Address',
      emailPlaceholder: 'Please enter email',
      emailValidate: 'Please enter valid email address',
      emailError: 'Email exist, please try another one',
      password: 'Password',
      passwordValidate: 'Please enter password',
      newPassword: 'New password',
      newPassowrdReset: 'Please enter new password to reset',
      passwordValidateNum: '8-16 characters',
      passwordValidateTips:
        'Mix of uppercase and lowercase letters (A-Z, a-z), numbers (0-9) and special characters (e.g.: !@#S%^&)',
      passwordValidateWarning:
        '8-16 characters, Mix of uppercase and lowercase letters (A-Z, a-z), numbers (0-9) and special characters (e.g.: !@#S%^&)',
      passwordSame: 'The new password cannot be the same as the old one',
      sharedIbAccountPermission: 'Shared IB Account Permission',
      remark: 'Remark',
      remarkTips: 'You may record account purpose or account holder',
      status: 'Status',
      cancel: 'Cancel',
      confirm: 'Confirm',
      disabled: 'Disabled',
      enabled: 'Enabled',
      changePasswordTips: 'The old password will be invalid and reset to the new password',
      resetPassword: 'Reset Password',
      resetPasswordSuccess: 'Password reset successful',
      enterNewPassword: 'Please enter new password',
      editSuccess: 'Modified successfully',
    },
    sharedIbHistory: {
      viewMore: 'View More',
      allHistory: 'All history has been shown',
      lastUpdate: 'Last updated',
      refreshTips: 'History updated',
      refresh: 'Refresh',
      chooseAccount: 'Choose Account',
      device: 'Device',
      login: 'Log in',
      commission: 'Apply Rebate',
      withdraw: 'Withdraw Rebate',
      fromTo: 'From {fromAccount} to {toAccount}, amount {total}',
      transfer: 'Transfer Rebate',
    },
  },
  settings: { ibProfile: 'IB PROFILE', info: 'INFO', reg: 'REGISTERED ON' },
  myProfile: {
    changeDetails:
      'If you wish to update your existing profile details, do not hesitate to contact {mailTo}',
  },
  unfundedAcc: {
    header: 'UNFUNDED ACCOUNTS',
    allActivationAccs: 'ALL ACTIVATION ACCOUNTS',
    neverFundedAccs: 'NEVER FUNDED',
    prevFundedZero: 'ZERO BALANCE',
  },
  multiib: { level: 'LEVEL {level}', noib: 'There are no sub-IBs under this account.' },
  sessionDialog: {
    title: 'Your session is about to expire!',
    content: 'You will be logged out in 60 seconds. Do you want to stay signed in?',
    keepIn: 'Yes, Keep me signed in',
    signOut: 'No, Sign me out',
  },
  header: { bckToCP: 'BACK TO CLIENT PORTAL', logout: 'LOG OUT' },
  menu: {
    dashboard: 'DASHBOARD',
    ibReport: 'IB REPORT',
    rebateReport: 'REBATE REPORT',
    accManagement: 'ACCOUNTS',
    iblevel: 'MULTI-LEVEL',
    ibAccs: 'ACTIVATED ACCOUNTS',
    leads: 'LEADS',
    pendingAccs: 'PENDING ACCOUNTS',
    unfundedAccs: 'UNFUNDED ACCOUNTS',
    payHistory: 'TRANSACTION HISTORY',
    ibProfile: 'IB PROFILE',
    refLinks: 'REFERRAL LINKS',
    contactUs: 'CONTACT US',
    switchCP: 'SWITCH TO CLIENT PORTAL',
  },
  register: {
    progressStatus: {
      title: 'Verification progress',
      estimatedTimeTitle: 'estimated time',
      min: 'minutes',
    },
    tabs: {
      personalDetails: 'Personal Details',
      moreAboutYou: 'More About You',
      accountConfig: 'Account Configuration',
      confirmYourId: 'Confirm Your ID',
      fundYourAcc: 'Fund your Account',
      personalInformation: 'Personal Information',
      createLiveAccount: 'Create Live Account',
      financialInfo: 'Financial Info',
      iDProof: 'ID Proof',
      addressProof: 'Address Proof',
    },
    btn: {
      next: 'NEXT',
      back: 'BACK',
      fundnow: 'FUND NOW',
      upload: 'Upload',
      done: 'DONE',
      bTCP: 'Back to Client Portal',
      proceedToUpload: 'Proceed to Upload',
      completeID: 'Complete ID Verification',
      completePOA: 'Complete POA Verification',
      depositViaOther: 'Deposit Via Other Channel',
      withdrawViaOther: 'Withdraw via other channel',
    },
    authority: {
      dialog1: 'You must have a live account to access this section of the client portal.',
      dialog2:
        'Open a live account now to unlock full access to your client portal, which includes a range of trading tools, promotions and much more.',
      openLiveAcc: 'open live account',
    },
    demo: {
      congrad: 'Congratulations!',
      title: 'Your 60 Day DEMO MT{platform} account is now active',
      title2: 'Your DEMO Account Details',
      li: { login: 'Login', srv: 'Server', expDate: 'Expiry Date' },
      emailSent: 'Login details have also been sent to your registered email.',
      dl: 'Download your MT{platform} Trading Platform',
      improveQ: 'Do you want to improve your trading？',
      improveWay:
        'Simply, open a live account in just minutes to unlock a range of exclusive trading tools and promotions.',
      proTrade: 'Professional marketing reports',
      customerService: '24/7 One-on-one customer service',
      competitivePromotionals: 'Competitive promotionals',
      secure: '3D secure transactions',
      openLiveAcc: 'open live account',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Personal Details',
        userTitle: 'Title',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'E-mail',
        dob: 'Date Of Birth',
        idType: 'Identification Type',
        referral: 'Have you been referred by anyone？  (optional)',
        middleName: 'Middle Name',
        nationality: 'Nationality',
        phoneNum: 'Phone Number',
        date: 'Day',
        month: 'Month',
        year: 'Year',
        placeOfBirth: 'Place Of Birth',
        idNum: 'Identification Number',
        passportNum: 'Passport Number',
        driverLicenseNum: 'Driver License Number',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Passport',
          idCard: 'National ID Card',
          driverLicense: 'Driver License',
        },
      },
      page2: {
        pageTitle: 'MAIN RESIDENTIAL ADDRESS',
        mainResidency: 'Country of main residency',
        streetNumber: 'Street Number',
        streetName: 'Street Name',
        address: 'Address',
        provinceOrState: 'Province / State',
        cityOrSuburb: 'City / Suburb',
        postcode: 'Postcode',
        usQuestion:
          'Are you a US Citizen/Resident for tax purposes or do you have any US links/ties(i.e. Bank account, etc.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLOYMENT AND FINANCIAL DETAILS',
      pageTitle2: 'Trading Knowledge and Experience',
      complianceExplain:
        'The following questions are collected for compliance with AML/CTF requirements.',
    },
    accountConfig: {
      pageTitle: 'Account Configuration',
      chooseTradingPlatform: 'Choose Trading Platform',
      chooseAccType: 'Choose Account Type',
      chooseAccCurrency: 'Choose Account Currency',
    },
    confirmYourId: {
      pageTitle: 'Confirm Your ID',
      description:
        'As a regulated forex broker, we are required to verify your identity. Please upload one of the following documents for your Proof of ID and Proof of Address verification. Alternatively, you may email your verification documents to {mailTo}',
      poidTitle: 'Proof of Identity',
      porTitle: 'Proof of Residency',
      uploadDescription: 'Choose a document type and upload accordingly',
      uploadTip:
        'Please Upload Both Front and Back of Your Photo ID. (Click the blue tick to continue uploading)',
      note: 'IMPORTANT: Name on document MUST clearly show your full name.',
      pending: { upload: 'Pending Upload', verification: 'Pending Verification' },
      poidTypes: {
        photoId: 'Front and Back of Photo ID',
        passport: 'Passport',
        drivingLicence: 'Driving Licence',
      },
      porTypes:
        '\n        <li>Utility Bill</li>\n        <li>Bank Statement</li>\n        <li>Residence Certificate</li>\n      ',
    },
    finishPage: {
      title: '{individualUserName}, thank you for applying for a {platformName} live account!',
      contentDefault:
        "We have received your application and set up your live account <b>{account}</b>. <br />\n      Shortly, you will receive an email at <b>{email}</b> containing your login details and a link to view your account's status. <br />",
      contentWithoutId3WithAccount:
        'Your live trading account is currently being processed and you will receive an email containing further information shortly.<br />\n      To fast track your live account processing, you can fund your account now and receive priority opening.<br />',
      contentWithoutId3WithoutAccount:
        'Your live trading account is currently being processed and you will receive an email containing further information shortly.<br />',
    },
    accOpeningVerification: {
      accOpenVer: 'Account Opening Verification',
      personalInformation: {
        narrative: 'Your personal information is required to open your Live Account',
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        gender: 'Gender',
        genderList: { male: 'Male', female: 'Female', other: 'Other' },
        dob: 'Date Of Birth',
        day: 'Day',
        month: 'Month',
        year: 'Year',
        mobile: 'Mobile Number',
        countryName: 'Country of Residence',
        nationality: 'Nationality',
      },
      createLiveAccount: {
        narrative:
          'Complete a few steps to open an account and start funding. More info on our account types <a href="{link}" target="_blank" alt>here</a>.',
        choose: 'Choose {dynamic}',
        tradingPlatform: 'Trading Platform',
        accountType: 'Account Type',
        accountCurrency: 'Account Currency',
        islamicTooltip:
          '{platform} may require you to provide supporting document(s) to verify that you are of the Islamic faith. {platform} reserves the right to amend your account configuration if verification fails.',
        successInfo: {
          title: 'Trading Account Created',
          content:
            'Congratulations! You have successfully completed account opening. <br /> To start trading, make a deposit now and complete your ID verification.',
        },
        successInfo2: {
          title: 'Trading Account Created',
          content:
            'Fill in your financial profile information to begin the next step in the verification of your account',
          start: 'Start',
        },
        generalInfo: {
          title: 'Your Submission is Under Review.',
          content:
            'Thank you for opening an account with {platform}. You can begin funding your account once your submission is approved.',
        },
        rejectedInfo: {
          title: 'Rejected',
          content:
            'We regret to inform you that your request to open a {platform} account has been rejected.',
        },
      },
    },
    identityVerification: {
      title: 'Identity Verification',
      subTitle: 'Identity Information',
      narrative: 'Fill in the details',
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      nationality: 'Nationality',
      idType: 'ID Type',
      idSerialNumber: 'ID Serial Number',
      passportNum: 'Passport Number',
      driverLicenseNum: 'Driver License Number',
      ID: 'ID',
      upload: {
        title: 'Upload Document',
        desc: 'Make sure the document shows your photo, full name, date of birth, and date of issue.',
        guideline: 'Upload Guidelines',
        positive: 'Upload Positive',
        signaturePage: 'Upload Signature page',
      },
      idTypeDropdown: {
        passport: 'Passport',
        idCard: 'National ID Card',
        driverLicense: 'Driver License',
        other: 'Other',
      },
      additionalInfo: {
        title: 'Additional Info Required',
        content: 'Verify your identity to start trading',
      },
      successInfo: {
        title: 'ID Verification Complete',
        content:
          'Congratulations! Your ID has been verified. You can now start trading on MT4/MT5 or the {platform} app if you have already made a deposit.',
      },
      generalInfo: {
        title: 'Your Submission is Under Review.',
        content:
          'Thank you for opening an account with {platform}. You can begin trading once your identity information is verified.',
      },
      rejectedInfo: {
        title: 'Rejected',
        content:
          'We regret to inform you that your request to open a {platform} account has been rejected.',
      },
      identifyingRequiredInfo: {
        title: 'Additional Info Required',
        content:
          'Some of your identifying documents might be missing or incorrect. Please re-upload them to proceed.',
      },
      streetNumber: 'Street number',
      streetName: 'Street name',
    },
    poaVerification: {
      proofOfAddressVer: 'Proof of Address Verification',
      subTitle: 'Address Information',
      narrative: 'Fill in the details',
      dueToAML:
        'Due to AML requirements, your address must be verified before you can make a withdrawal.',
      nationality: 'Nationality',
      country: 'Country Of Residence',
      address: 'Address',
      postalCode: 'Postal Code',
      citySuburb: 'City/Suburb',
      city: 'City',
      provinceState: 'Province/State',
      upload: {
        title: 'POA Certificate',
        inst1: 'Utility bill (electricity, water, gas)',
        inst2: 'Bank or credit card statements',
        desc: 'POA information for withdrawals must be issued within the past {months} months',
        guideline: 'Upload Guidelines',
        uploadFilesPhoto: 'Upload Files/Photo',
      },
      additionalInfo: {
        title: 'Additional Info Required',
        content: 'POA information needs to be verified before you can make a withdrawal.',
      },
      successInfo: {
        title: 'Proof of Address Verified',
        content: 'Congratulations! Your POA has been verified. You can now make withdrawals.',
      },
      generalInfo: {
        title: 'Your Submission is Under Review.',
        content:
          'Thank you for trading with {platform}. You can begin the withdrawal process once your submission is approved.',
      },
      identifyingRequiredInfo: {
        title: 'Additional Info Required',
        content:
          'Some of your identifying documents might be missing or incorrect. Please re-upload them to proceed.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: 'Your Financial Information',
        subTitle: 'Employment and Financial Details',
        subTitle2: 'Trading Knowledge and Experience',
        employmentStatus: 'Employment Status',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '{title} Per Week',
        estimatedAnnualIncome: 'Estimated Annual Income',
        estimatedSavingsAndInvestments: 'Estimated Savings and Investments',
        estimatedIntendedDeposit: 'Estimated Intended Deposit',
        sourceOfFunds: 'Source of Funds',
        numberOfTrades: 'Number of Trades',
        tradingAmount: 'Trading Amount',
      },
      iDProof: {
        ver: 'Upload Proof of Identity',
        subTitle: 'Identity Information',
        narrative: 'Fill in the details',
      },
      addressProof: {
        ver: 'Upload Proof of Address (POA)',
        subTitle: 'Address Information',
        narrative: 'Fill in the details',
        upload: {
          title: 'Accepted Documents for Proof of Address',
          inst1: 'Utility bill (electricity, water, gas)',
          inst2: 'Bank or credit card statements',
          desc: 'POA information for bank authentication must be issued within the past {months} months',
          guideline: 'Upload Guidelines',
          uploadFilesPhoto: 'Upload Files/Photo',
        },
      },
      successInfo: {
        title: 'Bank Authentication Complete',
        content: 'Congratulations! You have successfully verified your banking details.',
      },
      generalInfo: {
        title: 'Your Submission is Under Review.',
        content:
          'Your documents for bank transfer are still under review. You can still make a deposit via another channel should you wish to start trading now.',
      },
      rejectedInfo: {
        title: 'Rejected',
        content: 'Your request to make a deposit via bank transfer has been rejected.',
      },
      identifyingRequiredInfo: {
        title: 'Additional Info Required',
        content:
          'Some of your identifying documents might be missing or incorrect. Please re-upload them to proceed.',
      },
    },
  },
  footer: {
    riskWarn:
      'Risk Warning: Contracts for Difference (CFDs) trading carries a high level of risk to your capital and can result in losses, you should only trade with money you can afford to lose. CFDs trading may not be suitable for all investors, please ensure that you fully understand the risks involved and take appropriate measures to manage it. Please read the relevant Risk Disclosure document carefully.',
  },
  responseMsg: {
    410: 'Parameter validation failed',
    411: 'Incorrect password',
    420: 'User does not exist',
    421: 'Password reset denied',
    431: 'Rebate calculation is in progress, kindly try your request again in a while',
    490: 'Please do not submit duplicate card information',
    500: 'An error has occured.',
    501: 'Application failed. Please try again later.',
    505: 'File upload failed',
    520: 'Login Failed. Please try again.',
    521: 'A user is already logged in on this device!',
    522: 'Please log in again',
    523: 'Please log in again',
    524: 'Unfortunately your email address was not recognized',
    525: 'Incorrect username or password',
    527: 'User is not an IB',
    528: 'User does not exist',
    529: 'Incorrect username or password',
    530: 'Login token invalid',
    540: "Your trading account couldn't be found",
    541: 'Cannot find rebate account',
    542: 'You are not allowed to open additional account',
    544: 'You are not allowed to apply for an additional account until your Proof of ID verified',
    550: 'Incorrect account number',
    551: 'Your balance is insufficient',
    553: 'You cannot withdraw/transfer due to the credit in your account. Please contact {email} for further help',
    554: 'Requested amount is zero or null',
    562: 'We were unable to process your payment, please try again. If the problem persists, please contact us via live chat, or email {email}',
    564: 'Country does not match the payment channel',
    565: 'Incorrect Bank Code',
    566: 'Payment method not found',
    581: 'You have successfully opted in to the promotion. Please note that you must make a deposit in order to withdraw funds.',
    582: 'Since you have entered the campaign and have open positions, we are unable to process your withdrawal request.',
    584: "You don't have any applicable rebates",
    590: 'Cannot find transfer-out account',
    591: 'Cannot find transfer-in account',
    593: 'We are unable to process your funds transfer from the account selected due to your account containing credit.',
    594: 'Transfer of funds from this account is not allowed',
    595: 'Since you have entered our campaign, we are unable to process your transfer request',
    647: 'Invalid verification code',
    1101: '10 accounts exist, unable to add.',
    1102: 'Unable to enable, the account has been created as a live or demo account.',
    1103: 'Account has been disabled',
    1202: 'Verification expired, please try again',
    1204: 'Please try with another email address',
    1208: 'Verification codes cannot be sent repeatedly',
    1209: 'Send verification code error',
    session_timeout: 'Session timeout, redirecting to login page.',
  },
  verificationStatus: {
    title: { default: 'You’re Almost Done', LV3: 'Welcome' },
    dueToAML:
      'You’re ready to start trading soon! Help us safeguard your account by verifying your personal information.',
    accOpeningVerification: {
      title: 'Create Live Account',
      content: 'Create a Live Account to start funding your account.',
    },
    identityVerification: {
      title: 'Identity Verification',
      content: 'Submit and verify your proof of identity to start trading.',
    },
    poaVerification: {
      title: 'Address Verification',
      content: 'POA needs to be verified before you can make a withdrawal.',
    },
    kyc: {
      depositNow: 'Deposit Now',
      withdrawNow: 'Withdraw Now',
      createNow: 'Create Now',
      start: 'Start',
      complete: 'Completed',
      pending: 'Pending',
      rejected: 'Rejected',
      resubmit: 'Resubmit',
      downloadMetaTrader: 'Download MetaTrader',
    },
    resubmitNote: 'Your documents might be missing or incorrect. Please resubmit.',
    rejectedNote: 'Questions?  Please contact us via {liveChat} .',
    liveChat: 'Live Chat',
    uploadTemplate: {
      idGuideTitle: 'ID Document Guidelines',
      uploadGuideTitle: 'Upload Guidelines',
      desc1: 'Government-issued',
      desc2: 'Original, full-size, unedited documents',
      desc3: 'Place documents against a single-coloured background',
      desc4: 'Readable, well-lit, and clear colour image',
      desc5: 'No black and white images',
      desc6: 'No edited or expired documents',
      bottomDesc: 'The uploaded file can be jpg, png, or pdf, and the file size cannot exceed 5MB',
    },
  },
  agreement: {
    title: 'Introducing Broker Agreement Confirming',
    rebateAccount: 'Rebate account',
    description:
      'By clicking Agree you confirm that you have read, understood, and agree with all the information, service terms and conditions in the agreement',
    agree: 'Agree',
    signed: 'Rebate account {accountID}. Signed at {signedTime}',
    IBAgreement: 'IB Agreement',
    confirmAgreement: 'Please contact the IB Manager to confirm the IB agreement',
  },
};
