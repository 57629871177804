export default {
  en: 'English',
  zh: '中文',
  home: {
    NewPortal: '새로운 포털 사이트',
    SecureIBPortal: 'IB 포털 사이트',
    DASHBOARD: '대시보드',
    MARKETING_TOOLS: '마케팅 도구',
    IB_REPORT: 'IB 보고서',
    REBATE_REPORT: '리베이트 보고서',
    IB_ACCOUNTS: 'IB 계좌',
    PAYMENT_HISTORY: '거래내역',
    IB_FAQ: 'IB 관련 자주 묻는 질문',
    RebateAccountNumber: '리베이트 계좌번호',
    totalBalance: '총 금액',
    totalCommission: '커미션 합계',
    APPLY_FOR_A_REBATE: '대다',
    availableBalance: '사용 가능한 잔액',
    WITHDRAW_REBATE: '빼다',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '이전',
    MonthtoDate: '당월합계',
    UPDATE: '업데이트',
    REBATE: '리베이트',
    TOTAL_VOLUME: '총 볼륨',
    NET_FUNDING: '순 입금',
    DEPOSITS: '입금',
    WITHDRAW: '인출',
    OPEND_ACCOUNT: '개설 완료 계좌',
    PERFORMANCE: '실적',
    OpendAccount: '개설 완료 계좌',
    select_ib: 'IB 계정을 선택하십시오',
    MOST_TRADED_INSTRUMENTS: '가장 많이 거래된 상품',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '이달의 우수 실적 계좌',
    RECENTLY_OPENED_ACCOUNTS: '최근 개설 계좌',
    ACCOUNT_MANAGER: '고객지원',
    Account: '금액',
    Name: '성명',
    Volume: '볼륨',
    Rebate: '리베이트',
    Balance: '잔액',
    ContactClient: '연락하기',
    YouCannotAskForCommission: '커미션 신청에 실패하였습니다.',
    applicationFailedPleaseTryAgain: '요청 실패입니다. 잠시 후 다시 진행해주십시오.',
    applyRebateSuccessTip:
      '귀하의 리베이트 신청이 접수되었습니다.  | {amount} 은 영업일 1일 이내에 리베이트 계좌 {account}로 이체될 예정입니다. | 리베이트 금액이 계좌로 반영 완료 후, 출금 또는 거래 계좌로 이체가 가능합니다. ',
    HOVER:
      'IB 커미션은 일일 정산을 원칙으로 하고 있으며, 전 날의 커미션은 *호주 동부 표준시 기준 매일 09:00 – 11:00 에 정산되어 표기됩니다. ',
    blacklist: '이 계정은 현재 환불을 받을 수 없습니다. 계정 관리자에게 문의하십시오.',
    ACCOUNTS_PROFIT:
      '이 IB 계정을 포함하는 모든 거래 계정의 과거 총 이익/손실입니다. 로드하는 데 잠시 시간이 걸릴 수 있습니다.',
    PROFIT: '계좌 손익',
    reload: '다시 불러오기 ',
  },
  liveChat: { header: '실시간 채팅' },
  contactUs: {
    header: '연락하기',
    followUs: '우리를 따르라',
    tip: '전문가로 구성된 {platform}의 글로벌 고객지원팀이 고객님께 실시간 고객지원 서비스를 제공합니다.',
    chatNow: '지금 채팅해요',
    globalOffice: '글로벌 오피스',
  },
  ibAccounts: {
    header: 'IB 계좌',
    ibAccount: 'IB 계좌',
    subIbs: 'SUB IB 계좌',
    tradinglist: '거래 목록',
    openlist: '목록 열기',
    closedlist: '비공개 목록',
    tradinglistColumn: {
      ticketid: '티켓 ID',
      opentime: '진입시간',
      type: '종류',
      volume: '거래량',
      item: '상품',
      openprice: '공개 가격',
      closeprice: '종가',
      closetime: '청산시간',
      commission: '수수료',
      profit: '손익',
      buy: '구입',
      sell: '팔다',
    },
  },
  withdraw: {
    default: {
      selectCC: '신용카드 선택',
      selectBA: '은행 계좌 선택',
      anotherCC: '신용카드를 수동으로 입력하세요.',
      anotherBA: '은행 계좌 추가',
      remember: '내 계정 기억',
      verificationCode:
        '{email}에 확인 코드가 전송되었습니다. 추가로 고객 지원이 필요한 경우 {supportEmail}에 문의하십시오',
      fetchTradeAccFailed: '거래 계좌를 불러오는데 실패했습니다.',
      failed: '출금에 실패하였습니다. 잠시 후 다시 시도해주십시오.',
      tokenExpired: '토큰이 만료되었습니다.',
    },
    alert:
      'AML 법률에 따라 인출에 사용하는 방법은 입금에 사용 된 방법과 동일해야합니다. 인출 금액이 계좌 기본 통화의 100 단위 미만인 경우 은행에서 처리 수수료 20 단위를 부과합니다.',
    NetellerChargeAlert:
      'Neteller은 모든 출금에 대해 {percentageFee} %의 거래 수수료를 부과합니다.',
    FasaPayChargeAlert: 'FasaPay는 모든 출금에 대해 0.5 %의 거래 수수료를 부과합니다.',
    SkrillChargeAlert: 'Skrill은 모든 출금에 대해 {percentageFee} %의 거래 수수료를 부과합니다.',
    cryptoAlert:
      'BUSDT를 통해 입금 또는 출금할 수 없습니다. 주소와 암호화폐가 허용되는 체인 및 통화와 일치하는지 확인하세요. 그렇지 않으면 자금을 잃을 수 있습니다.',
    REBATE_WITHDRAW_REQUEST: '리베이트 출금 신청',
    PleaseSelectDesiredWithdrawMethod: '출금 방식을 선택해주십시오',
    BankName: '수취인 거래은행',
    BankAccountsName: '예금주',
    FasaPayAccountName: 'FasaPay 계정이름',
    AustralianBankName: '호주은행명',
    BankAccountNumber: '은행계좌번호',
    FasaPayAccountNumber: 'FasaPay 계정번호',
    BankAccountBeneficiary: '계좌 수령인',
    BSB: 'BSB',
    skrillEmail: 'Skrill 이메일',
    NetellerEmail: 'Neteller 이메일',
    PerfectMoneyEmail: 'Perfect Money 이메일',
    SticpayEmail: 'SticPay 이메일',
    BitcoinEmail: 'Bitcoin 지갑 주소',
    BitwalletEmail: 'Bitwallet 이메일',
    USDTEmail: 'Tether 지갑 주소',
    BankBranch: '은행지점',
    Province: '시/도',
    City: '시/군/구',
    BankAddress: '은행주소',
    bankCity: '시/군/구',
    bankProvince: '시/도',
    AccountNumberIBAN: '계좌번호/IBAN',
    BankBeneficiaryName: '수취인명',
    BankAccountBeneficiaryName: '계좌 수령인',
    AccountHoldersAddress: '예금주 주소',
    SelectWithdrawCard: '출금하고자 하는 카드를 선택 하십시오.',
    Swift: 'Swift코드',
    SwiftAud: 'Swift코드 (호주계좌 제외)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    ImportantNotes: '중요사항',
    YourPaymentGreaterThanAvailableBalance: '입력하신 지불 금액이 잔액을 초과합니다',
    CannotUseMoreThanTwoDecimalValues:
      '소수점 이하 두 자리 숫자와 0이하의 숫자는 입력이 불가합니다',
    YourPaymentIsBeingProcessed: '귀하의 출금신청이 접수되었습니다. ',
    Itcannotbe0: "0'은 입력이 불가합니다.",
    noCardWarn:
      '출금 가능한 카드가 존재하지 않습니다. 출금 세부정보에 카드 정보를 먼저 등록하시기 바랍니다. ',
    amtLarger: '금액은 빈칸으로 제출할 수 없으며 {minLimit} {currency}보다 크거나 같아야 합니다.',
    blackList:
      '죄송합니다. 현재 인출 요청을 제출할 수 없습니다. 자세한 내용은 {supportEmail}에 문의하십시오.',
    status: {
      withdrawalSubmitted: '제출됨',
      withdrawalCanceled: '취소됨',
      withdrawalAuditing: '처리중',
      withdrawalRejected: '거절',
      withdrawalAccepted: '처리중',
      withdrawalPayFailed: '실패',
      withdrawalSuccess: '처리됨',
      withdrawalFailed: '실패',
      withdrawalPending: '대기중',
      withdrawalPartialFailed: '실패',
      transferSubmitted: '처리중',
      transferSuccess: '처리됨',
      transferRejected: '거절',
      transferFailed: '실패',
      transferProcessing: '처리중',
      withdrawalReversedFailed: '실패된 역',
      withdrawalPartialSuccess: '부분적인 성공',
    },
    formErrorMsg: {
      bankName: '은행명을 입력하십시오.',
      bsb: 'BSB를 입력하십시오.',
      beneName: '계좌예금주를 입력하십시오.',
      accNum: '은행계좌번호를 입력하십시오.',
      swift: 'Swift코드',
      bankAddress: '은행주소',
      holderAddress: '예금주 주소',
      amt: '금액을 입력하십시오.',
      firstSixDigits: '앞의 6자리를 입력해주십시오',
      lastFourDigits: '마지막 4자리를 입력해주십시오',
      branchName: '분점명',
      email: '이메일',
      accName: '예금주',
      province: '은행지점명',
      city: '은행주소(City시/구/군)를 입력하십시오.',
      bankCard: '카드를 선택하십시오.',
      emailFormat: '올바른 이메일을 입력해 주십시오.',
      amt0: '0 이상의 금액을 입력하십시오.',
      bitcoin: 'Bitcoin 지갑 주소를 입력해주십시오',
      addressLength: '비트코인 지갑 주소는 26~42자 사이여야 합니다',
      usdt: 'USDT 지갑 주소를 입력해주십시오',
      beneficiaryNameReq: '은행 수취인 이름이 필요합니다',
    },
    transfer: {
      info1:
        '{platform}은 당월 첫 출금 시 은행 수수료를 면제합니다. 동일한 달 내에 추가로 인출할 경우 통화 20단위의 은행 수수료가 발생합니다. (USC/HKD/JPY의 경우 수수료는 20USD에 해당합니다.)',
      info2:
        '은행 수취인 이름은 여권상의 영문명에 따라 정확하게 입력해야 합니다. (약어 금지, 반드시 전체 영문명 입력)',
      info3:
        'IBAN 형식 (한국측 은행계좌번호) 은행계좌번호를 올바르게 입력하십시오. EU 지역 출신 외 국가 고객은 은행 명세서상의 은행 계좌 번호를 정확하게 기입해 주시길 바랍니다.',
      label: {
        upload: '은행 명세서를 업로드해주십시오. (최근 3개월 거래내역 포함)',
        upload2:
          '거래 명세서의 날짜 또는 최종 거래 날짜가 현재 월부터 지난 3개월 사이에 있는지 확인하십시오. 그 기간을 초과하는 경우 명세서를 다시 업로드하십시오.',
      },
      formValidation: { upload: '이체내역서를 업로드해주십시오.' },
      minAmount: '최소 인출 금액은 100 {currency} 입니다',
    },
    transitNumber: '환승번호',
    institutionNumber: '기관번호',
    beneficiaryName: '수취인 이름',
  },
  security: {
    contactSupport: '인증 방법에 방문할 수 없습니까? {contact_email} 또는 Livechat에 문의하세요',
    securityAuthenticator: '보안 인증',
    verificationRequired: '확인 코드를 입력하세요',
    resend: '재전송',
    authenticatorTooltip: 'Authenticator APP 검증 코드는 30초마다 새로 고침됩니다',
    verifyPreEmailNote: '코드가 {email}로 전송됩니다',
    verifyPostEmailNote: '코드가 {email}로 전송되었습니다',
    enableNow: '지금 활성화',
    verificationTitle: '확인이 필요합니다',
    verificationDesc: '진행하기 전에 보안 인증기 앱을 활성화하세요.',
  },
  rebate: { status: { processed: '처리됨', processing: '처리중', rejected: '거절' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: '리베이트 이체 신청',
    transferAmountIsGreaterThanAvailableBalance: '이체금액이 잔액을 초과했습니다',
    yourRebateTransferHasBeenProcessed: '귀하의 리베이트 이체가 접수되었습니다',
    PleaseEnterTransferInformation: '정확한 이체 정보를 입력하십시오',
    tradingAccount: '거래 계좌',
    rebateAccount: '리베이트 계좌',
    account: '금액',
    blackList: '계정 간 이체가 제한된 계정입니다. {supportEmail}로 문의하시기 바랍니다.',
  },
  common: {
    liveChat: { desc: '도움이 필요하신가요?실시간 채팅' },
    lang: {
      english: '영어',
      chinese: '중국어',
      french: '프랑스어',
      thai: '태국어',
      german: '독일어',
      spanish: '스페인어',
      malay: '말레이어',
      viet: '베트남어',
      indo: '인도네시아어',
      arabic: '아랍어',
    },
    button: {
      iKnow: '이해합니다',
      confirmCamelCase: '확인',
      confirm: '확인',
      cancel: '취소',
      submit: '제출하기',
      sendCode: '코드 발송',
      resendCode: '코드 재전송',
      ok: '확인',
    },
    withdrawChannel: {
      limitTitle: '출금이 일시적으로 지원되지 않습니다',
      limitPassword:
        '로그인 비밀번호 변경으로 인해 계좌 자금의 안전을 위해 {day}일 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hour}시간 후에 해제될 예정입니다.',
      limitUserLogin:
        '로그인 이메일/전화번호 변경으로 인해 {day}일 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hour}시간 후에 해제될 예정입니다.',
      limitSecurityAuthenticator:
        '보안 인증기 앱을 수정했기 때문에 계정 보안을 보장하기 위해 {day} 일 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hour} 시간 후에 해제될 예정입니다.',
      banktransfer: '해외송금',
      aus: '호주은행송금',
      int: '국제은행송금',
      chn: '중국은행송금',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: '유니온페이',
      unionpaytransfer: '유니온페이이체',
      fasapay: 'FasaPay',
      creditcard: '신용카드 ',
      bpay: 'Bpay',
      creditdebitcard: '신용/체크 카드',
      bankwiretransferinternational: '해외송금(국제)',
      banktransferaustralia: '은행송금(오스트레일리아)',
      banktransferinternational: '해외송금',
      banktransferchina: '은행송금(국제)',
      mobilepay: '모바일결제',
      brokertobrokertransfer: '브로커 간 이체',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '인터넷뱅킹 (태국)',
      internetbankingnigeria: '인터넷뱅킹 (나이지리아)',
      internetbankingvietnam: '인터넷뱅킹 (베트남)',
      internetbankingmalaysia: '인터넷뱅킹 (말레이시아)',
      internetbankingindonesia: '인터넷뱅킹 (인도네시아)',
      internetbankingkorea: '인터넷뱅킹 (한국)',
      internetbankingphilippine: '인터넷뱅킹 (필리핀 제도)',
      internetbankingsouthafrica: '인터넷뱅킹 (남아프리카)',
      internetbankinguganda: '인터넷뱅킹 (우간다)',
      internetbankingrwanda: '인터넷뱅킹 (르완다)',
      internetbankingzambia: '인터넷뱅킹 (잠비아)',
      internetbankingcongo: '인터넷뱅킹 (콩고)',
      internetbankingcameroon: '인터넷뱅킹 (카메룬)',
      internetbankingburundi: '인터넷뱅킹 (부룬디)',
      internetbankingkenya: '인터넷뱅킹 (케냐)',
      internetbankingghana: '인터넷뱅킹 (가나)',
      internetbankingtanzania: '인터넷뱅킹 (탄자니아)',
      internetbankingcanada: '인터넷뱅킹 (캐나다)',
      internetbankingbrazil: '인터넷뱅킹 (브라질)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '베트남 은행송금이체',
      nigeriabanktransfer: '나이지리아 은행송금이체',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: '은행송금 (오스트레일리아)',
      thailandinstantbankwiretransfer: '태국 은행 계좌이체',
      malaysiainstantbankwiretransfer: '말레이시아 은행 즉시이체',
      banktransferbpaypolipay: '은행송금/Bpay/PoliPay',
      cryptocurrencybitcoin: '암호 화폐-Bitcoin',
      cryptocurrencyusdt: '암호 화폐-USDT',
      cryptocurrencyethcps: '암호 화폐-ETH',
      cryptocurrencyusdccps: '암호 화폐-USDC(ERC-20)',
      nigeriainstantbankwiretransfer: '나이지리아 은행송금이체',
      vietnaminstantbankwiretransfer: '베트남 은행계좌이체',
      indonesiainstantbankwiretransfer: '인도네시아 은행계좌이체',
      philippinesinstantbankwiretransfer: '필리핀 제도 은행계좌이체',
      southafricainstantbankwiretransfer: '남아프리카 은행 즉시이체',
      indiainstantbanktransfer: '인도 은행 즉시이체',
      indiabanktransfer: '인도 은행송금이체',
      southkoreabanktransfer: '한국 즉시 은행 송금',
      ugandabanktransfer: '우간다 국내 은행이체',
      rwandabanktransfer: '르완다 국내 은행 이체',
      zambiabanktransfer: '잠비아 국내 은행 이체',
      congobanktransfer: '콩고 국내 은행 이체',
      cameroonbanktransfer: '카메룬 국내 은행 이체',
      burundibanktransfer: '부룬디 국내 은행 이체',
      kenyabanktransfer: '케냐 국내 은행 이체',
      ghanabanktransfer: '가나 국내 은행 이체',
      tanzaniabanktransfer: '탄자니아 국내 은행 이체',
      brazilbanktransfer: '브라질 은행이체',
      banktransferbpaypolipayaustralia: '은행송금/BPay/PoliPay (오스트레일리아)',
      banktransferbpaypolipayinternational: '은행송금/BPay/PoliPay (국제)',
      paypal: '페이팔',
      canadianlocalbanktransfer: '캐나다 로컬 은행 계좌 이체',
      cryptobitcoin: '암호 화폐-Bitcoin',
      cryptotetheromni: '암호 화폐-Tether(OMNI)',
      cryptotethererc20: '암호 화폐-Tether(ERC20)',
      cryptotethertrc20: '암호 화폐-Tether(TRC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      transfer: '계정으로 이체',
      E_WALLET: '전자 지갑',
      CRYPTOCURRENCY: '암호 화폐',
      LOCAL_TRANSFER: '로컬 전송',
      japanbanktransfer: '일본은행이체',
      internetbankingmexico: '인터넷뱅킹 (멕시코)',
      internetbankinguae: '인터넷 뱅킹 (UAE)',
      ewallet: '전자 지갑',
      internetbankingtaiwan: '인터넷 뱅킹 (대만)',
      internetbankingeu: '인터넷 뱅킹 (EU)',
    },
    country: { international: '국제은행송금', australia: '호주' },
    field: {
      country_region: '국가/지역',
      address: '상세주소',
      phone: '연락처',
      nat: '국가',
      country: '거주지',
      save: '투자',
      annIncome: '연수입',
      emailAdd: '이메일',
      yes: '예',
      no: '아니오',
      enterVerificationCode: '확인 코드 입력',
      ebuyAccName: ' EBuy 계정 이름',
      ebuyEmailAddress: ' EBuy  메일 주소 ',
      skrillAccName: ' Skrill 계정 이름',
      skrillEmailAddress: 'Skrill  메일 주소 ',
      netellerAccName: 'Neteller 계정 이름',
      netellerEmailAddress: 'Neteller  메일 주소 ',
      imptNotes: '비고',
      paymentAccName: '{name} 예금주',
      paymentAccNum: '{name} 계좌번호',
      documentType: '문서 유형',
      accDigit: '계정 숫자',
      docID: '문서 ID',
      bankBranchCode: '지점 코드',
      accType: '계좌유형',
      accName: '예금주',
      accNum: '계좌번호',
      bankName: '수취인 거래은행',
      swiftCode: '스위프트코드 (SWIFT code)',
      bankAddress: '은행주소',
      bankHolderAddress: '예금주주소',
      bankBeneficiaryName: '수취인명',
      bankAccNum: '은행계좌번호',
    },
    keys: {
      SUBMIT: '제출하기',
      FROM: '부터',
      UPDATE: '업데이트',
      TO: '까지',
      DATE: '계좌 계설  날짜',
      pleaseSelect: '선택하십시오.',
      amount: '금액',
      rebateAccount: '리베이트 계좌',
      comingSoon: '커밍순',
      tradingHistory: '거래 내역',
      noData: '데이터 없음',
      NAME: '이름',
      EMAIL: '이메일',
      ACCTYPE: '계좌유형',
      PLATFORM: '플랫폼',
      BASECURRENCY: '기준통화',
      DATERANGE: '리베이트 발생 시기',
      STATUS: '처리현황',
      DEST: '목적',
      ACCNUM: '계좌번호',
      BALANCE: '잔액',
      PROFITLOST: '손익',
      MARGINLVL: '마진 레벨',
      ACCEQUITY: '계정 자산',
      CREDIT: '신용 거래',
      NONE: '없음',
      ALL: '모두',
      actualAmonut: '실제 금액',
      currency: '통화',
      method: '방식',
      REGISTRATION: '등록 날짜',
      FTD: '첫 입금 날짜',
      CURRENTCAMPAIGN: '현재 캠페인',
      blacklisted: '블랙리스트에 오른',
      FIRST_TRANS_IN: '최초의 트랜스 인 날짜',
      createTime: '제출 시간',
      lastUpdateTime: '처리 시간',
    },
    dateRange: {
      today: '금일',
      yesterday: '전일',
      lastSevenDays: '7일 이전',
      lastThirtyDays: '30일 이전',
      weekToDate: '이번 주',
      monthToDate: '당월합계',
      quarterToDate: '분기별 합계',
      previousWeek: '지난 주',
      previousMonth: '지난 달',
      previousQuarter: '지난 분기',
    },
    products: { forex: '외환', commodities: '원자재', indices: '지수', crypto: '가상화폐' },
    formValidation: {
      dynamicReq: '{dynamic} 은 필수입력 사항입니다.',
      verCodeReq: '확인 코드가 필요합니다',
      alphanumericReq: '영숫자를 입력해주십시오.',
      answerReq: '선택하기',
      sameAsPOA: '항목은 POA와 같아야 합니다',
      sameAsID: '항목은 ID와 같아야 합니다',
      notBlank: '해당되지 않는 경우 비워두기',
      digits12WithoutDash: '"-" 없이 숫자 12자리를 입력하세요.',
      enterAsPerId: '신분증상의 숫자를 입력하십시오.',
      numberReq: '번호를 입력해주십시오.',
      addressReq: '상세 주소를 입력해주십시오.',
      suburbReq: '시/군/구를 입력해주십시오.',
      stateReq: '시/도를 입력해주십시오.',
      postcodeReq: '우편번호를 입력해주십시오.',
      countryReq: '거주 국가를 입력해주십시오.',
      AlphanumericOnlyNotSpace: '영문자와 숫자만 허용됩니다. 공백은 허용되지 않습니다.',
      AlphanumericOnly: '영문자 및 숫자만 허용됩니다.',
      NumbersSymbol: '숫자 및 기호만 허용됩니다.',
      NumbersOnlyNotSpace: '숫자만 허용됩니다. 공백은 허용되지 않습니다.',
      NumbersOnly: '숫자만 허용됩니다.',
      LettersSymbol: '문자와 기호만 허용됩니다.',
      LettersSymbolNotSpace: '문자와 기호만 허용됩니다. 공백은 허용되지 않습니다.',
      LettersOnlyNotSpace: '문자만 허용됩니다. 공백은 허용되지 않습니다.',
      NotSpace: '공백은 허용되지 않습니다.',
    },
  },
  report: {
    title: { rebateReport: '리베이트 보고서' },
    rebate: {
      column: {
        name: '이름',
        accountNumber: '계좌번호',
        accountType: '계좌유형',
        volume: '볼륨',
        rebate: '리베이트',
        totalRebate: '리베이트 합계',
        date: '일시',
        instrument: '거래 상품',
      },
      dropdown: { allInstruments: '모든 거래 상품', all: '모두', instruments: '거래 상품' },
      others: {
        rebate: '리베이트',
        instrumentsTraded: '거래 상품',
        lots: '랏',
        totalRebate: '리베이트 합계',
        rebates: '리베이트',
      },
    },
  },
  ibReport: {
    header: 'IB 보고서',
    netFunding: '순 입금',
    deposits: '입금',
    withdraw: '출금',
    openedAccs: '개설 완료 계좌',
    funding: '입출금 내역',
    withdrawals: '인출',
    appliedAmount: '적용 금액',
    depositAmount: '입금 금액',
    withdrawalAmount: '출금 금액',
    timeLimitMessage:
      '다운로드할 수 있는 최대 쿼리 범위는 90일입니다. 새 날짜 범위를 업데이트하세요.',
    successInfo: '다운로드 성공',
    download: '다운로드',
    comfirm: '확인',
    defaultTimeMessage: '다운로드할 수 있는 최대 쿼리 범위는 90일입니다.',
  },
  lead: { header: '리즈', ibAccount: 'IB 계좌', demoAccounts: '데모계좌' },
  pendingAccounts: {
    header: '대기중 계좌',
    errorDoc:
      '다음 신청자들은 온라인으로 개설 신청서를 작성했지만, 최종 단계에서 신분증명 (ID) 자료를 제출하지 않았습니다.',
    acceptedDocType: '본인 인증 자료',
    primaryDoc: '신분증명자료',
    passport: '여권 (서명란 포함)',
    id: '신분증(앞/뒤)',
    dl: '운전면허증(앞/뒤)',
    address: '주소증명자료',
    addressList: [
      '최근 6개월 이내 발급된 공과금(전기/수도/가스) 영수증 사본을 제출해주시길 바랍니다',
      '최근 6개월 이내 발급된 신용카드내역서 사본을 제출해 주시길 바랍니다',
      '최근 6개월 이내 은행거래내역서 사본을 제출해 주시길 바랍니다',
    ],
    tip: '신분 증명 자료를 다음의 주소로 첨부하시기 바랍니다. ',
  },
  rebatePaymentHistory: {
    rebatePaymentHistory: '거래내역',
    rebateHistory: '리베이트 내역',
    withdrawHistory: '출금 내역',
    transferHistory: '이체내역',
    cancelWithdraw: '출금신청을 취소하시겠습니까?',
    cancelError:
      '귀하의 출금이 현재 처리 중입니다. 따라서 취소 요청을 수락할 수 없습니다. 자세한 내용은 라이브 채팅 기능을 사용하여 문의할 수 있습니다.',
  },
  fileUploader: {
    supportedFormat: '지원 파일 형태 : png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: '지원 파일 형태: {files}',
    maxSize: '파일 업로드 크기: {maxSize} MB',
    maxFileNum: '최대 파일업로드 개수:{maxFileNum}',
    selectFile: '찾아보기',
    maxFileSize: '업로드된 파일 크기는 {maxSize}MB를 초과할 수 없습니다',
    sureToDelete: { title: '확실하신가요?', content: '업로드한 파일을 삭제하시겠습니까?' },
  },
  transferHistory: {
    payStatus: { processing: '처리중', paid: '지불', unpaid: '미지불', rejected: '거절' },
  },
  referralLinks: {
    header: '관련 링크',
    ibAcc: 'IB 계좌',
    campLang: '언어',
    cpy: '복사',
    alreadyCPY: '복사됨',
    liveAcc: '실계좌',
    demoAcc: '데모계좌',
    homePage: '홈페이지',
    Share: '공유하기',
    subIbReferral: '서브 IB 추천',
  },
  settings: { ibProfile: 'IB 프로필', info: '내 정보', reg: '등록' },
  myProfile: {
    changeDetails: '프로필 세부 정보의 업데이트가 필요하시면 {mailTo}로 문의하시기 바랍니다.',
  },
  unfundedAcc: {
    header: '계좌 예치 현황',
    allActivationAccs: '활성화 된 모든 계정',
    neverFundedAccs: '미예치 계좌',
    prevFundedZero: '현재 잔액없음',
  },
  multiib: { level: '{level} 단계', noib: '이 계정에는 하위 IB가 없습니다.' },
  header: { bckToCP: '고객 포털사이트로 되돌아가기', logout: '로그아웃' },
  menu: {
    dashboard: '대시보드',
    ibReport: 'IB 보고서',
    rebateReport: '리베이트 보고서',
    accManagement: '계좌관리',
    iblevel: '다단계',
    ibAccs: '활성화된 계좌',
    leads: '리즈',
    pendingAccs: '대기중 계좌',
    unfundedAccs: '계좌 예치 현황',
    payHistory: '거래내역',
    ibProfile: 'IB 프로필',
    refLinks: '관련 링크',
    contactUs: '연락하기',
    switchCP: '고객 포털사이트로 이동',
  },
  footer: {
    riskWarn:
      '위험 경고 : CFD 거래는 자금에 높은 위험을 가져오고 손실을 초래할 수 있으므로 손실을 견딜 수있는 자금으로 만 거래해야합니다. CFD 거래는 모든 투자자에게 적합하지 않을 수 있으므로 관련된 위험을 완전히 이해하고 적절한 조치를 취하여 관리하십시오. 관련 위험 공개 문서를주의 깊게 읽으십시오.',
  },
  responseMsg: {
    410: '매개변수 검증에 실패하였습니다',
    411: '비밀번호 불일치',
    420: '사용자가 존재하지 않습니다',
    421: '비밀번호 재설정이 거부되었습니다',
    431: '리베이트 계산이 진행 중이므로 잠시 후 다시 요청해 주시기 바랍니다',
    490: '중복된 카드 정보입니다. ',
    500: '에러가 발생했습니다.',
    501: '실행이 취소되었습니다. 나중에 다시 시도하십시오.',
    505: '파일 업로드에 실패하였습니다',
    520: '로그인에 실패했습니다. 다시 로그인하시기 바랍니다.',
    521: '사용자가 이미 동일 기기에 로그인했습니다.',
    522: '다시 로그인해주십시오.',
    523: '다시 로그인해주십시오.',
    524: '귀하의 이메일 주소가 유효하지 않습니다. ',
    525: '아이디 또는 비밀번호가 일치하지 않습니다.',
    527: '사용자가 IB가 아닙니다',
    528: '사용자가 존재하지 않습니다',
    529: '아이디 또는 비밀번호가 일치하지 않습니다.',
    530: '로그인 세션이 유효하지 않습니다',
    540: '귀하의 거래 계좌를 찾을 수 없습니다.',
    541: '리베이트 계좌를 찾을 수 없습니다.',
    542: '같은 이름으로 계좌를 개설 할 수 없습니다.',
    544: '신분증이 인증될 때까지 추가 계정을 신청할 수 없습니다',
    550: '계좌번호 불일치',
    551: '잔액이 부족합니다.',
    553: '계정의 크레딧으로 인해 출금 / 이체 할 수 없습니다. 추가 도움이 필요하면 {email}에 문의하십시오.',
    554: '요청하신 금액이 0 또는 무효입니다.',
    564: '국가가 결제 채널과 일치하지 않습니다',
    565: '은행코드 불일치',
    566: '지불 방식을 찾을 수 없습니다.',
    581: '프로모션에 성공적으로 선택했습니다. 자금을 인출하려면 입금해야합니다.',
    582: '이벤트에 참여 중이며 직위가 있으므로 탈퇴 신청서를 제출할 수 없습니다.',
    584: '요청 가능한 리베이트가 존재하지 않습니다.',
    590: '송금 계좌를 찾을 수 없습니다.',
    591: '귀하의 은행 계좌를 찾을 수 없습니다.',
    593: '귀하의 계정에 크레딧이 포함되어 있기 때문에 선택한 계정에서 자금 이체를 처리할 수 없습니다.',
    594: '이 계정에서 자금 이체는 허용되지 않습니다',
    595: '귀하가 당사 활동에 참여하고 있으므로 현재 귀하의 이전 요청을 처리 할 수 ​​없습니다.',
    647: '확인 코드가 잘못 입력되었습니다',
    1101: '10개의 공유 계정이 생성되었으며, 새로운 계정을 생성할 수 없습니다.',
    1102: '활성화할 수 없습니다. 계정이 라이브 또는 데모 계정으로 생성되었습니다.',
    1103: '계정이 비활성화되었습니다',
    1202: '확인이 만료되었습니다. 다시 시도하세요',
    1204: '다른 이메일 주소로 시도해주세요',
    1208: '확인 코드는 반복해서 보낼 수 없습니다',
    1209: '인증 코드 전송 오류',
    session_timeout: '장 시간 로그인 하지 않았습니다. 재 로그인 해주십시오.',
  },
  verificationStatus: {
    title: { default: '거의 다 되었습니다!', LV3: '환영합니다' },
    dueToAML:
      '곧 트레이딩을 시작하실 준비가 되었습니다! 귀하의 개인 정보를 인증함으로써 저희가 귀하의 계정을 보호할 수 있습니다.',
    accOpeningVerification: {
      title: '실시간 계정 개설',
      content: '계좌에 펀딩을 시작하기 위해서 실시간 계정을 개설하세요',
    },
    identityVerification: {
      title: '신원 인증',
      content: '트레이딩을 시작하려면 신원 증명을 제출하고 인증하십시오.',
    },
    poaVerification: { title: '주소 인증', content: '인출하기 전에 POA를 인증해야 합니다.' },
    kyc: {
      depositNow: '지금 입금',
      withdrawNow: '지금 인출하기',
      createNow: '지금 개설',
      start: '시작',
      complete: '완료',
      pending: '보류 중',
      rejected: '거부됨',
      resubmit: '다시 재출',
    },
    resubmitNote: '일부 문서가 없거나 올바르지 않을 수 있습니다. 다시 제출해주십시오.',
    rejectedNote: '질문이 있으십니까? {liveChat}로 연락해 주세요.',
    liveChat: '라이브 채팅으',
    uploadTemplate: {
      idGuideTitle: 'ID 문서 지침',
      uploadGuideTitle: '업로드 지침',
      desc1: '정부 발행',
      desc2: '원본 전체 크기, 편집되지 않은 문서',
      desc3: '단일 색상 배경에 문서를 배치하세요',
      desc4: '읽을 수 있고, 조명을 잘 받은, 컬러 이미지',
      desc5: '흑백 이미지 안 됨',
      desc6: '편집되거나 만료된 문서 안 됨',
      bottomDesc:
        '업로드된 파일은 jpg, png 또는 pdf일 수 있으며 파일 크기는 5MB를 초과할 수 없습니다',
    },
  },
  register: {
    progressStatus: { title: '계좌 인증 심사 진행중', estimatedTimeTitle: '예상시', min: '분' },
    tabs: {
      personalDetails: '개인정보',
      moreAboutYou: '더 알아보기',
      accountConfig: '계좌 환경',
      confirmYourId: '신분증명',
      fundYourAcc: '자금예치',
      personalInformation: '개인 정보',
      createLiveAccount: '실시간 계정 개설',
      financialInfo: '재무 정보',
      iDProof: '신원 증명',
      addressProof: '주소 증명',
    },
    btn: {
      next: '다음',
      back: '이전',
      fundnow: '입금하기',
      upload: '업로드',
      done: '완료',
      bTCP: '고객 포털로 돌아가기',
      proceedToUpload: '업로드 진행',
      completeID: 'ID 인증 완료',
      completePOA: 'POA 인증 완료',
      depositViaOther: '다른 채널을 통한 예치',
      withdrawViaOther: '다른 채널을 통해 출금',
    },
    authority: {
      dialog1: '해당 페이지의 접속을 희망하실 경우 실계좌로 로그인 하시기 바랍니다.',
      dialog2: '지금 바로 실계좌를 개설하여 해당 페이지에 대한 접근 권한을 활성화 하시길 바랍니다.',
      openLiveAcc: '실계좌개설',
    },
    demo: {
      congrad: '축하드립니다!',
      title: '데모계좌는 60일간 유효합니다.',
      title2: '고객님의 데모 계좌 상세정보',
      li: { login: '로그인', srv: '서버', expDate: '유효기간' },
      emailSent: '로그인 정보는 가입하신 이메일로도 발송되었습니다.',
      dl: 'MT4 거래 플랫폼 다운로드',
      improveQ: '당사의 트레이딩 환경이 궁금하신가요?',
      improveWay:
        '지금 실계좌를 개설하면 다양한 트레이딩 툴과 특별한 프로모션의 기회를 누릴 수 있습니다.',
      proTrade: '전문 마케팅 보고서',
      customerService: '24/7 고객서비스',
      competitivePromotionals: '경쟁력있는 프로모션',
      secure: '3D 보안 거래',
      openLiveAcc: '실계좌개설',
    },
    personalDetails: {
      page1: {
        pageTitle: '개인정보',
        userTitle: '호칭',
        firstName: '이름',
        lastName: '성',
        email: '이메일',
        dob: '생년월일',
        idType: '신분증명유형',
        referral: '당사를 추천해 주신 분이 있으신가요?  (선택사항)',
        middleName: '중간 이름',
        nationality: '국가',
        phoneNum: '연락처',
        date: '일',
        month: '월',
        year: '년도',
        placeOfBirth: '출생지',
        idNum: '신분증번호',
        passportNum: '여권번호',
        driverLicenseNum: '운전면허증번호',
        titleDropdown: {
          mr: '남성',
          mrs: '기혼 여성',
          ms: '여성',
          miss: '미혼 여성',
          dr: '의사',
          prof: '전문가/교수',
        },
        idTypeDropdown: { passport: '여권', idCard: '주민등록증', driverLicense: '운전면허증' },
      },
      page2: {
        pageTitle: '현 주소지 정보',
        mainResidency: '현재 거주지',
        streetNumber: '상세주소 (번지수, 동, 호)',
        streetName: '도로명',
        address: '상세주소',
        provinceOrState: '시/도',
        cityOrSuburb: '시/군/구',
        postcode: '우편번호',
        usQuestion:
          '미국에 세금을 납부하는 시민권/영주권을 가진 국민이거나 미국 소재의 은행 계좌가 있습니까?',
      },
    },
    moreAboutYou: {
      pageTitle1: '고용 현황 및 재무 상태 입력',
      pageTitle2: '거래 지식 및 경험',
      complianceExplain:
        '아래의 질문은 준법감시부의 AML/CTF (자금세탁방지 및 테러자금조달방지) 규범 요구 사항을 준수하기 위한 목적으로 수집됩니다. ',
    },
    accountConfig: {
      pageTitle: '계좌 환경',
      chooseTradingPlatform: '거래 플랫폼 선택',
      chooseAccType: '계좌 유형 선택',
      chooseAccCurrency: '계좌 통화 선택',
    },
    confirmYourId: {
      pageTitle: '신분증명',
      description:
        '당사는 정식으로 금융 규제를 준수하는 외환 중개사로서 귀하의 신원을 확인해야 합니다. 신분증명 및 거주증명 확인을 위해 다음 문서 중 하나를 업로드해 주십시오. 혹은 {mail}로 증명 문서를 보낼 실 수 있습니다.',
      poidTitle: '신분증명자료',
      porTitle: '주소증명자료',
      uploadDescription: '문서 유형을 선택한 후 파일을 업로드 해주십시오.',
      note: '중요: 파일명에 반드시 영문명을 입력해 주시기 바랍니다. (예: Gil Dong Hong)',
      pending: { upload: '업로드 대기중', verification: '승인 대기중' },
      poidTypes: { photoId: '신분증', passport: '여권', drivingLicence: '운전면허증' },
      porTypes:
        '  <li>공과금 고지서</li>\n        <li>은행명세서</li>\n        <li>영문등초본</li> ',
    },
    finishPage: {
      title: '{individualUserName} 님, {platformName} 실계좌 개설을 신청해주셔서 감사합니다!',
      contentDefault:
        '귀하의 계좌 개설 신청이 정상적으로 접수되었으며, <b>{account}</b> 실계좌가 개설 완료되었습니다.<br />\n      곧 귀하의 {email}로 로그인 세부 정보 및 계좌의 활성화 상태를 볼 수있는 링크가 포함 된 이메일이 발송됩니다. <br />',
      contentWithoutId3WithAccount:
        '귀하의 거래 계좌는 승인중이며 이와 관련된 사항은 이메일로 발송됩니다.<br /> <br />\n      만약 계좌의 우선 개설을 원하신다면, 자금 예치를 통해 개설 활성화에 우선권을 부여 할 수 있습니다. <br />',
      contentWithoutId3WithoutAccount:
        '귀하의 실계좌는 담당부서에서 심사중이며 이와 관련된 사항은 이메일로 발송됩니다. <br />',
    },
    accOpeningVerification: {
      accOpenVer: '계정 개설 인증',
      personalInformation: {
        narrative: '실시간 계정을 열려면 개인 정보가 필요합니다',
        firstName: '이름',
        middleName: '중간 이름',
        lastName: '성을 입력해야 합니다',
        gender: '성별',
        genderList: { male: '남성', female: '여성', other: '기타' },
        dob: '생년월일',
        day: '일',
        month: '월',
        year: '년',
        mobile: '휴대 전화 번호',
        countryName: '거주 국가',
        nationality: '국적',
      },
      createLiveAccount: {
        narrative:
          '몇 가지 단계를 완료하여 계정을 만들고 펀딩을 시작하세요. 계정 유형<a href="{link}" target="_blank" alt>에 대한 자세한 내용은 여기를 참조하십시오</a>.',
        choose: '{dynamic} 선택',
        tradingPlatform: '트레이딩 플랫폼',
        accountType: '계정 유형',
        accountCurrency: '계정 통화',
        islamicTooltip:
          '{platform} 은 사용자가 이슬람교 신앙을 가지고 있는지 확인하기 위한 증빙 서류를 제공할 것을 요구할 수 있습니다. {platform} 은 인증에 실패할 경우 계정 구성을 수정할 수 있는 권한을 보유합니다.',
        successInfo: {
          title: '트레이딩 계좌 생성 완료',
          content:
            '축하드립니다! 계정 개설을 성공적으로 완료되었습니다. <br /> 트레이딩을 시작하려면, 지금 입금하고 신분 인증을 완료하세요.',
        },
        successInfo2: {
          title: '트레이딩 계좌 생성 완료',
          content: '계정 인증의 다음 단계를 시작하려면 재무 프로파일 정보를 입력하세요',
          start: '시작',
        },
        generalInfo: {
          title: '제출하신 내용을 검토 중입니다.',
          content:
            '{platform}에 계좌를 개설해 주셔서 감사합니다. 제출하신 내용이 승인되면 계정에 대한 펀딩을 시작할 수 있습니다.',
        },
        rejectedInfo: {
          title: '거부됨',
          content: '{platform} 계정 개설 요청이 거절되었음을 알려드리게 되어 유감입니다.',
        },
      },
    },
    identityVerification: {
      title: '신원 인증',
      subTitle: '신원 정보',
      narrative: '세부 정보 입력',
      firstName: '이름',
      middleName: '중간 이름',
      lastName: '성을 입력해야 합니다',
      nationality: '국적',
      idType: 'ID 유형',
      idSerialNumber: 'ID 일련 번호',
      passportNum: '여권번호',
      driverLicenseNum: '운전면허증번호',
      upload: {
        title: '문서 업로드',
        desc: '문서에 사진, 전체 이름, 생년월일 및 발급 날짜가 표시되어 있는지 확인합니다.',
        guideline: '업로드 지침',
        positive: '포지티브 업로드',
        signaturePage: '서명 업로드 페이지',
      },
      idTypeDropdown: {
        passport: '여권',
        idCard: '주민등록증',
        driverLicense: '운전면허증',
        other: '기타',
      },
      additionalInfo: {
        title: '추가 정보를 입력해야 합니다',
        content: '트레이딩을 시작하려면 신원을 인증하세요',
      },
      successInfo: {
        title: 'ID 인증 완료',
        content:
          '축하드립니다! ID가 인증되었습니다. 이미 예금을 하셨다면, MT4/MT5 또는 {platform} 앱에서 트레이딩을 시작하실 수 있습니다.',
      },
      generalInfo: {
        title: '제출하신 내용을 검토 중입니다.',
        content:
          '{platform} 에 계좌를 개설해 주셔서 감사합니다. 신원 정보가 인증되면 트레이딩을 시작하실 수 있습니다.',
      },
      rejectedInfo: {
        title: '거부됨',
        content: '{platform} 계정 개설 요청이 거절되었음을 알려드리게 되어 유감입니다.',
      },
      identifyingRequiredInfo: {
        title: '추가 정보를 입력해야 합니다',
        content:
          '일부 신원 관련 문서가 없거나 올바르지 않을 수 있습니다. 계속 진행하려면 해당 파일을 다시 업로드하십시오.',
      },
      streetNumber: '상세주소 (번지수, 동, 호)',
      streetName: '도로명',
    },
    poaVerification: {
      proofOfAddressVer: '주소 증명 인증',
      subTitle: '주소 정보',
      narrative: '세부 정보 입력',
      dueToAML: 'AML 요구 사항 때문에, 인출하기 전 귀하의 주소를 인증해야 합니다.',
      nationality: '국적',
      country: '거주 국가',
      address: '주소',
      postalCode: '우편 번호',
      citySuburb: '도시/시',
      city: '도시를',
      provinceState: '주/도',
      upload: {
        title: 'POA 인증서',
        inst1: '공과금(전기, 수도, 가스)',
        inst2: '은행 또는 신용카드 명세서',
        desc: '인출에 대한 POA 정보는 지난 {months} 개월 이내에 발행된 것이어야 합니다',
        guideline: '업로드 지침',
        uploadFilesPhoto: '파일/사진 업로드',
      },
      additionalInfo: {
        title: '추가 정보를 입력해야 합니다',
        content: '인출하기 전에 POA를 인증해야 합니다.',
      },
      successInfo: {
        title: '주소 증명 인증됨',
        content: '축하드립니다! POA가 인증되었습니다. 이제 인출하실 수 있습니다.',
      },
      generalInfo: {
        title: '제출하신 내용을 검토 중입니다.',
        content:
          '{platform} 과(와) 거래해 주셔서 감사합니다. 제출이 승인되면 철회 절차를 시작할 수 있습니다.',
      },
      identifyingRequiredInfo: {
        title: '추가 정보를 입력해야 합니다',
        content:
          '일부 신원 관련 문서가 없거나 올바르지 않을 수 있습니다. 계속 진행하려면 해당 파일을 다시 업로드하십시오.',
      },
    },
    financialVerification: {
      financialInfo: {
        ver: '귀하의 재정 정보',
        subTitle: '고용 및 재무 세부 정보',
        subTitle2: '트레이딩 지식 및 경험',
        employmentStatus: '고용 상태',
        USDRemoteTitle: '{title} (USD)',
        perWeekTitle: '일주일당 {title}',
        estimatedAnnualIncome: '예상 연간 소득',
        estimatedSavingsAndInvestments: '예상 절감액 및 투자액',
        estimatedIntendedDeposit: '예상 예금',
        sourceOfFunds: '자금 출처',
        numberOfTrades: '거래 수',
        tradingAmount: '거래량',
      },
      iDProof: { ver: '신원 증명 업로드', subTitle: '신원 정보', narrative: '세부 정보 입력' },
      addressProof: {
        ver: '주소 증명 업로드 (POA)',
        subTitle: '주소 정보',
        narrative: '세부 정보 입력',
        upload: {
          title: '주소 증명을 위해 승인된 문서',
          inst1: '공과금(전기, 수도, 가스)',
          inst2: '은행 또는 신용카드 명세서',
          desc: '인출에 대한 POA 정보는 지난 {months}개월 이내에 발행된 것이어야 합니다',
          guideline: '업로드 지침',
          uploadFilesPhoto: '파일/사진 업로드',
        },
      },
      successInfo: {
        title: '은행 인증 완료',
        content: '축하드립니다! 은행 정보를 성공적으로 인증했습니다.',
      },
      generalInfo: {
        title: '제출하신 내용을 검토 중입니다.',
        content:
          '은행 송금을 위한 문서는 아직 검토 중입니다. 지금 거래를 시작하고 싶다면 다른 채널을 통해 예금을 할 수도 있습니다.',
      },
      rejectedInfo: { title: '거부됨', content: '계좌 이체 입금 요청이 거절되었습니다.' },
      identifyingRequiredInfo: {
        title: '추가 정보를 입력해야 합니다',
        content:
          '일부 신원 관련 문서가 없거나 올바르지 않을 수 있습니다. 계속 진행하려면 해당 파일을 다시 업로드하십시오.',
      },
    },
  },
  ibProfile: {
    subTitle: 'IB 공유',
    sharedIbList: {
      sharedIbAccount: 'IB 공유 계정',
      add: '새 계정 생성',
      maxSharedIbAccount: 'IB 공유 계정은 최대 10개까지 생성 가능',
      accountHistory: '공유 계정 이용 기록',
      delete: '삭제',
      setting: '설정',
      enable: '활성화',
      disable: '비활성화하다',
      enabled: '활성화됨',
      disabled: '종료됨',
      sharedAccountEnabled: '공유 계정이 활성화되었습니다',
      sharedAccountDisabled: '공유 계정이 비활성화되었습니다',
      sharedAccountCopied: '공유 계정이 복사되었습니다',
      deleteTips: '삭제한 IB 공유 계정은 다시 로그인할 수 없습니다. 삭제하시겠습니까?',
      sharedAccountDeleted: '공유 계정이 삭제되었습니다',
      copyTips: '공유 계정이 복사되었습니다',
      accountData: '계정 데이터',
      IBData: 'IB 데이터',
      fundOperations: '자금 운용',
      resetPassword: '비밀번호 재설정',
      remark: '참고 ',
      cancel: '취소',
      accountHistoryBtn: '계정 사용 기록',
    },
    title: 'IB 프로필',
    updateSharedIb: {
      addNewSharedIb: '새 공유 계정 만들기',
      editSharedIb: '공유 계정 수정',
      addSuccess: '공유 계정이 성공적으로 생성되었습니다',
      maxSharedIbAccountError: '10개의 공유 계정이 생성되었으며, 새로운 계정을 생성할 수 없습니다.',
      permissionIdsTips: '공유 계정에 대해 다음 권한을 허용할지 여부를 선택할 수 있습니다.',
      permissionIdsFirst:
        '<b>계정 데이터</b> : 고객 관련 페이지(고객 관리, 최근 신규 계좌)에서 데이터를 볼 수 있으며, 권한이 허용되지 않을 경우 해당 데이터가 숨겨지고 표시됩니다.',
      permissionIdsSecond:
        '<b>IB 데이터</b>: IB 관련 데이터(개요 데이터, 다단계 소개 브로커-계좌)를 조회하며, 권한이 허용되지 않을 경우 해당 데이터가 숨겨져 표시됩니다.',
      permissionIdsThird:
        '<b>자금 운용</b> : IB 펀드 운용(수수료 신청, 이체, 출금), 리베이트 보기(리베이트 보고서, 잔액 및 자금 기록), 허용되지 없을 경우 작동이 불가합니다.',
      email: '공유 계정 이메일',
      emailValidate: '유효한 이메일을 입력해주세요',
      emailError: '해당 이메일 주소는 이미 사용 중입니다. 다른 이메일 주소를 사용해 주세요.',
      password: '로그인 비밀번호 설정',
      passwordValidate: '비밀번호를 입력 해주세요',
      newPassword: '새로운 비밀번호를 입력해주세요',
      passwordValidateNum: '8~16자',
      passwordValidateTips: '영문대소문자(A-Z, a-z), 숫자(0-9), 특수문자(예: !@#S%^&) 혼합',
      passwordValidateWarning:
        '8~16자,영문대소문자(A-Z, a-z), 숫자(0-9), 특수문자(예: !@#S%^&) 혼합',
      sharedIbAccountPermission: '공유 계정 권한',
      remark: '참고 ',
      remarkTips: '계정 사용량나 계정 보유자를 기록할 수 있습니다.',
      status: '공유 계정 상태 ',
      cancel: '취소',
      confirm: '확인 ',
      disabled: '종료됨',
      enabled: '활성화됨',
      changePasswordTips: '새 비밀번호가 성공적으로 변경되면 이전 비밀번호는 즉시 무효화됩니다.',
      resetPassword: '비밀번호 변경',
      resetPasswordSuccess: '비밀번호 재설정 완료되었습니다.',
      enterNewPassword: '새로운 비밀번호를 입력해주세요',
      newPassowrdReset: '새로운 비밀번호를 입력해주세요',
      emailPlaceholder: '이메일 주소를 입력해주세요',
      passwordSame: '새 비밀번호는 이전 비밀번호와 동일할 수 없습니다.',
      fundsChangeTips:
        '자금 이체가 허용된 후 공유 IB 계정에서 IB 메인 계정의 잔액은 입출금을 할 수 있는데, 이 작업이 허용됩니까?',
      editSuccess: '수정되었습니다.',
    },
    sharedIbHistory: {
      viewMore: '더 알아보기',
      allHistory: '모든 기록이 표시되었습니다.',
      lastUpdate: '업데이트 시간',
      refreshTips: '기록이 업데이트되었습니다.',
      refresh: '새로 고치다',
      chooseAccount: '계정을 선택하세요',
      device: '설비 ',
      login: '로그인 ',
      commission: '커미션 신청',
      withdraw: '출금',
      transfer: '이체',
      fromTo: '{fromAccount}에서 {toAccount}까지, 금액 {total}',
    },
    npPermissionTips: '현재 계정에는 운영 권한이 없습니다.',
  },
  agreement: {
    title: '브로커 계약 컨피밍 소개',
    rebateAccount: '리베이트 계정',
    description:
      '동의를 클릭하면 계약서의 모든 정보, 서비스 이용 약관을 읽고 이해했으며 이에 동의함을 확인합니다.',
    agree: '동의',
    signed: '리베이트 계정 {accountID}. {signedTime}에 서명됨',
    IBAgreement: 'IB 계약',
    confirmAgreement: 'IB 계약서를 확인하려면 IB 관리자에게 문의하세요.',
  },
};
